import React, { useState } from 'react';

const Accordion1 = ({ title, content, lineHeight }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="accordion-item">
      <div className={isActive ? 'accordion-title active' : 'accordion-title'} onClick={() => setIsActive(!isActive)}>
        <div className="title">{title}</div>
        <div className="icon-expand">{isActive ? '-' : '+'}</div>
      </div>
      {isActive && <div className="accordion-content" style={{lineHeight: `${lineHeight}px`}} dangerouslySetInnerHTML={{__html: content}}></div>}
    </div>
  );
};

export default Accordion1;