import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { logoutUser } from "../slices/authSlice";
import { toast } from "react-toastify";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const NavBar = () => {
  const dispatch = useDispatch();
  const { cartTotalQuantity } = useSelector((state) => state.cart);
  const auth = useSelector((state) => state.auth);
   const navigate = useNavigate();

  console.log(auth);

  return (
    <nav className="nav-bar">
      <Link to="/">
        VCARD EDITOR
      </Link>
      <ArrowBackIcon onClick={() => navigate(-1)} />
      {auth._id ? (
        <Links>
          <div
            onClick={() => {
              dispatch(logoutUser(null));
              toast.warning("Logged out!", { position: "bottom-left" });
            }}
          >
            Logout
          </div>
        </Links>
      ) : (
        <AuthLinks>
          <Link to="/login">Login</Link>
          <Link to="register">Register</Link>
        </AuthLinks>
      )}
    </nav>
  );
};

export default NavBar;

const AuthLinks = styled.div`
  a {
    color:#000;
    &:last-child {
      margin-left: 2rem;
    }
  }
`;

const Links = styled.div`
  color: white;
  display: flex;

  div {
    cursor: pointer;
    background: #ad3306;
    padding: 12px 20px;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    border-radius: 4px;

    &:last-child {
      margin-left: 2rem;
    }
  }
`;
