import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Home from "./components/Home";
import NotFound from "./components/NotFound";
import Cart from "./components/Cart";

import "react-toastify/dist/ReactToastify.css";
import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { loadUser } from "./slices/authSlice";
import CheckoutSuccess from "./components/CheckoutSuccess";
import Users from "./components/admin/Users";
import Orders from "./components/admin/Oders";
import Categories from "./components/admin/Categories";
import CreateCategory from "./components/admin/CreateCategory";
import Summary from "./components/admin/Summary";
import EditCard from "./components/admin/EditCard";
import CreateCard from "./components/admin/CreateCard";
import Product from "./components/details/Product";
import ListCard from "./components/admin/ListCard";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadUser(null));
  }, [dispatch]);

  return (
    <div className="App">
      <BrowserRouter>
        <ToastContainer />
        <div className="content-container">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="dashboard" element={<ListCard />} />
            <Route path="create-vcard" element={<CreateCard />} />
            <Route path="edit-vcard/:id" element={<EditCard />} />
            <Route path="/login" element={<Login />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
