import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { url, setHeaders } from "./api";
import { toast } from "react-toastify";

const initialState = {
  cards: [],
  status: null,
  createStatus: null,
  editStatus: null,
  deleteStatus: null
};

export const cardsFetch = createAsyncThunk(
  "cards/cardsFetch",
  async () => {
    try {
      const response = await axios.get(`${url}/cards`);

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const cardsCreate = createAsyncThunk(
  "cards/cardsCreate",
  async (values) => {
    try {
      const response = await axios.post(
        `${url}/cards`,
        values,
        setHeaders()
      );
      return response.data.card;
    } catch (error) {
        console.log(error);
        toast.error(error.response?.data);
    }
  }
);

export const cardsEdit = createAsyncThunk(
  "cards/cardsEdit",
  async (values) => {
    try {
      const response = await axios.put(
        `${url}/cards`,
        values,
        setHeaders()
      );

      return response.data;
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data);
    }
  }
);

export const cardsDelete = createAsyncThunk(
  "cards/cardsDelete",
  async (id) => {
    try {
      const response = await axios.delete(
        `${url}/cards/${id}`,
        setHeaders()
      );
      return response.data;
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data);
    }
  }
);

const cardsSlice = createSlice({
  name: "cards",
  initialState,
  reducers: {},
  extraReducers: {
    [cardsFetch.pending]: (state, action) => {
      state.status = "pending";
    },
    [cardsFetch.fulfilled]: (state, action) => {
      state.cards = action.payload;
      state.status = "success";
    },
    [cardsFetch.rejected]: (state, action) => {
      state.status = "rejected";
    },
    [cardsCreate.pending]: (state, action) => {
      state.createStatus = "pending";
    },
    [cardsCreate.fulfilled]: (state, action) => {
      state.cards.push(action.payload);
      state.createStatus = "success";
      toast.success("Vcard Created!");
      window.location.href = '/dashboard?card_create=true';
    },
    [cardsCreate.rejected]: (state, action) => {
      state.createStatus = "rejected";
    },
    [cardsEdit.pending]: (state, action) => {
      state.editStatus = "pending";
    },
    [cardsEdit.fulfilled]: (state, action) => {
      state.editStatus = "success";
      toast.success("Vcard Updated!");
      window.location.href = `/edit-vcard/${action.payload.id}`;
    },
    [cardsEdit.rejected]: (state, action) => {
      state.editStatus = "rejected";
    },
    [cardsDelete.pending]: (state, action) => {
      state.deleteStatus = "pending";
    },
    [cardsDelete.fulfilled]: (state, action) => {
      const newList = state.cards.filter((card) => card.id !== action.payload);
      state.cards = newList;
      state.deleteStatus = "success";
      toast.error("Vcard Deleted!");
    },
    [cardsDelete.rejected]: (state, action) => {
      state.deleteStatus = "rejected";
    },
  },
});

export default cardsSlice.reducer;
