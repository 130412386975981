import styled from "styled-components";

export const AdminHeaders = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FormField = styled.div`
   width: 100%;
    margin-bottom: 18px;
    svg {
      color: #313a56;  
    }
`;

export const Card = styled.div`
   background: #fff;
   width: 100%;
    border: 1px solid #f3f4f6;
    border-radius: 2px;
    padding: 25px;
`;

export const Heading = styled.h2`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 25px;
`;

export const PrimaryButton = styled.button`
  padding: 9px 12px;
  border-radius: 5px;
  font-weight: 400;
  letter-spacing: 1.15px;
  background-color: #4b70e2;
  color: #f9f9f9;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 0.5rem 0;
  svg {
    vertical-align: sub;
    font-size: 18px;
  }
`;
