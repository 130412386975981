import styled from "styled-components";
import { Outlet, NavLink, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import CategoryIcon from '@mui/icons-material/Category';
import NavBar from "../NavBar";
import { toast } from "react-toastify";
import { logoutUser } from "../../slices/authSlice";
import LogoutIcon from '@mui/icons-material/Logout';
import AddIcon from '@mui/icons-material/Add';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CopyrightIcon from '@mui/icons-material/Copyright';
import FilterIcon from '@mui/icons-material/Filter';

const AdminNav = () => {
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if(!auth.isAdmin) {
     navigate('/login');
  }

  return (
    <>
      <SideNav>
        <NavLink to="/" style={{color: '#ff9800', fontSize: '19px', fontWeight: '700', marginBottom: '40px'}}>
           VCARD EDITOR
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive ? "link-active" : "link-inactive"
          }
          to="/dashboard"
        >
          <DashboardIcon/> Vcards
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive ? "link-active" : "link-inactive"
          }
          to="/create-vcard"
        >
          <AddIcon style={{verticalAlign: 'sub'}}/> Create Vcard
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive ? "link-active" : "link-inactive"
          }
          to="/"
        >
          <FilterIcon style={{verticalAlign: 'sub'}}/> Templates
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive ? "link-active" : "link-inactive"
          }
          to="/admin/users"
        >
          <AccountCircleIcon/> Profile
        </NavLink>
        <Logout onClick={() => {
              dispatch(logoutUser(null));
              toast.warning("Logged out!", { position: "bottom-left" });
            }}><LogoutIcon/> Logout</Logout>
      </SideNav>
    
    </>
  );
};

export default AdminNav;

const StyledDashboard = styled.div`
  display: flex;
  height: 100%;
`;

const Logout = styled.div`
    width:100%;
    cursor: pointer;
    text-decoration: none;
    margin-bottom: 30px;
    font-size: 14px;
    color: #5e6168;
    font-weight: 500;

    svg {
      vertical-align: bottom;
      font-size: 18px;
    }
`;

const SideNav = styled.div`
  height: 100vh;
  position: fixed;
  overflow-y: hidden;
  width: 230px;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  border-right: 1px solid #f3f4f6;

  h3 {
    margin: 0 0 1rem 0;
    padding: 0;
    text-transform: uppercase;
    font-size: 17px;
  }

  a {
    text-decoration: none;
    margin-bottom: 30px;
    font-size: 14px;
    color: #5e6168;
    font-weight: 500;
    svg {
      vertical-align: middle;
      font-size: 18px;
    }
  }
`;

const Content = styled.div`
  margin-left: 230px;
  padding: 2.5rem 4rem;
  width: 100%;
  background: #fcfcfd;
`;

const Footer = styled.div`
    width: 100%;
    text-align: center;
    padding: 15px 0;

    a {
        font-size: 13px;
        text-decoration: none;

        svg { font-size: 13px; }
    }
`;
