import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { categoriesCreate } from "../../slices/categoriesSlice";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const CreateProduct = () => {
  const dispatch = useDispatch();
  const reader = new FileReader();
  const { createStatus } = useSelector((state) => state.products);
  const { shops } = useSelector((state) => state.shops);

  const [categoryImg, setCategoryImg] = useState("");
  const [catLevel, setCatLevel] = useState("");
  const [shop, setShop] = useState("");
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [desc, setDesc] = useState("");
  const [upload, setUpload] = useState(0);


  const handleCategoryImageUpload = (e) => {
    const file = e.target.files[0];
    TransformFileData(file);
  };

  const TransformFileData = (file) => {
    if (file) {
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setCategoryImg(reader.result);
      };
    } else {
      setCategoryImg("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    dispatch(
      categoriesCreate({
        name,
        price,
        desc,
        image: categoryImg
      })
    );
  };

  return (
    <StyledCreateProduct>
      <h3>Create a new Category</h3>
      <StyledForm onSubmit={handleSubmit}>

          <input
            type="text"
            placeholder="Category Name"
            onChange={(e) => setName(e.target.value)}
            required
          />

          <select onChange={(e) => setCatLevel(e.target.value)} required>
            <option selected hidden disabled>Select Category Level</option>
            <option value="0">Main Category</option>
            <option value="1">Sub Category</option>
            <option value="2">Sub Sub Category</option>
          </select>

           <label>Add Category Image</label>
           <input
             id="imgUpload"
             accept="image/*"
             type="file"
             onChange={handleCategoryImageUpload}
             required
           />
        
           <ImagePreview>
             {categoryImg ? (
               <>
                 <img src={categoryImg} alt="error!" />
               </>
               ) : ''
             }
           </ImagePreview>

        <SubmitButton type="submit">
          {createStatus === "pending" ? "Submitting" : "Submit"}
        </SubmitButton>
      </StyledForm>
      
    </StyledCreateProduct>
  );
};

export default CreateProduct;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 15px;

  select,
  input[type='text'], input[type='number'] {
    padding: 10px;
    outline: none;
    border-radius: 5px;
    border: 1px solid rgb(223 222 222);
    margin: 0 0 20px;
    font-size: 14px;

    &:focus {
      border: 1px solid #ddd;
    }
  }

  select {
    color: rgb(95, 95, 95);
  }
  input[type='file'] {
    width: 174px;
  }
  label {
    font-weight:600;
    margin-bottom: 10px;
  }
`;

const StyledCreateProduct = styled.div`
      padding: 30px;
    box-shadow: 1px 1px 12px 0px #ddd;
    border-radius: 10px;
    width: 550px;
    margin: 0 auto;
`;

const ImagePreview = styled.div`
  max-width: 300px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
  }
`;

const SubmitButton = styled.button`
   margin: 0 auto;
    width: 300px;
    font-size: 17px;
    padding: 12px 12px;
    border-radius: 5px;
    font-weight: 400;
    letter-spacing: 1.15px;
    background-color: #4b70e2;
    color: #f9f9f9;
    border: none;
    outline: none;
    cursor: pointer;
    margin-top: 30px;
`;

const Gallery = styled.div`
   img {
     width: 200px;
    height: 200px;
    object-fit: cover;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin: 6px;
    display: inline-block;
   }
`;
