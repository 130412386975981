import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { url, setHeaders } from "./api";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { PrimaryButton, Card, Heading, FormField } from "./CommonStyled";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Person2Icon from '@mui/icons-material/Person2';
import TemplateOne from "./templates/TemplateOne";
import TemplateTwo from "./templates/TemplateTwo";
import PreviewTemplate from "./templates/PreviewTemplate";
import VisibilityIcon from '@mui/icons-material/Visibility';
import SaveIcon from '@mui/icons-material/Save';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import ImageIcon from '@mui/icons-material/Image';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import AddIcon from '@mui/icons-material/Add';
import './editor.css';
import pulse from './images/pulse.svg';
import checkmark from './images/check-mark.png';
import { cardsEdit } from "../../slices/cardsSlice"; 
import CopyrightIcon from '@mui/icons-material/Copyright';
import AdminNav from "./AdminNav";
import { useParams } from "react-router-dom";
import SettingsIcon from '@mui/icons-material/Settings';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LanguageIcon from '@mui/icons-material/Language';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ChatIcon from '@mui/icons-material/Chat';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import { SketchPicker } from "react-color";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import FormatLineSpacingIcon from '@mui/icons-material/FormatLineSpacing';
import skype from './images/skype.png';
import messenger from './images/messenger.png';
import pinterest from './images/pinterest.png';
import youtube from './images/youtube.png';
import tiktok from './images/tiktok.png';
import telegram from './images/telegram.png';
import twitter from './images/twitter.png';
import tumblr from './images/tumblr.png';
import viber from './images/viber.png';
import snapchat from './images/snapchat.png';
import wechat from './images/wechat.png';
import reddit from './images/reddit.png';
import Captcha from "react-numeric-captcha";


const EditCard = () => {

  const dispatch = useDispatch();
  const { editStatus } = useSelector((state) => state.cards);
  const { cards } = useSelector((state) => state.cards);
  console.log(cards);

  const params = useParams(); 
  //console.log(params.id);
  let card = cards.filter((item) => item.id === parseInt(params.id));
  console.log(card);

  const [template, setTemplate] = useState(1);

  const [data, setData] = useState({
    firstname: '',
    lastname: '',
    name_color: {},
    font_size_name: 20,
    font_weight_name: 600,
    font_style_name: 'normal',
    font_underline_name: 'none',
    name_text_align: 'center',
    name_margin_top: 0,
    jobtitle: '',
    jobtitle_color: {},
    font_size_jobtitle: 14,
    font_weight_jobtitle: 400,
    font_style_jobtitle: 'normal',
    font_underline_jobtitle: 'none',
    jobtitle_text_align: 'center',
    company: '',
    company_color: {},
    font_size_company: 14,
    font_weight_company: 400,
    font_style_company: 'normal',
    font_underline_company: 'none',
    company_text_align: 'center',
    description: '',
    description_color: {},
    font_size_description: 14,
    font_weight_description: 400,
    font_style_description: 'normal',
    font_underline_description: 'none',
    description_padding_top: 30,
    description_padding_bottom: 30,
    description_text_align: 'center',
    description_line_height: 25,
    address: '',
    address_color: {},
    font_size_address: 14,
    font_weight_address: 400,
    font_style_address: 'normal',
    font_underline_address: 'none',
    address_text_align: 'center',
    email: '',
    number_mobile: '',
    number_sms: '',
    number_whatsapp: '',
    website: '',
    location: '',
    facebook: '',
    instagram: '',
    linkedin: '',
    skype: '',
    messenger: '',
    pinterest: '',
    twitter: '',
    telegram: '',
    snapchat: '',
    viber: '',
    tumblr: '',
    wechat: '',
    youtube: '',
    reddit: '',
    tiktok: '',
    contact_details_padding_top: 30,
    contact_details_padding_bottom: 30,
    contactForm: 0,
    contactForm_title: 0,
    contactForm_title_label: '',
    contactForm_title_color: {},
    font_size_contactForm_title: 22,
    font_weight_contactForm_title: 500,
    font_style_contactForm_title: 'normal',
    font_underline_contactForm_title: 'none',
    contactForm_title_padding_top: 10,
    contactForm_title_padding_bottom: 30,
    contactForm_title_text_align: 'center',
    contactForm_email_recipient: '',
    contactForm_email_host: '',
    contactForm_email_username: '',
    contactForm_email_password: '', 
    contact_form_padding_top: 40,
    contact_form_padding_bottom: 40,
    profile_pic: '',
    profile_pic_shape: '50%',
    profile_pic_horizontal_pos: 'center',
    profile_pic_vertical_pos: 10,
    profile_pic_border_width: 2,
    profile_pic_border_color: {},
    header_img: '',
    company_logo_show: 0,
    company_logo: '',
    company_logo_shape: '0',
    company_logo_vertical_pos: 10,
    company_logo_horizontal_pos: 'center',
    company_logo_border_width: 2,
    company_logo_border_color: {},
    background_color: {},
    vcard_gradient: 'no',
    vcard_gradient_type: 'linear',
    vcard_gradient_color_1: {},
    vcard_gradient_color_2: {},
    vcard_gradient_angle: 30,
    screen_type: 'color-background',
    background_color_body: {},
    body_gradient_color_1: {},
    body_gradient_color_2: {},
    body_gradient_type: 'linear',
    body_gradient_angle: 30,
    body_background_image: '',
    body_background_image_repeat: '0',
    showTestimony: 0,
    testimonial_title: 0,
    testimonial_title_label: '',
    testimonial_title_color: {},
    font_size_testimonial_title: 22,
    font_weight_testimonial_title: 500,
    font_style_testimonial_title: 'normal',
    font_underline_testimonial_title: 'none',
    testimonial_title_padding_top: 10,
    testimonial_title_padding_bottom: 30,
    testimonial_title_text_align: 'center',
    testimonial: [],
    testimonial_padding_top: 40,
    testimonial_padding_bottom: 40,
    custom_image_show: 0,
    custom_image: '',
    custom_image_title: 0,
    custom_image_title_label: '',
    custom_image_title_color: {},
    font_size_custom_image_title: 22,
    font_weight_custom_image_title: 500,
    font_style_custom_image_title: 'normal',
    font_underline_custom_image_title: 'none',
    custom_image_title_padding_top: 10,
    custom_image_title_padding_bottom: 30,
    custom_image_title_text_align: 'center',
    custom_image_border_width: 4,
    custom_image_border_color: {},
    custom_image_padding_top: 30,
    custom_image_padding_bottom: 30,
    second_custom_image_show: 0,
    second_custom_image: '',
    second_custom_image_title: 0,
    second_custom_image_title_label: '',
    second_custom_image_title_color: {},
    second_font_size_custom_image_title: 22,
    second_font_weight_custom_image_title: 500,
    second_font_style_custom_image_title: 'normal',
    second_font_underline_custom_image_title: 'none',
    second_custom_image_title_padding_top: 10,
    second_custom_image_title_padding_bottom: 30,
    second_custom_image_title_text_align: 'center',
    second_custom_image_border_width: 4,
    second_custom_image_border_color: {},
    second_custom_image_padding_top: 30,
    second_custom_image_padding_bottom: 30,
    image_grid_show: 0,
    image_grid: [],
    image_grid_column: '1',
    image_grid_title: 0,
    image_grid_title_label: '',
    image_grid_title_color: {},
    font_size_image_grid_title: 22,
    font_weight_image_grid_title: 500,
    font_style_image_grid_title: 'normal',
    font_underline_image_grid_title: 'none',
    image_grid_title_padding_top: 10,
    image_grid_title_padding_bottom: 30,
    image_grid_title_text_align: 'center',
    image_grid_padding_top: 30,
    image_grid_padding_bottom: 30,
    add_block_image_text: 0,
    block_image_text_title: 0,
    block_image_text_title_label: '',
    block_image_text_title_color: {},
    font_size_block_image_text_title: 16,
    font_weight_block_image_text_title: 500,
    font_style_block_image_text_title: 'normal',
    font_underline_block_image_text_title: 'none',
    block_image_text_title_text_align: 'left',
    block_image_text_title_padding_top: 0,
    block_image_text_title_padding_bottom: 10,
    block_image_text_title_padding_left: 10,
    block_image_text_title_padding_right: 10,
    block_image_text_paragraph: '',
    block_image_text_img: '',
    block_image_text_img_padding_left: 5,
    block_image_text_img_padding_right: 5,
    block_image_text_img_padding_top: 5,
    block_image_text_img_padding_bottom: 5,
    block_image_text_img_border_width: 2,
    block_image_text_img_border_color: {},
    block_image_text_font_size: 13,
    block_image_text_font_weight: 300,
    block_image_text_line_height: 21,
    block_image_text_color: {},
    block_image_text_style: 'normal',
    block_image_text_underline: 'none',
    block_image_text_alignment: 'left',
    block_image_text_background: {},
    block_image_text_padding_top: 40,
    block_image_text_padding_bottom: 40,
    block_image_text_paragraph_padding_top: 0,
    block_image_text_paragraph_padding_left: 10,
    block_image_text_paragraph_padding_right: 10,
    block_image_text_paragraph_padding_bottom: 10,
    two_image_show: 0,
    two_image_first: '',
    two_image_first_link: '',
    two_image_first_link_show: 0,
    two_image_first_shape: '0',
    two_image_first_border_width: 0,
    two_image_first_border_color: {},
    two_image_last: '',
    two_image_last_link: '',
    two_image_last_link_show: 0,
    two_image_last_shape: '0',
    two_image_last_border_width: 0,
    two_image_last_border_color: {},
    two_image_title: 0,
    two_image_title_label: '',
    two_image_title_color: {},
    font_size_two_image_title: 22,
    font_weight_two_image_title: 500,
    font_style_two_image_title: 'normal',
    font_underline_two_image_title: 'none',
    two_image_title_padding_top: 10,
    two_image_title_padding_bottom: 30,
    two_image_title_text_align: 'center',
    two_image_padding_top: 30,
    two_image_padding_bottom: 30,
    appointment: 0,
    appointment_title: 0,
    appointment_title_label: '',
    appointment_title_color: {},
    font_size_appointment_title: 22,
    font_weight_appointment_title: 500,
    font_style_appointment_title: 'normal',
    font_underline_appointment_title: 'none',
    appointment_title_padding_top: 10,
    appointment_title_padding_bottom: 30,
    appointment_title_text_align: 'center',
    appointment_email_recipient: '',
    appointment_padding_top: 40,
    appointment_padding_bottom: 40,
    custom_text_show: 0,
    custom_text: '',
    custom_text_title: 0,
    custom_text_title_label: '',
    custom_text_title_color: {},
    font_size_custom_text_title: 22,
    font_weight_custom_text_title: 500,
    font_style_custom_text_title: 'normal',
    font_underline_custom_text_title: 'none',
    custom_text_title_padding_top: 10,
    custom_text_title_padding_bottom: 30,
    custom_text_title_text_align: 'center',
    custom_text_border_width: 4,
    custom_text_background_color: {},
    custom_text_font_size: 15,
    custom_text_font_weight: 400,
    custom_text_font_style: 'normal',
    custom_text_font_color: {},
    custom_text_font_text_align: 'center',
    custom_text_padding_top: 30,
    custom_text_padding_bottom: 30,
    second_custom_text_show: 0,
    second_custom_text: '',
    second_custom_text_title: 0,
    second_custom_text_title_label: '',
    second_custom_text_title_color: {},
    second_font_size_custom_text_title: 22,
    second_font_weight_custom_text_title: 500,
    second_font_style_custom_text_title: 'normal',
    second_font_underline_custom_text_title: 'none',
    second_custom_text_title_padding_top: 10,
    second_custom_text_title_padding_bottom: 30,
    second_custom_text_title_text_align: 'center',
    second_custom_text_border_width: 0,
    second_custom_text_background_color: {},
    second_custom_text_font_size: 14,
    second_custom_text_font_weight: 400,
    second_custom_text_font_style: 'normal',
    second_custom_text_font_color: {},
    second_custom_text_font_text_align: 'center',
    second_custom_text_padding_top: 30,
    second_custom_text_padding_bottom: 30,
    add_table: 0,
    table: [],
    table_title: 0,
    table_title_label: '',
    table_title_color: {},
    font_size_table_title: 22,
    font_weight_table_title: 500,
    font_style_table_title: 'normal',
    font_underline_table_title: 'none',
    table_title_padding_top: 10,
    table_title_padding_bottom: 30,
    table_title_text_align: 'center',
    table_border_width: 2,
    table_border_color: {},
    table_cell_padding: 10,
    table_cell_alignment: 'center',
    table_font_size: 14,
    table_font_weight: 400,
    table_font_style: 'normal',
    table_font_color: {},
    table_font_text_align: 'center',
    table_padding_top: 30,
    table_padding_bottom: 30,
    table_cell_background: {},
    add_accordion: 0,
    accordion_title: 0,
    accordion_title_label: '',
    accordion_title_color: {},
    font_size_accordion_title: 22,
    font_weight_accordion_title: 500,
    font_style_accordion_title: 'normal',
    font_underline_accordion_title: 'none',
    accordions: [],
    accordion_title_padding_top: 10,
    accordion_title_padding_bottom: 30,
    accordion_title_text_align: 'center',
    accordion_padding_top: 40,
    accordion_padding_bottom: 40,
    accordion_content_line_height: 30,
    map: 0,
    map_title: 0,
    map_title_label: '',
    map_title_color: {},
    font_size_map_title: 22,
    font_weight_map_title: 500,
    font_style_map_title: 'normal',
    font_underline_map_title: 'none',
    map_title_padding_top: 10,
    map_title_padding_bottom: 30,
    map_title_text_align: 'center',
    map_padding_top: 40,
    map_padding_bottom: 40,
    latitude: '37.42216',
    longitude: '-122.08427',
    add_iframe: 0,
    iframe_title: 0,
    iframe_title_label: '',
    iframe_title_color: {},
    font_size_iframe_title: 22,
    font_weight_iframe_title: 500,
    font_style_iframe_title: 'normal',
    font_underline_iframe_title: 'none',
    iframe_title_padding_top: 10,
    iframe_title_padding_bottom: 30,
    iframe_title_text_align: 'center',
    iframe_url: '',
    iframe_width: 350,
    iframe_height: 200,
    iframe_scrollbar: 'yes',
    iframe_show_border: 'no',
    iframe_border_type: 'solid',
    iframe_border_width: 2,
    iframe_border_color: {},
    iframe_padding_top: 30,
    iframe_padding_bottom: 30,
    second_add_iframe: 0,
    second_iframe_title: 0,
    second_iframe_title_label: '',
    second_iframe_title_color: {},
    second_font_size_iframe_title: 22,
    second_font_weight_iframe_title: 500,
    second_font_style_iframe_title: 'normal',
    second_font_underline_iframe_title: 'none',
    second_iframe_title_padding_top: 10,
    second_iframe_title_padding_bottom: 30,
    second_iframe_title_text_align: 'center',
    second_iframe_url: '',
    second_iframe_width: 350,
    second_iframe_height: 200,
    second_iframe_scrollbar: 'yes',
    second_iframe_show_border: 'no',
    second_iframe_border_type: 'solid',
    second_iframe_border_width: 2,
    second_iframe_border_color: {},
    second_iframe_padding_top: 30,
    second_iframe_padding_bottom: 30,
    third_add_iframe: 0,
    third_iframe_title: 0,
    third_iframe_title_label: '',
    third_iframe_title_color: {},
    third_font_size_iframe_title: 22,
    third_font_weight_iframe_title: 500,
    third_font_style_iframe_title: 'normal',
    third_font_underline_iframe_title: 'none',
    third_iframe_title_padding_top: 10,
    third_iframe_title_padding_bottom: 30,
    third_iframe_title_text_align: 'center',
    third_iframe_url: '',
    third_iframe_width: 350,
    third_iframe_height: 200,
    third_iframe_scrollbar: 'yes',
    third_iframe_show_border: 'no',
    third_iframe_border_type: 'solid',
    third_iframe_border_width: 2,
    third_iframe_border_color: {},
    third_iframe_padding_top: 30,
    third_iframe_padding_bottom: 30,
    fourth_add_iframe: 0,
    fourth_iframe_title: 0,
    fourth_iframe_title_label: '',
    fourth_iframe_title_color: {},
    fourth_font_size_iframe_title: 22,
    fourth_font_weight_iframe_title: 500,
    fourth_font_style_iframe_title: 'normal',
    fourth_font_underline_iframe_title: 'none',
    fourth_iframe_title_padding_top: 10,
    fourth_iframe_title_padding_bottom: 30,
    fourth_iframe_title_text_align: 'center',
    fourth_iframe_url: '',
    fourth_iframe_width: 350,
    fourth_iframe_height: 200,
    fourth_iframe_scrollbar: 'yes',
    fourth_iframe_show_border: 'no',
    fourth_iframe_border_type: 'solid',
    fourth_iframe_border_width: 2,
    fourth_iframe_border_color: {},
    fourth_iframe_padding_top: 30,
    fourth_iframe_padding_bottom: 30,
    video: 0,
    video_title: 0,
    video_title_label: '',
    video_title_color: {},
    font_size_video_title: 22,
    font_weight_video_title: 500,
    font_style_video_title: 'normal',
    font_underline_video_title: 'none',
    video_title_padding_top: 10,
    video_title_padding_bottom: 30,
    video_title_text_align: 'center',
    videolink: 'https://www.w3schools.com/html/mov_bbb.mp4',
    video_padding_top: 40,
    video_padding_bottom: 40,
    audio: 0,
    audio_title: 0,
    audio_title_label: '',
    audio_title_color: {},
    font_size_audio_title: 22,
    font_weight_audio_title: 500,
    font_style_audio_title: 'normal',
    font_underline_audio_title: 'none',
    audio_title_padding_top: 10,
    audio_title_padding_bottom: 30,
    audio_title_text_align: 'center',
    audiolink: '',
    audio_padding_top: 40,
    audio_padding_bottom: 40,
    font_family: '"Montserrat", sans-serif',
    save_contact_button_background: {},
    save_contact_button_font_color: {},
    save_contact_button_font_weight: 500,
    save_contact_button_font_size: 16,
    save_contact_button_border_radious: 30,
    save_contact_button_border_color: {},
    save_contact_button_border_width: 0,
    save_contact_button_padding_top: 20,
    save_contact_button_padding_bottom: 20,
    add_custom_button: 0,
    custom_button_text: 'Button text',
    custom_button_link: '',
    custom_button_width: 200,
    custom_button_height: 60,
    custom_button_background: {},
    custom_button_font_color: {},
    custom_button_font_weight: 500,
    custom_button_font_size: 16,
    custom_button_border_width: 2,
    custom_button_border_color: {},
    custom_button_border_radious: 5,
    custom_button_position: 'center',
    custom_button_animation: '1',
    custom_button_margin_top: 20,
    custom_button_margin_bottom: 20,
    second_add_custom_button: 0,
    second_custom_button_text: 'Button',
    second_custom_button_link: '',
    second_custom_button_width: 200,
    second_custom_button_height: 60,
    second_custom_button_background: {},
    second_custom_button_font_color: {},
    second_custom_button_font_weight: 600,
    second_custom_button_font_size: 20,
    second_custom_button_border_width: 3,
    second_custom_button_border_color: {},
    second_custom_button_border_radious: 10,
    second_custom_button_position: 'center',
    second_custom_button_animation: '1',  
    second_custom_button_margin_top: 20,
    second_custom_button_margin_bottom: 20,
    add_text_between_line: 0,
    text_between_line_word: 'TEXT HERE',
    text_between_line_word_font_size: 20,
    text_between_line_word_font_weight: 500,
    text_between_line_word_color: {},
    text_between_line_word_format: 'regular',
    text_between_line_size: 2,
    text_between_line_color: {}, 
    text_between_line_space_top: 30,
    text_between_line_space_bottom: 30,
    second_add_text_between_line: 0,
    second_text_between_line_word: 'TEXT HERE',
    second_text_between_line_word_font_size: 16,
    second_text_between_line_word_font_weight: 500,
    second_text_between_line_word_color: {},
    second_text_between_line_word_format: 'regular',
    second_text_between_line_size: 2,
    second_text_between_line_color: {}, 
    second_text_between_line_space_top: 30,
    second_text_between_line_space_bottom: 30,
    third_add_text_between_line: 0,
    third_text_between_line_word: 'TEXT HERE',
    third_text_between_line_word_font_size: 16,
    third_text_between_line_word_font_weight: 500,
    third_text_between_line_word_color: {},
    third_text_between_line_word_format: 'regular',
    third_text_between_line_size: 2,
    third_text_between_line_color: {}, 
    third_text_between_line_space_top: 30,
    third_text_between_line_space_bottom: 30,
    fourth_add_text_between_line: 0,
    fourth_text_between_line_word: 'TEXT HERE',
    fourth_text_between_line_word_font_size: 16,
    fourth_text_between_line_word_font_weight: 500,
    fourth_text_between_line_word_color: {},
    fourth_text_between_line_word_format: 'regular',
    fourth_text_between_line_size: 2,
    fourth_text_between_line_color: {}, 
    fourth_text_between_line_space_top: 30,
    fourth_text_between_line_space_bottom: 30,
    add_payment_button: 0,
    payment_button_title: 0,
    payment_button_title_label: '',
    payment_button_title_color: {},
    font_size_payment_button_title: 22,
    font_weight_payment_button_title: 500,
    font_style_payment_button_title: 'normal',
    font_underline_payment_button_title: 'none',
    payment_button_title_padding_top: 10,
    payment_button_title_padding_bottom: 30,
    payment_button_title_text_align: 'center',
    payment_button_text: 'Add to Cart',
    payment_button_link: '',
    payment_button_background: {},
    payment_button_font_color: {},
    payment_button_height: 50,
    payment_button_text_align: 'center',
    payment_button_font_weight: 500,
    payment_button_font_size: 16,
    payment_button_border_width: 2,
    payment_button_border_color: {},
    payment_button_border_radious: 0,
    products: [],
    paypal_live_mode: 0,
    paypal_business_account_sandbox: '',
    paypal_business_account_live: '',
    payment_button_margin_top: 30,
    payment_button_margin_bottom: 30,
    blocks: [],
  });
  console.log(data);

  const [preview, setPreview] = useState(false);
  const [scrollLimit, setScrollLimit] = useState(false);

  const [profile_upload, setProfileUpload] = useState(false); 
  const handleFileChange = (e) => {
     setProfileUpload(true);
     const img = {
       data: e.target.files[0],
     }
  
     var formData = new FormData();     
     formData.append("name", "xyz");
     formData.append("files", img.data);

    fetch("https://nfcleads.au/nodeapi/upload_image", {
        method: 'POST',
        body: formData,
    }).then(res => res.json().then(parsedValue => {
            // code that can access both here
            var uploaded_image = parsedValue.message[0].filename;
            setData({...data, profile_pic: uploaded_image});
            setProfileUpload(false);
        }))
      .catch((err) => ("Error occured", err))
  }

  const removeProfilePicture = () => {
     setData({...data, profile_pic: ''});
  }

  const [company_logo_upload, setCompanyLogoUpload] = useState(false); 
  const handleCompanyLogoChange = (e) => {
     setCompanyLogoUpload(true);
    
    const company_img = {
       data: e.target.files[0],
     }
  
     var formData = new FormData();     
     formData.append("name", "company_img");
     formData.append("company_img", company_img.data); 

    fetch("https://nfcleads.au/nodeapi/company_logo", {
        method: 'POST',
        body: formData,
    }).then(res => res.json().then(parsedValue => {
            // code that can access both here
            var uploaded_image = parsedValue.message[0].filename;
            setData({...data, company_logo: uploaded_image});
            setCompanyLogoUpload(false);
        }))
      .catch((err) => ("Error occured", err))
  }

  const removeCompanyLogo = () => {
     setData({...data, company_logo: ''});
  }

  const [download, setDownload] = useState("");
  const [download_link, setDownloadLink] = useState("");
  const downloadCard = () => {
     setDownload("prepare");
     setTimeout(() => {
        axios.post(
                `${url}/test123`, 
                {data : data},
                setHeaders()
          ).then((response) => {
               console.log(response.data);
               setDownloadLink(response.data.download_link);
               setDownload('complete');
          });  
        },2000);
  }

  const saveCard = () => {
    if(!captchaSuccess){
         alert("Please verify captcha");
         return;
    }
     dispatch(
      cardsEdit({data})
    );
  }

    // Accordian
const [expanded, setExpanded] = useState(false);
const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
};

  const [open_color_pallate, setOpenColorPallate] = useState(false);
  const togglePallate = () => {
    if(open_color_pallate === true){
       setOpenColorPallate(false);
    }else{
      setOpenColorPallate(true);
    }
  }
  //creating state to store our color and also set color using onChange event for sketch picker
  const [sketchPickerColor, setSketchPickerColor] = useState({
    r: "245",
    g: "255",
    b: "235",
    a: "100",
  });

  const [bodyColor, setBodyColor] = useState({
    r: "255",
    g: "255",
    b: "255",
    a: "100",
  });

  const [profile_pic_border_color, setProfilePictureFrameColor] = useState({
    r: "255",
    g: "255",
    b: "255",
    a: "100",
  });

  const [name_color, setNameColor] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "100",
  });

  const [address_color, setAddressColor] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "100",
  });

  const [jobtitle_color, setJobtitleColor] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "100",
  });

  const [company_color, setCompanyColor] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "100",
  });

  const [description_color, setDescriptionColor] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "100",
  });

  const [custom_image_border_color, setCustomImageBorderColor] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "100",
  });

  const [video_title_color, setVideoTitleColor] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "100",
  });

  const [audio_title_color, setAudioTitleColor] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "100",
  });

  const [testimonial_title_color, setTestimonialTitleColor] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "100",
  });

  const [map_title_color, setMapTitleColor] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "100",
  });

  const [appointment_title_color, setAppointmentTitleColor] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "100",
  });

  const [contactForm_title_color, setContactFormTitleColor] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "100",
  });

  const [image_grid_title_color, setImageGridTitleColor] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "100",
  });

  const [table_font_color, setTableFontColor] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "100",
  });

  const [table_title_color, setTableTitleColor] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "100",
  });

  const [table_border_color, setTableBorderColor] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "100",
  });

  const [custom_text_title_color, setCustomTextTitleColor] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "100",
  });

  const [custom_image_title_color, setCustomImageTitleColor] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "100",
  });

  const [second_custom_image_title_color, setSecondCustomImageTitleColor] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "100",
  });

  const [second_custom_image_border_color, setSecondCustomImageBorderColor] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "100",
  });

  const [custom_text_background_color, setCustomTextBackgroundColor] = useState({
    r: "255",
    g: "255",
    b: "255",
    a: "100",
  });

  const [custom_text_font_color, setCustomTextFontColor] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "100",
  });

  const [second_custom_text_title_color, setSecondCustomTextTitleColor] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "100",
  });

  const [second_custom_text_background_color, setSecondCustomTextBackgroundColor] = useState({
    r: "255",
    g: "255",
    b: "255",
    a: "100",
  });

  const [second_custom_text_font_color, setSecondCustomTextFontColor] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "100",
  });

  const [two_image_title_color, setTwoImageTitleColor] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "100",
  }); 

  const [iframe_title_color, setIframeTitleColor] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "100",
  });

  const [accordion_title_color, setAccordionTitleColor] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "100",
  }); 

  const [block_image_text_color, setBlockImageTextColor] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "100",
  }); 

  const [block_image_text_background, setBlockImageTextBackground] = useState({
    r: "255",
    g: "255",
    b: "255",
    a: "100",
  });

  const [block_image_text_title_color, setBlockImageTextTitleColor] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "100",
  });

  const [GradientPrimaryColour, setGradientPrimaryColour] = useState({
    r: "189",
    g: "16",
    b: "224",
    a: "100",
  }); 

  const [GradientSecondaryColour, setGradientSecondaryColour] = useState({
    r: "80",
    g: "227",
    b: "194",
    a: "100",
  }); 

  const [BodyGradientPrimaryColour, setBodyGradientPrimaryColour] = useState({
    r: "189",
    g: "16",
    b: "224",
    a: "100",
  }); 

  const [BodyGradientSecondaryColour, setBodyGradientSecondaryColour] = useState({
    r: "80",
    g: "227",
    b: "194",
    a: "100",
  });

  const [two_image_first_border_color, setTwoImageFirstBorderColor] = useState({
    r: "80",
    g: "227",
    b: "194",
    a: "100",
  });

  const [two_image_last_border_color, setTwoImageLastBorderColor] = useState({
    r: "80",
    g: "227",
    b: "194",
    a: "100",
  });

  const [block_image_text_img_border_color, setBlockImageTextImgBorderColor] = useState({
    r: "80",
    g: "227",
    b: "194",
    a: "100",
  });

  const [company_logo_border_color, setCompanyLogoBorderColor] = useState({
    r: "80",
    g: "227",
    b: "194",
    a: "100",
  }); 

  const [table_cell_background, setTableCellBackground] = useState({
    r: "255",
    g: "255",
    b: "255",
    a: "100",
  });

  const [save_contact_button_background, setSaveContactButtonBackground] = useState({
    r: "255",
    g: "87",
    b: "34",
    a: "1",
  }); 

  const [save_contact_button_font_color, setSaveContactButtonFontColor] = useState({
    r: "255",
    g: "255",
    b: "255",
    a: "1",
  }); 

  const [save_contact_button_border_color, setSaveContactButtonBorderColor] = useState({
    r: "255",
    g: "255",
    b: "255",
    a: "1",
  }); 

  const [text_between_line_color, setTextBetweenLineColor] = useState({
    r: "255",
    g: "255",
    b: "255",
    a: "1",
  });

  const [text_between_line_word_color, setTextBetweenLineWordColor] = useState({
    r: "255",
    g: "255",
    b: "255",
    a: "1",
  }); 

  const [second_text_between_line_color, setSecondTextBetweenLineColor] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "1",
  });

  const [second_text_between_line_word_color, setSecondTextBetweenLineWordColor] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "1",
  }); 

  const [third_text_between_line_color, setThirdTextBetweenLineColor] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "1",
  });

  const [third_text_between_line_word_color, setThirdTextBetweenLineWordColor] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "1",
  }); 

  const [fourth_text_between_line_color, setFourthTextBetweenLineColor] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "1",
  });

  const [fourth_text_between_line_word_color, setFourthTextBetweenLineWordColor] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "1",
  }); 

  const [custom_button_background, setCustomButtonBackground] = useState({
    r: "255",
    g: "255",
    b: "255",
    a: "1",
  }); 

  const [custom_button_font_color, setCustomButtonFontColor] = useState({
    r: "255",
    g: "255",
    b: "255",
    a: "1",
  }); 

  const [custom_button_border_color, setCustomButtonBorderColor] = useState({
    r: "255",
    g: "255",
    b: "255",
    a: "1",
  });

  const [second_custom_button_background, setSecondCustomButtonBackground] = useState({
    r: "254",
    g: "72",
    b: "128",
    a: "1",
  }); 

  const [second_custom_button_font_color, setSecondCustomButtonFontColor] = useState({
    r: "254",
    g: "72",
    b: "128",
    a: "1",
  }); 

  const [second_custom_button_border_color, setSecondCustomButtonBorderColor] = useState({
    r: "254",
    g: "72",
    b: "128",
    a: "1",
  });

  const [iframe_border_color, setIframeBorderColor] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "1",
  }); 

  const [second_iframe_border_color, setSecondIframeBorderColor] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "1",
  });  

  const [second_iframe_title_color, setSecondIframeTitleColor] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "100",
  });
  
  const [third_iframe_border_color, setThirdIframeBorderColor] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "1",
  });  

  const [third_iframe_title_color, setThirdIframeTitleColor] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "100",
  });

  const [fourth_iframe_border_color, setFourthIframeBorderColor] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "1",
  });  

  const [fourth_iframe_title_color, setFourthIframeTitleColor] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "100",
  });

  const [payment_button_font_color, setPaymentButtonFontColor] = useState({
    r: "255",
    g: "255",
    b: "255",
    a: "100",
  });

  const [payment_button_background, setPaymentButtonBackground] = useState({
    r: "93",
    g: "13",
    b: "161",
    a: "100",
  });

  const [payment_button_border_color, setPaymentButtonBorderColor] = useState({
    r: "93",
    g: "13",
    b: "161",
    a: "100",
  });

  const [payment_button_title_color, setPaymentButtonTitleColor] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "1",
  });


const [testimonial, setTestimonial] = useState([]);
const [image_grid, setImageGrid] = useState([]);

const [header_upload, setHeaderUpload] = useState(false); 
const handleHeaderChange = (e) => {
    setHeaderUpload(true);
    const head_img = {
       data: e.target.files[0],
     }
  
     var formData = new FormData();     
     formData.append("name", "header_img");
     formData.append("header_img", head_img.data); 

    fetch("https://nfcleads.au/nodeapi/upload_header_image", {
        method: 'POST',
        body: formData,
    }).then(res => res.json().then(parsedValue => {
            // code that can access both here
            var uploaded_image = parsedValue.message[0].filename;
            setData({...data, header_img: uploaded_image});
            setHeaderUpload(false);
        }))
      .catch((err) => ("Error occured", err))
  }

  const removeHeaderImage = () => {
     setData({...data, header_img: ''});
  }

  // Handle Testimonial Upload
  const [testimonial_upload, setTestimonialUpload] = useState(0);
  const handleTestimonialUpload = (e) => {
     setTestimonialUpload(1);
     const testimonial_img = {
       data: e.target.files[0],
     }
     var formData = new FormData();     
     formData.append("name", "testimonial_img");
     formData.append("testimonial_img", testimonial_img.data); 

    fetch("https://nfcleads.au/nodeapi/upload_testimonial", {
        method: 'POST',
        body: formData,
    }).then(res => res.json().then(parsedValue => {
            // code that can access both here
            var uploaded_image = parsedValue.message[0].filename;
            data.testimonial.push(uploaded_image);
            setTestimonialUpload(2);
        }))
      .catch((err) => ("Error occured", err))
  }

  const removeFromTestimony = (e) => {
    var id = e.target.getAttribute("id");
    var test = data.testimonial.filter(item => item !== id);
    setData({...data, testimonial: test});
  }

// Body background image upload
  const [body_background_image_upload, setBodyBackgroundImageUpload] = useState(false);
  const handleBodyBackgroundImage = (e) => {
    setBodyBackgroundImageUpload(true);
    let custom_img_body = {
       data: e.target.files[0],
     }
  
     var formData = new FormData();     
     formData.append("name", "custom_img_body");
     formData.append("custom_img_body", custom_img_body.data); 

    fetch("https://nfcleads.au/nodeapi/upload_custom_img_body", {
        method: 'POST',
        body: formData,
    }).then(res => res.json().then(parsedValue => {
            // code that can access both here
            var uploaded_image = parsedValue.message[0].filename;
            setData({...data, body_background_image: uploaded_image});
            setBodyBackgroundImageUpload(false);
        }))
      .catch((err) => ("Error occured", err))
  }

  const removeBodyBackgroundImage = () => {
     setData({...data, body_background_image: ''});
  }

   // Image Grid upload 
  const [image_grid_upload, setImageGridUpload] = useState(0);
  const handleImageGridUpload = (e) => {
      setImageGridUpload(1);
      const image_grid_img = {
       data: e.target.files[0],
     }
  
     var formData = new FormData();     
     formData.append("name", "image_grid_img");
     formData.append("image_grid_img", image_grid_img.data); 

    fetch("https://nfcleads.au/nodeapi/upload_image_grid", {
        method: 'POST',
        body: formData,
    }).then(res => res.json().then(parsedValue => {
            // code that can access both here
            var uploaded_image = parsedValue.message[0].filename;
            setImageGrid([...image_grid, uploaded_image]);
            setImageGridUpload(2);
        }))
      .catch((err) => ("Error occured", err))
  }

  const removeFromImageGrid = (e) => {
    var id = e.target.getAttribute("id");
    var test = image_grid.filter(item => item !== id);
    setImageGrid(test);
  }

// Custom image upload
  const [custom_image_upload, setCustomImageUpload] = useState(false);
  const handleUploadCustomImage = (e) => {
    setCustomImageUpload(true);
    const custom_img = {
       data: e.target.files[0],
     }
  
     var formData = new FormData();     
     formData.append("name", "custom_img");
     formData.append("custom_img", custom_img.data); 

    fetch("https://nfcleads.au/nodeapi/upload_custom_image", {
        method: 'POST',
        body: formData,
    }).then(res => res.json().then(parsedValue => {
            // code that can access both here
            var uploaded_image = parsedValue.message[0].filename;
            setData({...data, custom_image: uploaded_image});
            setCustomImageUpload(false);
        }))
      .catch((err) => ("Error occured", err))
  }

const removeCustomImage = () => {
  setData({...data, custom_image: ''});
}

// Second Custom image upload
  const [second_custom_image_upload, setSecondCustomImageUpload] = useState(false);
  const handleUploadSecondCustomImage = (e) => {
    setSecondCustomImageUpload(true);
    const second_custom_img = {
       data: e.target.files[0],
     }
  
     var formData = new FormData();     
     formData.append("name", "second_custom_img");
     formData.append("second_custom_img", second_custom_img.data); 

    fetch("https://nfcleads.au/nodeapi/upload_second_custom_image", {
        method: 'POST',
        body: formData,
    }).then(res => res.json().then(parsedValue => {
            // code that can access both here
            var uploaded_image = parsedValue.message[0].filename;
            setData({...data, second_custom_image: uploaded_image});
            setSecondCustomImageUpload(false);
        }))
      .catch((err) => ("Error occured", err))
  }

  const removeSecondCustomImage = () => {
     setData({...data, second_custom_image: ''});
  }
  // Two image upload first
const [two_image_upload_first, setTwoImageUploadFirst] = useState(false);
const handleUploadTwoImageFirst = (e) => {
    setTwoImageUploadFirst(true);
    const upload_first = {
       data: e.target.files[0],
     }
  
     var formData = new FormData();     
     formData.append("name", "two_image_upload_first");
     formData.append("two_image_upload_first", upload_first.data); 

    fetch("https://nfcleads.au/nodeapi/two_image_upload_first", {
        method: 'POST',
        body: formData,
    }).then(res => res.json().then(parsedValue => {
            // code that can access both here
            var uploaded_image = parsedValue.message[0].filename;
            setData({...data, two_image_first: uploaded_image});
            setTwoImageUploadFirst(false);
        }))
      .catch((err) => ("Error occured", err))
}
const removeTwoImgFirst = () => {
     setData({...data, two_image_first: ''});
}

// Two image upload last
const [two_image_upload_last, setTwoImageUploadLast] = useState(false);
const handleUploadTwoImageLast = (e) => {
    setTwoImageUploadLast(true);
    const upload_last = {
       data: e.target.files[0],
     }
  
     var formData = new FormData();     
     formData.append("name", "two_image_upload_last");
     formData.append("two_image_upload_last", upload_last.data); 

    fetch("https://nfcleads.au/nodeapi/two_image_upload_last", {
        method: 'POST',
        body: formData,
    }).then(res => res.json().then(parsedValue => {
            // code that can access both here
            var uploaded_image = parsedValue.message[0].filename;
            setData({...data, two_image_last: uploaded_image});
            setTwoImageUploadLast(false);
        }))
      .catch((err) => ("Error occured", err))
}
const removeTwoImgLast = () => {
     setData({...data, two_image_last: ''});
}

// Table
const [rows, setRows] = useState(2); // Initial number of rows
const [columns, setColumns] = useState(2); // Initial number of columns
//const [tableData, setTableData] = useState([]);
const [create_table, setCreateTable] = useState(true);

  const handleRowsChange = (e) => {
    setRows(parseInt(e.target.value) || 0);
  };

  const handleColumnsChange = (e) => {
    setColumns(parseInt(e.target.value) || 0);
  };
  // create new table
  const handleSave = () => {
    const data_table = [];
    for (let i = 0; i < rows; i++) {
      const rowData = [];
      for (let j = 0; j < columns; j++) {
        rowData.push('');
      }
      data_table.push(rowData);
    }
    setData({...data, table: data_table});
    setCreateTable(false); 
  };

 const handleInputChange = (e, rowIndex, columnIndex) => {
    const newData = [...data.table];
    newData[rowIndex][columnIndex] = e.target.value;
  //  setTableData(newData);
    setData({...data, table: newData})
  };
// end Table

  // Block text image upload
  const [block_image_text_upload, setBlockTmageTextUpload] = useState(false);
  const handleUploadBlockImage = (e) => {
    setBlockTmageTextUpload(true);
    const block_image_text_img = {
       data: e.target.files[0],
     }
  
     var formData = new FormData();     
     formData.append("name", "block_image_text_img");
     formData.append("block_image_text_img", block_image_text_img.data); 

    fetch("https://nfcleads.au/nodeapi/upload_block_image", {
        method: 'POST',
        body: formData,
    }).then(res => res.json().then(parsedValue => {
            // code that can access both here
            var uploaded_image = parsedValue.message[0].filename;
            setData({...data, block_image_text_img: uploaded_image});
            setBlockTmageTextUpload(false);
        }))
      .catch((err) => ("Error occured", err))
  }
  const removeBlockImage = (e) => {
    setData({...data, block_image_text_img: ''});
  }

  // Start of accordion block
  const [show_new_accordion, setShowNewAccordion] = useState(true);
  const [accordions, setAccordions] = useState([]);
  const [numbers, setNumbers] = useState([]);
  const [p, addP] = useState(1);
  const addAccordion = () => {
     addP(p+1);
     if(numbers.length > 0){
         numbers.splice(0,1);
     }
     setNumbers([...numbers, {id : p}]);
     setShowNewAccordion(true);
  }
  const addToAccordion = (e) => {
    var id = e.target.getAttribute("id")
    var t_name = document.getElementById('name_accordion-' + id).value;
    var t_content = document.getElementById('content_accordion-' + id).value;
    setShowNewAccordion(false);
    setAccordions([...accordions, { id: id, name: t_name, content: t_content}]);
  }
  const updateAccordionTitle = (index, newTitle) => {
    var updatedItems = [...accordions]; // Create a copy of the original array
    updatedItems[index] = { ...updatedItems[index], name: newTitle };
    setAccordions(updatedItems);
  } 
  const updateAccordionContent = (index, newContent) => {
    var updatedItems = [...accordions]; // Create a copy of the original array
    updatedItems[index] = { ...updatedItems[index], content: newContent };
    setAccordions(updatedItems);
  } 
  const removeFromAccordion = (e) => {
    var id = e.target.getAttribute("id");
    console.log(id);
    console.log(numbers);
    setNumbers(numbers.filter(item => item.id != id));
    setAccordions(data.accordions.filter(item => item.id != id));
  }

  useEffect(() => {
     addP(data.accordions.length + 1);
     setAccordions(data.accordions);
  }, [data.accordions]);

  useEffect(() => {
     setData({...data, accordions: accordions});
     console.log(numbers);
  }, [accordions]);
  // End of accordion block

  // Start of product block
const addNewproduct = (e) => {
    let productsCopy = [...data.products];
    productsCopy.push({ _id: data.products.length, title: '', price: 0, description: '', product_image: '' });
    setData({...data, products: productsCopy});
}
const updateProductTitle = (index, newTitleP) => {
    var updatedItems = [...data.products]; // Create a copy of the original array
    updatedItems[index] = { ...updatedItems[index], title: newTitleP };
    setData({...data, products: updatedItems});
} 
const updateProductDescription = (index, newDescriptionP) => {
    var updatedItems = [...data.products]; // Create a copy of the original array
    updatedItems[index] = { ...updatedItems[index], description: newDescriptionP };
    setData({...data, products: updatedItems});
} 
const updateProductPrice = (index, newPrice) => {
    var updatedItems = [...data.products]; // Create a copy of the original array
    updatedItems[index] = { ...updatedItems[index], price: newPrice };
    setData({...data, products: updatedItems});
}
const [product_upload, setProductUpload] = useState(0);
const handleProductUpload = (index,e) => {
   setProductUpload(1);
   const product_img = {
      data: e.target.files[0],
   }
   var formData = new FormData();     
   formData.append("name", "product_img");
   formData.append("product_img", product_img.data); 

   fetch("https://nfcleads.au/nodeapi/upload_product_img", {
      method: 'POST',
      body: formData,
    }).then(res => res.json().then(parsedValue => {
         // code that can access both here
         var uploaded_image = parsedValue.message[0].filename;
         var updatedItems = [...data.products]; // Create a copy of the original array
         updatedItems[index] = { ...updatedItems[index], product_image: uploaded_image };
         setData({...data, products: updatedItems});
         setProductUpload(0);
    }))
    .catch((err) => ("Error occured", err)) 
}
const removeProductImage = (index) => {
   var updatedItems = [...data.products]; // Create a copy of the original array
   updatedItems[index] = { ...updatedItems[index], product_image: '' };
   setData({...data, products: updatedItems});
}
const removeFromProducts = (index) => {
    setData({...data, products: data.products.filter(item => data.products.indexOf(item) != index)});
}
useEffect(() => {
   console.log(data.products)
}, [data.products])
// End of product block

  useEffect(() => {
     console.log(testimonial);
     setData({...data, testimonial: testimonial});
  }, [testimonial]);

  useEffect(() => {
     console.log(image_grid);
     setData({...data, image_grid: image_grid});
  }, [image_grid]);


  useEffect(() => {
     document.addEventListener("scroll", (event) => {
     //console.log(window.scrollY);
     if(window.scrollY > 1100){
         setScrollLimit(true);
     }else{
        setScrollLimit(false);
      }
    });
  });


 useEffect(() => {
  if(cards.length > 0) {
    const modifiedString = card[0].table_view.replace(/<a href="([^"]*)">/g, '<a href=\\"$1\\">');
    const accordionsModified = card[0].accordions.replace(/<a href="([^"]*)">/g, '<a href=\\"$1\\">');

    setData({...data,
       id: card[0].id,
       firstname: card[0].firstname,
       lastname: card[0].lastname,
       name_color: JSON.parse(card[0].name_color),
       font_size_name: card[0].font_size_name,
       font_weight_name: card[0].font_weight_name,
       font_style_name: card[0].font_style_name,
       font_underline_name: card[0].font_underline_name,
       name_text_align: card[0].name_text_align,
       name_margin_top: card[0].name_margin_top,
       jobtitle: card[0].jobtitle,
       jobtitle_color: JSON.parse(card[0].jobtitle_color),
       font_size_jobtitle: card[0].font_size_jobtitle,
       font_weight_jobtitle: card[0].font_weight_jobtitle,
       font_style_jobtitle: card[0].font_style_jobtitle,
       font_underline_jobtitle: card[0].font_underline_jobtitle,
       jobtitle_text_align: card[0].jobtitle_text_align,
       company: card[0].company,
       company_color: JSON.parse(card[0].company_color),
       font_size_company: card[0].font_size_company,
       font_weight_company: card[0].font_weight_company,
       font_style_company: card[0].font_style_company,
       font_underline_company: card[0].font_underline_company,
       company_text_align: card[0].company_text_align,
       description: card[0].description,
       description_color: JSON.parse(card[0].description_color),
       font_size_description: card[0].font_size_description,
       font_weight_description: card[0].font_weight_description,
       font_style_description: card[0].font_style_description,
       font_underline_description: card[0].font_underline_description,
       description_padding_top: card[0].description_padding_top,
       description_padding_bottom: card[0].description_padding_bottom,
       description_text_align: card[0].description_text_align,
       description_line_height: card[0].description_line_height,
       email: card[0].email,
       number_mobile: card[0].number_mobile,
       number_sms: card[0].number_sms,
       number_whatsapp: card[0].number_whatsapp,
       address: card[0].address,
       address_color: JSON.parse(card[0].address_color),
       font_size_address: card[0].font_size_address,
       font_weight_address: card[0].font_weight_address,
       font_style_address: card[0].font_style_address,
       font_underline_address: card[0].font_underline_address,
       address_text_align: card[0].address_text_align,
       website: card[0].website,
       location: card[0].location,
       facebook: card[0].facebook,
       instagram: card[0].instagram,
       linkedin: card[0].linkedin,
       skype: card[0].skype,
       messenger: card[0].messenger,
       pinterest: card[0].pinterest,
       twitter: card[0].twitter,
       telegram: card[0].telegram,
       snapchat: card[0].snapchat,
       viber: card[0].viber,
       tumblr: card[0].tumblr,
       wechat: card[0].wechat,
       youtube: card[0].youtube,
       reddit: card[0].reddit,
       tiktok: card[0].tiktok,
       contact_details_padding_top: card[0].contact_details_padding_top,
       contact_details_padding_bottom: card[0].contact_details_padding_bottom,
       contactForm: card[0].contactForm,
       contactForm_title: card[0].contactForm_title,
       contactForm_title_label: card[0].contactForm_title_label,
       contactForm_title_color: JSON.parse(card[0].contactForm_title_color),
       font_size_contactForm_title: card[0].font_size_contactForm_title,
       font_weight_contactForm_title: card[0].font_weight_contactForm_title,
       font_style_contactForm_title: card[0].font_style_contactForm_title,
       font_underline_contactForm_title: card[0].font_underline_contactForm_title,
       contactForm_title_padding_top: card[0].contactForm_title_padding_top,
       contactForm_title_padding_bottom: card[0].contactForm_title_padding_bottom,
       contactForm_title_text_align: card[0].contactForm_title_text_align,
       contactForm_email_recipient: card[0].contactForm_email_recipient,
       contactForm_email_host: card[0].contactForm_email_host,
       contactForm_email_username: card[0].contactForm_email_username,
       contactForm_email_password: card[0].contactForm_email_password, 
       contact_form_padding_top: card[0].contact_form_padding_top,
       contact_form_padding_bottom: card[0].contact_form_padding_bottom,
       profile_pic: card[0].profile_pic,
       profile_pic_shape: card[0].profile_pic_shape,
       profile_pic_horizontal_pos: card[0].profile_pic_horizontal_pos,
       profile_pic_vertical_pos: card[0].profile_pic_vertical_pos,
       profile_pic_border_width: card[0].profile_pic_border_width,
       profile_pic_border_color: JSON.parse(card[0].profile_pic_border_color),
       header_img: card[0].header_img,
       company_logo_show: card[0].company_logo_show,
       company_logo: card[0].company_logo,
       company_logo_shape: card[0].company_logo_shape,
       company_logo_vertical_pos: card[0].company_logo_vertical_pos,
       company_logo_horizontal_pos: card[0].company_logo_horizontal_pos,
       company_logo_border_width: card[0].company_logo_border_width,
       company_logo_border_color: JSON.parse(card[0].company_logo_border_color),
       background_color: JSON.parse(card[0].background_color),
       vcard_gradient: card[0].vcard_gradient,
       vcard_gradient_type: card[0].vcard_gradient_type,
       vcard_gradient_color_1: JSON.parse(card[0].vcard_gradient_color_1),
       vcard_gradient_color_2: JSON.parse(card[0].vcard_gradient_color_2),
       vcard_gradient_angle: card[0].vcard_gradient_angle,
       screen_type: card[0].screen_type,
       background_color_body: JSON.parse(card[0].background_color_body),
       body_gradient_color_1: JSON.parse(card[0].body_gradient_color_1),
       body_gradient_color_2: JSON.parse(card[0].body_gradient_color_2),
       body_gradient_type: card[0].body_gradient_type,
       body_gradient_angle: card[0].body_gradient_angle,
       body_background_image: card[0].body_background_image,
       body_background_image_repeat: card[0].body_background_image_repeat,
       showTestimony: card[0].showTestimony,
       testimonial_title: card[0].testimonial_title,
       testimonial_title_label: card[0].testimonial_title_label,
       testimonial_title_color: JSON.parse(card[0].testimonial_title_color),
       font_size_testimonial_title: card[0].font_size_testimonial_title,
       font_weight_testimonial_title: card[0].font_weight_testimonial_title,
       font_style_testimonial_title: card[0].font_style_testimonial_title,
       font_underline_testimonial_title: card[0].font_underline_testimonial_title,
       testimonial_title_padding_top: card[0].testimonial_title_padding_top,
       testimonial_title_padding_bottom: card[0].testimonial_title_padding_bottom,
       testimonial_title_text_align: card[0].testimonial_title_text_align,
       testimonial: card[0].testimonial.split(','),
       testimonial_padding_top: card[0].testimonial_padding_top,
       testimonial_padding_bottom: card[0].testimonial_padding_bottom,
       custom_image_show: card[0].custom_image_show,
       custom_image: card[0].custom_image,
       custom_image_title: card[0].custom_image_title,
       custom_image_title_label: card[0].custom_image_title_label,
       custom_image_title_color: JSON.parse(card[0].custom_image_title_color),
       font_size_custom_image_title: card[0].font_size_custom_image_title,
       font_weight_custom_image_title: card[0].font_weight_custom_image_title,
       font_style_custom_image_title: card[0].font_style_custom_image_title,
       font_underline_custom_image_title: card[0].font_underline_custom_image_title,
       custom_image_title_padding_top: card[0].custom_image_title_padding_top,
       custom_image_title_padding_bottom: card[0].custom_image_title_padding_bottom,
       custom_image_title_text_align: card[0].custom_image_title_text_align,
       custom_image_border_width: card[0].custom_image_border_width,
       custom_image_border_color: JSON.parse(card[0].custom_image_border_color),
       custom_image_padding_top: card[0].custom_image_padding_top,
       custom_image_padding_bottom: card[0].custom_image_padding_bottom,
       second_custom_image_show: card[0].second_custom_image_show,
       second_custom_image: card[0].second_custom_image,
       second_custom_image_title: card[0].second_custom_image_title,
       second_custom_image_title_label: card[0].second_custom_image_title_label,
       second_custom_image_title_color: JSON.parse(card[0].second_custom_image_title_color),
       second_font_size_custom_image_title: card[0].second_font_size_custom_image_title,
       second_font_weight_custom_image_title: card[0].second_font_weight_custom_image_title,
       second_font_style_custom_image_title: card[0].second_font_style_custom_image_title,
       second_font_underline_custom_image_title: card[0].second_font_underline_custom_image_title,
       second_custom_image_title_padding_top: card[0].second_custom_image_title_padding_top,
       second_custom_image_title_padding_bottom: card[0].second_custom_image_title_padding_bottom,
       second_custom_image_title_text_align: card[0].second_custom_image_title_text_align,
       second_custom_image_border_width: card[0].second_custom_image_border_width,
       second_custom_image_border_color: JSON.parse(card[0].second_custom_image_border_color),
       second_custom_image_padding_top: card[0].second_custom_image_padding_top,
       second_custom_image_padding_bottom: card[0].second_custom_image_padding_bottom,
       image_grid_show: card[0].image_grid_show,
       image_grid: card[0].image_grid.split(','),
       image_grid_column: card[0].image_grid_column,
       image_grid_title: card[0].image_grid_title,
       image_grid_title_label: card[0].image_grid_title_label,
       image_grid_title_color: JSON.parse(card[0].image_grid_title_color),
       font_size_image_grid_title: card[0].font_size_image_grid_title,
       font_weight_image_grid_title: card[0].font_weight_image_grid_title,
       font_style_image_grid_title: card[0].font_style_image_grid_title,
       font_underline_image_grid_title: card[0].font_underline_image_grid_title,
       image_grid_title_padding_top: card[0].image_grid_title_padding_top,
       image_grid_title_padding_bottom: card[0].image_grid_title_padding_bottom,
       image_grid_title_text_align: card[0].image_grid_title_text_align,
       image_grid_padding_top: card[0].image_grid_padding_top,
       image_grid_padding_bottom: card[0].image_grid_padding_bottom,
       add_block_image_text: card[0].add_block_image_text,
       block_image_text_title: card[0].block_image_text_title,
       block_image_text_title_label: card[0].block_image_text_title_label,
       block_image_text_title_color: JSON.parse(card[0].block_image_text_title_color),
       font_size_block_image_text_title: card[0].font_size_block_image_text_title,
       font_weight_block_image_text_title: card[0].font_weight_block_image_text_title,
       font_style_block_image_text_title: card[0].font_style_block_image_text_title,
       font_underline_block_image_text_title: card[0].font_underline_block_image_text_title,
       block_image_text_title_text_align: card[0].block_image_text_title_text_align,
       block_image_text_title_padding_top: card[0].block_image_text_title_padding_top,
       block_image_text_title_padding_bottom: card[0].block_image_text_title_padding_bottom,
       block_image_text_title_padding_left: card[0].block_image_text_title_padding_left,
       block_image_text_title_padding_right: card[0].block_image_text_title_padding_right,
       block_image_text_paragraph: card[0].block_image_text_paragraph,
       block_image_text_img: card[0].block_image_text_img,
       block_image_text_img_padding_left: card[0].block_image_text_img_padding_left,
       block_image_text_img_padding_right: card[0].block_image_text_img_padding_right,
       block_image_text_img_padding_top: card[0].block_image_text_img_padding_top,
       block_image_text_img_padding_bottom: card[0].block_image_text_img_padding_bottom,
       block_image_text_img_border_width: card[0].block_image_text_img_border_width,
       block_image_text_img_border_color: JSON.parse(card[0].block_image_text_img_border_color),
       block_image_text_font_size: card[0].block_image_text_font_size,
       block_image_text_font_weight: card[0].block_image_text_font_weight,
       block_image_text_line_height: card[0].block_image_text_line_height,
       block_image_text_color: JSON.parse(card[0].block_image_text_color),
       block_image_text_style: card[0].block_image_text_style,
       block_image_text_underline: card[0].block_image_text_underline,
       block_image_text_alignment: card[0].block_image_text_alignment,
       block_image_text_background: JSON.parse(card[0].block_image_text_background),
       block_image_text_padding_top: card[0].block_image_text_padding_top,
       block_image_text_padding_bottom: card[0].block_image_text_padding_bottom,
       block_image_text_paragraph_padding_top: card[0].block_image_text_paragraph_padding_top,
       block_image_text_paragraph_padding_left: card[0].block_image_text_paragraph_padding_left,
       block_image_text_paragraph_padding_right: card[0].block_image_text_paragraph_padding_right,
       block_image_text_paragraph_padding_bottom: card[0].block_image_text_paragraph_padding_bottom,
       two_image_show: card[0].two_image_show,
       two_image_first: card[0].two_image_first,
       two_image_first_link: card[0].two_image_first_link,
       two_image_first_link_show: card[0].two_image_first_link_show,
       two_image_first_shape: card[0].two_image_first_shape,
       two_image_first_border_width: card[0].two_image_first_border_width,
       two_image_first_border_color: JSON.parse(card[0].two_image_first_border_color),
       two_image_last: card[0].two_image_last,
       two_image_last_link: card[0].two_image_last_link,
       two_image_last_link_show: card[0].two_image_last_link_show,
       two_image_last_shape: card[0].two_image_last_shape,
       two_image_last_border_width: card[0].two_image_last_border_width,
       two_image_last_border_color: JSON.parse(card[0].two_image_last_border_color),
       two_image_title: card[0].two_image_title,
       two_image_title_label: card[0].two_image_title_label,
       two_image_title_color: JSON.parse(card[0].two_image_title_color),
       font_size_two_image_title: card[0].font_size_two_image_title,
       font_weight_two_image_title: card[0].font_weight_two_image_title,
       font_style_two_image_title: card[0].font_style_two_image_title,
       font_underline_two_image_title: card[0].font_underline_two_image_title,
       two_image_title_padding_top: card[0].two_image_title_padding_top,
       two_image_title_padding_bottom: card[0].two_image_title_padding_bottom,
       two_image_title_text_align: card[0].two_image_title_text_align,
       two_image_padding_top: card[0].two_image_padding_top,
       two_image_padding_bottom: card[0].two_image_padding_bottom,
       add_table: card[0].add_table,
       table: JSON.parse(modifiedString),
       table_title: card[0].table_title,
       table_title_label: card[0].table_title_label,
       table_title_color: JSON.parse(card[0].table_title_color),
       font_size_table_title: card[0].font_size_table_title,
       font_weight_table_title: card[0].font_weight_table_title,
       font_style_table_title: card[0].font_style_table_title,
       font_underline_table_title: card[0].font_underline_table_title,
       table_title_padding_top: card[0].table_title_padding_top,
       table_title_padding_bottom: card[0].table_title_padding_bottom,
       table_title_text_align: card[0].table_title_text_align,
       table_border_width: card[0].table_border_width,
       table_border_color: JSON.parse(card[0].table_border_color),
       table_cell_padding: card[0].table_cell_padding,
       table_cell_alignment: card[0].table_cell_alignment,
       table_font_size: card[0].table_font_size,
       table_font_weight: card[0].table_font_weight,
       table_font_style: card[0].table_font_style,
       table_font_color: JSON.parse(card[0].table_font_color),
       table_font_text_align: card[0].table_font_text_align,
       table_padding_top: card[0].table_padding_top,
       table_padding_bottom: card[0].table_padding_bottom,
       table_cell_background: JSON.parse(card[0].table_cell_background),
       add_iframe: card[0].add_iframe,
       iframe_title: card[0].iframe_title,
       iframe_title_label: card[0].iframe_title_label,
       iframe_title_color: JSON.parse(card[0].iframe_title_color),
       font_size_iframe_title: card[0].font_size_iframe_title,
       font_weight_iframe_title: card[0].font_weight_iframe_title,
       font_style_iframe_title: card[0].font_style_iframe_title,
       font_underline_iframe_title: card[0].font_underline_iframe_title,
       iframe_title_padding_top: card[0].iframe_title_padding_top,
       iframe_title_padding_bottom: card[0].iframe_title_padding_bottom,
       iframe_title_text_align: card[0].iframe_title_text_align,
       iframe_url: card[0].iframe_url,
       iframe_width: card[0].iframe_width,
       iframe_height: card[0].iframe_height,
       iframe_scrollbar: card[0].iframe_scrollbar,
       iframe_show_border: card[0].iframe_show_border,
       iframe_border_type: card[0].iframe_border_type,
       iframe_border_width: card[0].iframe_border_width,
       iframe_border_color: JSON.parse(card[0].iframe_border_color),
       iframe_padding_top: card[0].iframe_padding_top,
       iframe_padding_bottom: card[0].iframe_padding_bottom,
       second_add_iframe: card[0].second_add_iframe,
       second_iframe_title: card[0].second_iframe_title,
       second_iframe_title_label: card[0].second_iframe_title_label,
       second_iframe_title_color: JSON.parse(card[0].second_iframe_title_color),
       second_font_size_iframe_title: card[0].second_font_size_iframe_title,
       second_font_weight_iframe_title: card[0].second_font_weight_iframe_title,
       second_font_style_iframe_title: card[0].second_font_style_iframe_title,
       second_font_underline_iframe_title: card[0].second_font_underline_iframe_title,
       second_iframe_title_padding_top: card[0].second_iframe_title_padding_top,
       second_iframe_title_padding_bottom: card[0].second_iframe_title_padding_bottom,
       second_iframe_title_text_align: card[0].second_iframe_title_text_align,
       second_iframe_url: card[0].second_iframe_url,
       second_iframe_width: card[0].second_iframe_width,
       second_iframe_height: card[0].second_iframe_height,
       second_iframe_scrollbar: card[0].second_iframe_scrollbar,
       second_iframe_show_border: card[0].second_iframe_show_border,
       second_iframe_border_type: card[0].second_iframe_border_type,
       second_iframe_border_width: card[0].second_iframe_border_width,
       second_iframe_border_color: JSON.parse(card[0].second_iframe_border_color),
       second_iframe_padding_top: card[0].second_iframe_padding_top,
       second_iframe_padding_bottom: card[0].second_iframe_padding_bottom,
       third_add_iframe: card[0].third_add_iframe,
       third_iframe_title: card[0].third_iframe_title,
       third_iframe_title_label: card[0].third_iframe_title_label,
       third_iframe_title_color: JSON.parse(card[0].third_iframe_title_color),
       third_font_size_iframe_title: card[0].third_font_size_iframe_title,
       third_font_weight_iframe_title: card[0].third_font_weight_iframe_title,
       third_font_style_iframe_title: card[0].third_font_style_iframe_title,
       third_font_underline_iframe_title: card[0].third_font_underline_iframe_title,
       third_iframe_title_padding_top: card[0].third_iframe_title_padding_top,
       third_iframe_title_padding_bottom: card[0].third_iframe_title_padding_bottom,
       third_iframe_title_text_align: card[0].third_iframe_title_text_align,
       third_iframe_url: card[0].third_iframe_url,
       third_iframe_width: card[0].third_iframe_width,
       third_iframe_height: card[0].third_iframe_height,
       third_iframe_scrollbar: card[0].third_iframe_scrollbar,
       third_iframe_show_border: card[0].third_iframe_show_border,
       third_iframe_border_type: card[0].third_iframe_border_type,
       third_iframe_border_width: card[0].third_iframe_border_width,
       third_iframe_border_color: JSON.parse(card[0].third_iframe_border_color),
       third_iframe_padding_top: card[0].third_iframe_padding_top,
       third_iframe_padding_bottom: card[0].third_iframe_padding_bottom,
       fourth_add_iframe: card[0].fourth_add_iframe,
       fourth_iframe_title: card[0].fourth_iframe_title,
       fourth_iframe_title_label: card[0].fourth_iframe_title_label,
       fourth_iframe_title_color: JSON.parse(card[0].fourth_iframe_title_color),
       fourth_font_size_iframe_title: card[0].fourth_font_size_iframe_title,
       fourth_font_weight_iframe_title: card[0].fourth_font_weight_iframe_title,
       fourth_font_style_iframe_title: card[0].fourth_font_style_iframe_title,
       fourth_font_underline_iframe_title: card[0].fourth_font_underline_iframe_title,
       fourth_iframe_title_padding_top: card[0].fourth_iframe_title_padding_top,
       fourth_iframe_title_padding_bottom: card[0].fourth_iframe_title_padding_bottom,
       fourth_iframe_title_text_align: card[0].fourth_iframe_title_text_align,
       fourth_iframe_url: card[0].fourth_iframe_url,
       fourth_iframe_width: card[0].fourth_iframe_width,
       fourth_iframe_height: card[0].fourth_iframe_height,
       fourth_iframe_scrollbar: card[0].fourth_iframe_scrollbar,
       fourth_iframe_show_border: card[0].fourth_iframe_show_border,
       fourth_iframe_border_type: card[0].fourth_iframe_border_type,
       fourth_iframe_border_width: card[0].fourth_iframe_border_width,
       fourth_iframe_border_color: JSON.parse(card[0].fourth_iframe_border_color),
       fourth_iframe_padding_top: card[0].fourth_iframe_padding_top,
       fourth_iframe_padding_bottom: card[0].fourth_iframe_padding_bottom,
       appointment: card[0].appointment,
       appointment_title: card[0].appointment_title,
       appointment_title_label: card[0].appointment_title_label,
       appointment_title_color: JSON.parse(card[0].appointment_title_color),
       font_size_appointment_title: card[0].font_size_appointment_title,
       font_weight_appointment_title: card[0].font_weight_appointment_title,
       font_style_appointment_title: card[0].font_style_appointment_title,
       font_underline_appointment_title: card[0].font_underline_appointment_title,
       appointment_title_padding_top: card[0].appointment_title_padding_top,
       appointment_title_padding_bottom: card[0].appointment_title_padding_bottom,
       appointment_title_text_align: card[0].appointment_title_text_align,
       appointment_email_recipient: card[0].appointment_email_recipient,
       appointment_padding_top: card[0].appointment_padding_top,
       appointment_padding_bottom: card[0].appointment_padding_bottom,
       custom_text_show: card[0].custom_text_show,
       custom_text: card[0].custom_text,
       custom_text_title: card[0].custom_text_title,
       custom_text_title_label: card[0].custom_text_title_label,
       custom_text_title_color: JSON.parse(card[0].custom_text_title_color),
       font_size_custom_text_title: card[0].font_size_custom_text_title,
       font_weight_custom_text_title: card[0].font_weight_custom_text_title,
       font_style_custom_text_title: card[0].font_style_custom_text_title,
       font_underline_custom_text_title: card[0].font_underline_custom_text_title,
       custom_text_title_padding_top: card[0].custom_text_title_padding_top,
       custom_text_title_padding_bottom: card[0].custom_text_title_padding_bottom,
       custom_text_title_text_align: card[0].custom_text_title_text_align,
       custom_text_border_width: card[0].custom_text_border_width,
       custom_text_background_color: JSON.parse(card[0].custom_text_background_color),
       custom_text_font_size: card[0].custom_text_font_size,
       custom_text_font_weight: card[0].custom_text_font_weight,
       custom_text_font_style: card[0].custom_text_font_style,
       custom_text_font_color: JSON.parse(card[0].custom_text_font_color),
       custom_text_font_text_align: card[0].custom_text_font_text_align,
       custom_text_padding_top: card[0].custom_text_padding_top,
       custom_text_padding_bottom: card[0].custom_text_padding_bottom,
       second_custom_text_show: card[0].second_custom_text_show,
       second_custom_text: card[0].second_custom_text,
       second_custom_text_title: card[0].second_custom_text_title,
       second_custom_text_title_label: card[0].second_custom_text_title_label,
       second_custom_text_title_color: JSON.parse(card[0].second_custom_text_title_color),
       second_font_size_custom_text_title: card[0].second_font_size_custom_text_title,
       second_font_weight_custom_text_title: card[0].second_font_weight_custom_text_title,
       second_font_style_custom_text_title: card[0].second_font_style_custom_text_title,
       second_font_underline_custom_text_title: card[0].second_font_underline_custom_text_title,
       second_custom_text_title_padding_top: card[0].second_custom_text_title_padding_top,
       second_custom_text_title_padding_bottom: card[0].second_custom_text_title_padding_bottom,
       second_custom_text_title_text_align: card[0].second_custom_text_title_text_align,
       second_custom_text_border_width: card[0].second_custom_text_border_width,
       second_custom_text_background_color: JSON.parse(card[0].second_custom_text_background_color),
       second_custom_text_font_size: card[0].second_custom_text_font_size,
       second_custom_text_font_weight: card[0].second_custom_text_font_weight,
       second_custom_text_font_style: card[0].second_custom_text_font_style,
       second_custom_text_font_color: JSON.parse(card[0].second_custom_text_font_color),
       second_custom_text_font_text_align: card[0].second_custom_text_font_text_align,
       second_custom_text_padding_top: card[0].second_custom_text_padding_top,
       second_custom_text_padding_bottom: card[0].second_custom_text_padding_bottom,
       add_accordion: card[0].add_accordion,
       accordion_title: card[0].accordion_title,
       accordion_title_label: card[0].accordion_title_label,
       accordion_title_color: JSON.parse(card[0].accordion_title_color),
       font_size_accordion_title: card[0].font_size_accordion_title,
       font_weight_accordion_title: card[0].font_weight_accordion_title,
       font_style_accordion_title: card[0].font_style_accordion_title,
       font_underline_accordion_title: card[0].font_underline_accordion_title,
       accordions: JSON.parse(accordionsModified),
       accordion_title_padding_top: card[0].accordion_title_padding_top,
       accordion_title_padding_bottom: card[0].accordion_title_padding_bottom,
       accordion_title_text_align: card[0].accordion_title_text_align,
       accordion_padding_top: card[0].accordion_padding_top,
       accordion_padding_bottom: card[0].accordion_padding_bottom,
       accordion_content_line_height: card[0].accordion_content_line_height,
       map: card[0].map,
       map_title: card[0].map_title,
       map_title_label: card[0].map_title_label,
       map_title_color: JSON.parse(card[0].map_title_color),
       font_size_map_title: card[0].font_size_map_title,
       font_weight_map_title: card[0].font_weight_map_title,
       font_style_map_title: card[0].font_style_map_title,
       font_underline_map_title: card[0].font_underline_map_title,
       map_title_padding_top: card[0].map_title_padding_top,
       map_title_padding_bottom: card[0].map_title_padding_bottom,
       map_title_text_align: card[0].map_title_text_align,
       map_padding_top: card[0].map_padding_top,
       map_padding_bottom: card[0].map_padding_bottom,
       latitude: card[0].latitude,
       longitude: card[0].longitude,
       video: card[0].video,
       video_title: card[0].video_title,
       video_title_label: card[0].video_title_label,
       video_title_color: JSON.parse(card[0].video_title_color),
       font_size_video_title: card[0].font_size_video_title,
       font_weight_video_title: card[0].font_weight_video_title,
       font_style_video_title: card[0].font_style_video_title,
       font_underline_video_title: card[0].font_underline_video_title,
       video_title_padding_top: card[0].video_title_padding_top,
       video_title_padding_bottom: card[0].video_title_padding_bottom,
       video_title_text_align: card[0].video_title_text_align,
       video_padding_top: card[0].video_padding_top,
       video_padding_bottom: card[0].video_padding_bottom,
       videolink: card[0].videolink,
       audio: card[0].audio,
       audio_title: card[0].audio_title,
       audio_title_label: card[0].audio_title_label,
       audio_title_color: JSON.parse(card[0].audio_title_color),
       font_size_audio_title: card[0].font_size_audio_title,
       font_weight_audio_title: card[0].font_weight_audio_title,
       font_style_audio_title: card[0].font_style_audio_title,
       font_underline_audio_title: card[0].font_underline_audio_title,
       audio_title_padding_top: card[0].audio_title_padding_top,
       audio_title_padding_bottom: card[0].audio_title_padding_bottom,
       audio_title_text_align: card[0].audio_title_text_align,
       audiolink: card[0].audiolink,
       audio_padding_top: card[0].audio_padding_top,
       audio_padding_bottom: card[0].audio_padding_bottom,
       font_family: card[0].font_family,
       save_contact_button_background: JSON.parse(card[0].save_contact_button_background),
       save_contact_button_font_color: JSON.parse(card[0].save_contact_button_font_color),
       save_contact_button_font_weight: card[0].save_contact_button_font_weight,
       save_contact_button_font_size: card[0].save_contact_button_font_size,
       save_contact_button_border_radious: card[0].save_contact_button_border_radious,
       save_contact_button_border_color: JSON.parse(card[0].save_contact_button_border_color),
       save_contact_button_border_width: card[0].save_contact_button_border_width,
       save_contact_button_padding_top: card[0].save_contact_button_padding_top,
       save_contact_button_padding_bottom: card[0].save_contact_button_padding_bottom,
       add_custom_button: card[0].add_custom_button,
       custom_button_text: card[0].custom_button_text,
       custom_button_link: card[0].custom_button_link,
       custom_button_width: card[0].custom_button_width,
       custom_button_height: card[0].custom_button_height,
       custom_button_background: JSON.parse(card[0].custom_button_background),
       custom_button_font_color: JSON.parse(card[0].custom_button_font_color),
       custom_button_font_weight: card[0].custom_button_font_weight,
       custom_button_font_size: card[0].custom_button_font_size,
       custom_button_border_width: card[0].custom_button_border_width,
       custom_button_border_color: JSON.parse(card[0].custom_button_border_color),
       custom_button_border_radious: card[0].custom_button_border_radious,
       custom_button_position: card[0].custom_button_position,
       custom_button_animation: card[0].custom_button_animation,
       custom_button_margin_top: card[0].custom_button_margin_top,
       custom_button_margin_bottom: card[0].custom_button_margin_bottom,
       second_add_custom_button: card[0].second_add_custom_button,
       second_custom_button_text: card[0].second_custom_button_text,
       second_custom_button_link: card[0].second_custom_button_link,
       second_custom_button_width: card[0].second_custom_button_width,
       second_custom_button_height: card[0].second_custom_button_height,
       second_custom_button_background: JSON.parse(card[0].second_custom_button_background),
       second_custom_button_font_color: JSON.parse(card[0].second_custom_button_font_color),
       second_custom_button_font_weight: card[0].second_custom_button_font_weight,
       second_custom_button_font_size: card[0].second_custom_button_font_size,
       second_custom_button_border_width: card[0].second_custom_button_border_width,
       second_custom_button_border_color: JSON.parse(card[0].second_custom_button_border_color),
       second_custom_button_border_radious: card[0].second_custom_button_border_radious,
       second_custom_button_position: card[0].second_custom_button_position,
       second_custom_button_animation: card[0].second_custom_button_animation,  
       second_custom_button_margin_top: card[0].second_custom_button_margin_top,
       second_custom_button_margin_bottom: card[0].second_custom_button_margin_bottom,
       add_text_between_line: card[0].add_text_between_line,
       text_between_line_word: card[0].text_between_line_word,
       text_between_line_word_font_size: card[0].text_between_line_word_font_size,
       text_between_line_word_font_weight: card[0].text_between_line_word_font_weight,
       text_between_line_word_color: JSON.parse(card[0].text_between_line_word_color),
       text_between_line_word_format: card[0].text_between_line_word_format,
       text_between_line_size: card[0].text_between_line_size,
       text_between_line_color: JSON.parse(card[0].text_between_line_color), 
       text_between_line_space_top: card[0].text_between_line_space_top,
       text_between_line_space_bottom: card[0].text_between_line_space_bottom,
       add_payment_button: card[0].add_payment_button,
       payment_button_title: card[0].payment_button_title,
       payment_button_title_label: card[0].payment_button_title_label,
       payment_button_title_color: JSON.parse(card[0].payment_button_title_color),
       font_size_payment_button_title: card[0].font_size_payment_button_title,
       font_weight_payment_button_title: card[0].font_weight_payment_button_title,
       font_style_payment_button_title: card[0].font_style_payment_button_title,
       font_underline_payment_button_title: card[0].font_underline_payment_button_title,
       payment_button_title_padding_top: card[0].payment_button_title_padding_top,
       payment_button_title_padding_bottom: card[0].payment_button_title_padding_bottom,
       payment_button_title_text_align: card[0].payment_button_title_text_align,
       payment_button_text: card[0].payment_button_text,
       payment_button_link: card[0].payment_button_link,
       payment_button_background: JSON.parse(card[0].payment_button_background),
       payment_button_font_color: JSON.parse(card[0].payment_button_font_color),
       payment_button_height: card[0].payment_button_height,
       payment_button_text_align: card[0].payment_button_text_align,
       payment_button_font_weight: card[0].payment_button_font_weight,
       payment_button_font_size: card[0].payment_button_font_size,
       payment_button_border_width: card[0].payment_button_border_width,
       payment_button_border_color: JSON.parse(card[0].payment_button_border_color),
       payment_button_border_radious: card[0].payment_button_border_radious,
       products: JSON.parse(card[0].products),
       paypal_live_mode: card[0].paypal_live_mode,
       paypal_business_account_sandbox: card[0].paypal_business_account_sandbox,
       paypal_business_account_live: card[0].paypal_business_account_live,
       payment_button_margin_top: card[0].payment_button_margin_top,
       payment_button_margin_bottom: card[0].payment_button_margin_bottom,
       blocks: JSON.parse(card[0].blocks),
    });
   }
}, [cards]); 

// dnd functions for the order of blocks.
function handleOnDragEnd(result) {
    if (!result.destination) return;
    const items = Array.from(data.blocks);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setData({...data, blocks: items});
} 

const [customize, setCustomize] = useState({modal: false, content: ''});

// set body background color
const [body_background, setBodyBackground] = useState('');
useEffect(() => {
  if(data.screen_type === 'color-background') {
      setBodyBackground(`rgba(${data.background_color_body.r},${data.background_color_body.g},${data.background_color_body.b},${data.background_color_body.a})`);
  }
  if(data.screen_type === 'gradient-background'){
    if(data.body_gradient_type === 'linear'){
       //alert('cxz');
        setBodyBackground(`linear-gradient(${data.body_gradient_angle}deg, rgba(${data.body_gradient_color_1.r},${data.body_gradient_color_1.g},${data.body_gradient_color_1.b},${data.body_gradient_color_1.a}) 25%, rgba(${data.body_gradient_color_2.r},${data.body_gradient_color_2.g},${data.body_gradient_color_2.b},${data.body_gradient_color_2.a}) 64%)`);      
    }else{
      setBodyBackground(`radial-gradient(circle, rgba(${data.body_gradient_color_1.r},${data.body_gradient_color_1.g},${data.body_gradient_color_1.b},${data.body_gradient_color_1.a}) 25%, rgba(${data.body_gradient_color_2.r},${data.body_gradient_color_2.g},${data.body_gradient_color_2.b},${data.body_gradient_color_2.a}) 64%)`);
    }
  }
  if(data.screen_type === 'image-background'){
      if(data.body_background_image_repeat === '0'){
         setBodyBackground(`url(https://nfcleads.au/nodeapi/uploads/${data.body_background_image}) no-repeat center center/cover`);
      }else{
         setBodyBackground(`url(https://nfcleads.au/nodeapi/uploads/${data.body_background_image}) repeat`);
      }
  }

}, [data.screen_type,
    data.background_color_body, 
    data.body_gradient_color_1,
    data.body_gradient_color_2, 
    data.body_gradient_angle, 
    data.body_gradient_type,
    data.body_background_image,
    data.body_background_image_repeat
   ]
) 

const [captchaSuccess, setCaptchaSuccess] = useState(false);
const handleCaptchaChange = (status) => {
  setCaptchaSuccess(status);
};

useEffect(() => {
   console.log(captchaSuccess);
},[captchaSuccess])

  return (
    <>
     <StyledDashboard>
       <AdminNav/>
       <Content>
        
   <StyledEditCard>
      <TwoColumn>
        <EditView>
           <Heading>Edit Vcard</Heading>
           <Action>
              <span onClick={() => setPreview(true)}><VisibilityIcon/> Preview Vcard</span>
              <span onClick={saveCard} style={{background: '#77c599'}}><SaveIcon/> {editStatus === "pending" ? "Saving..." : "Save Vcard"}</span>
              <span onClick={downloadCard} style={{background: '#c489ab'}}><DownloadForOfflineIcon/> Download Vcard</span>
           </Action>
           
            <StyledForm>
             <Card>  
               <h4 className="card_setting"><SettingsIcon/> Vcard Settings</h4>
               <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                 <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                 >
                 <Typography sx={{ width: '33%', flexShrink: 0 }}>
                   <label>Fonts</label>
                 </Typography>
                </AccordionSummary>
                <AccordionDetails>
                 <FormField>
                   <select onChange={(e) => setData({...data, font_family: e.target.value})}>
                     <option>Select Font</option>
                     <option value="Roboto">Roboto</option>
                     <option value="Poppins">Poppins</option>
                     <option value="Open Sans">Open Sans</option>
                     <option value="Montserrat">Montserrat</option>
                     <option value="Lato">Lato</option>
                     <option value="Raleway">Raleway</option>
                   </select>
                 </FormField>
                </AccordionDetails>
               </Accordion>

               <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                 <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                 >
                 <Typography sx={{ width: '33%', flexShrink: 0 }}>
                   <label>Vcard Background Colour</label>
                 </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormField>
                    <label>Background Type</label>
                    <select onChange={(e) => setData({...data, vcard_gradient: e.target.value})} value={data.vcard_gradient}>
                     <option value="no">Color Background</option>
                     <option value="yes">Gradient Background</option>   
                    </select>
                  </FormField>
                  { data.vcard_gradient === "no" ?
                    <FormField>
                      <SketchPicker onChange={(color) => { setSketchPickerColor(color.rgb); setData({...data, background_color: color.rgb}); }} color={sketchPickerColor} />
                    </FormField>
                    :
                     <>
                      <div>
                        <FormField>
                         <label>Gradient Primary Colour</label>
                         <SketchPicker onChange={(color) => { setGradientPrimaryColour(color.rgb); setData({...data, vcard_gradient_color_1: color.rgb}); }} color={GradientPrimaryColour} />
                        </FormField>
                        <FormField>
                         <label>Gradient Secondary Colour</label>
                         <SketchPicker onChange={(color) => { setGradientSecondaryColour(color.rgb); setData({...data, vcard_gradient_color_2: color.rgb}); }} color={GradientSecondaryColour} />
                        </FormField>
                      </div>
                      <FormField>
                       <label>Selct Gradient Type</label>
                       <select onChange={(e) => setData({...data, vcard_gradient_type: e.target.value})}>
                        <option value="linear">Linear</option>
                        <option value="radial">Radial</option>   
                       </select>
                      </FormField>
                      { data.vcard_gradient_type === 'linear' ?
                         <FormField>
                          <label>Gradient Direction <small style={{color: '#777'}}>(Range: 0-360)</small></label>
                          <input type="number" max="360" value={data.vcard_gradient_angle} onChange={(e) => setData({...data, vcard_gradient_angle: e.target.value})}/> 
                         </FormField> 
                         :
                       ''
                      }
                     </>
                  }
                </AccordionDetails>
               </Accordion>

               <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                 <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                 >
                 <Typography sx={{ width: '33%', flexShrink: 0 }}>
                   <label>Screen Background Colour 
                     <button type="button" style={{
                        float: 'right',
                        marginRight: '15px',
                        fontSize: '11px',
                        background: '#673AB7',
                        color: '#fff',
                        padding: '5px 9px',
                        border: 'none',
                        borderRadius: '4px',
                        cursor: 'pointer',
                     }} onClick={() => setPreview(true)}>Preview</button>
                   </label>
                 </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormField>
                    <label>Background Type</label>
                    <select onChange={(e) => setData({...data, screen_type: e.target.value})}>
                     <option value="color-background">Color Background</option>
                     <option value="gradient-background">Gradient Background</option> 
                     <option value="image-background">Image Background</option>   
                    </select>
                  </FormField>
                  { data.screen_type === 'color-background' ?
                    <FormField>
                      <SketchPicker onChange={(color) => { console.log(color.rgb);setBodyColor(color.rgb); setData({...data, background_color_body: color.rgb}); }} color={bodyColor} />
                    </FormField>
                    : 
                    ''
                  }
                  { data.screen_type === 'gradient-background' ?
                    <>
                      <div>
                        <FormField>
                         <label>Gradient Primary Colour</label>
                         <SketchPicker onChange={(color) => { setBodyGradientPrimaryColour(color.rgb); setData({...data, body_gradient_color_1: color.rgb}); }} color={BodyGradientPrimaryColour} />
                        </FormField>
                        <FormField>
                         <label>Gradient Secondary Colour</label>
                         <SketchPicker onChange={(color) => { setBodyGradientSecondaryColour(color.rgb); setData({...data,body_gradient_color_2: color.rgb}); }} color={BodyGradientSecondaryColour} />
                        </FormField>
                      </div>
                      <FormField>
                       <label>Selct Gradient Type</label>
                       <select onChange={(e) => setData({...data, body_gradient_type: e.target.value})}>
                        <option value="linear">Linear</option>
                        <option value="radial">Radial</option>   
                       </select>
                      </FormField>
                      { data.body_gradient_type === 'linear' ?
                         <FormField>
                          <label>Gradient Direction <small style={{color: '#777'}}>(Range: 0-360)</small></label>
                          <input type="number" max="360" value={data.body_gradient_angle} onChange={(e) => setData({...data, body_gradient_angle: e.target.value})}/> 
                         </FormField> 
                         :
                       ''
                      }
                     </>
                    : 
                    ''
                  }
                  
                  { data.screen_type === 'image-background' ?
                  <>
                   <form enctype="multipart/form-data">
                    <FormField>
                     <label><ImageIcon style={{fontSize: '17px'}}/>Body Background Image</label>
                     <input type='file' name='file' id="files" onChange={handleBodyBackgroundImage}></input>
                     { body_background_image_upload === true ?
                       <p style={{fontSize: '13px'}}>Uploading...</p>
                       : ''
                     }
                     </FormField>
                    </form>
               
                   { data.body_background_image !== '' ?
                    <div id="profile_pic_view">
                      <img src={'https://nfcleads.au/nodeapi/uploads/' + data.body_background_image} />
                      <button className="testimonial-btn red-btn" type="button" onClick={removeBodyBackgroundImage}>Remove</button>
                    </div>
                    : ''
                    }
                    { data.body_background_image !== '' ?
                      <FormField>
                       <label>Do you want repeated image background?</label>
                       <select value={data.body_background_image_repeat} onChange={(e) => setData({...data, body_background_image_repeat: e.target.value})}>
                        <option value="0">No</option>
                        <option value="1">Yes</option>   
                       </select>
                      </FormField>
                    : ''
                    }
                    </>
                    : 
                    ''
                  }
                </AccordionDetails>
               </Accordion>

               <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                 <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel9bh-content"
                    id="panel1bh-header"
                 >
                 <Typography sx={{ width: '33%', flexShrink: 0 }}>
                   <label>Save Contact Button</label>
                 </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormField>
                    <label>Button Colour</label>
                    <SketchPicker onChange={(color) => { setSaveContactButtonBackground(color.rgb); setData({...data, save_contact_button_background: color.rgb}); }} color={save_contact_button_background} />
                  </FormField>
                  <br/>
                  <FormField>
                    <label>Button Font Colour</label>
                    <SketchPicker onChange={(color) => { setSaveContactButtonFontColor(color.rgb); setData({...data, save_contact_button_font_color: color.rgb}); }} color={save_contact_button_font_color} />
                  </FormField>
                  <br/>
                  <FormField>
                    <label>Button Font Size</label>
                    <input type="number" value={data.save_contact_button_font_size} onChange={(e) => setData({...data, save_contact_button_font_size: e.target.value})} />
                  </FormField>
                  <FormField>
                    <label>Button Font Weight</label>
                    <input type="number" value={data.save_contact_button_font_weight} onChange={(e) => setData({...data, save_contact_button_font_weight: e.target.value})} />
                  </FormField>
                  <FormField>
                    <label>Border Thickness</label>
                    <input type="number" value={data.save_contact_button_border_width} onChange={(e) => setData({...data, save_contact_button_border_width: e.target.value})} />
                  </FormField>
                  <FormField>
                    <label>Button Border Radius</label>
                    <input type="number" value={data.save_contact_button_border_radious} onChange={(e) => setData({...data, save_contact_button_border_radious: e.target.value})} />
                  </FormField>
                  <FormField>
                    <label>Border Colour</label>
                    <SketchPicker onChange={(color) => { setSaveContactButtonBorderColor(color.rgb); setData({...data, save_contact_button_border_color: color.rgb}); }} color={save_contact_button_border_color} />
                  </FormField>
                  <FormField>
                    <label>Space Top</label>
                    <input type="number" value={data.save_contact_button_padding_top} onChange={(e) => setData({...data, save_contact_button_padding_top: e.target.value})} />
                  </FormField>
                  <FormField>
                    <label>Space Bottom</label>
                    <input type="number" value={data.save_contact_button_padding_bottom} onChange={(e) => setData({...data, save_contact_button_padding_bottom: e.target.value})} />
                  </FormField>
                </AccordionDetails>
               </Accordion>

               <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                 <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                 >
                 <Typography sx={{ width: '33%', flexShrink: 0 }}>
                   <label>Order of Blocks</label>
                 </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                   <Droppable droppableId="blocks">
                    {(provided) => (
                      <ul className="blocks" {...provided.droppableProps} ref={provided.innerRef}>
                       {data.blocks.map(({id, name}, index) => {
                        return (
                          <Draggable key={id} draggableId={id} index={index}>
                           {(provided) => (
                               <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                 <p>{name} <FormatLineSpacingIcon/></p>
                               </li>
                           )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </ul>
                   )}
                 </Droppable>
                </DragDropContext>
                </AccordionDetails>
               </Accordion>
              </Card>
               
               <br></br><br></br>

             <Card>
             <h4 className="card_setting">Basic Details</h4><br></br>
             { data.company_logo_show === 0 ? 
                <>
               <FormField>
                 <label><ImageIcon style={{fontSize: '17px'}}/> Header Photo</label>
                 <input type='file' name='header_img' id="header_img" onChange={handleHeaderChange}></input>
                 { header_upload === true ?
                   <p style={{fontSize: '13px'}}>Uploading...</p>
                   : ''
                 }
               </FormField>
               { data.header_img !== '' ?
                 <div id="profile_pic_view">
                   <img id="header_img_view" src={'https://nfcleads.au/nodeapi/uploads/' + data.header_img} />
                   <button className="testimonial-btn red-btn" type="button" onClick={removeHeaderImage}>Remove</button>
                 </div>
                 : ''
               }

               <FormField>
                 <label><ImageIcon style={{fontSize: '17px'}}/> Profile Photo</label>
                 <input type='file' name='file' id="files" onChange={handleFileChange}></input>
                 { profile_upload === true ?
                   <p style={{fontSize: '13px'}}>Uploading...</p>
                   : ''
                 }
               </FormField>
               { data.profile_pic !== '' ?
                 <div id="profile_pic_view">
                   <img src={'https://nfcleads.au/nodeapi/uploads/' + data.profile_pic} />
                   <button className="testimonial-btn red-btn" type="button" onClick={removeProfilePicture}>Remove</button>
                 </div>
                 : ''
               }

               <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                 <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                  <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    <label><SettingsIcon/> Customize Photo Picture</label>
                  </Typography>
                 </AccordionSummary>
                 <AccordionDetails>
               <FormField>
                 <label>Profile Photo Shape</label>
                 <select onChange={(e) => setData({...data, profile_pic_shape: e.target.value})} value={data.profile_pic_shape}>
                   <option value="50%">Rounded</option>
                   <option value="0">Square</option>  
                 </select>
               </FormField>
               <FormField>
                 <label>Profile Photo Vertical Position</label>
                 <input type="number" value={data.profile_pic_vertical_pos} onInput={(e) => setData({...data, profile_pic_vertical_pos: e.target.value})} />
               </FormField>
               <FormField>
                 <label>Profile Photo Horizontal Position</label>
                 <select value={data.profile_pic_horizontal_pos} onChange={(e) => setData({...data, profile_pic_horizontal_pos: e.target.value})}>
                   <option value="center">Center</option>
                   <option value="left">Left</option>
                   <option value="right">Right</option>
                 </select>
               </FormField>
               <FormField>
                 <label>Profile Photo Border Line Thickness</label>
                 <input type="number" value={data.profile_pic_border_width} onInput={(e) => setData({...data, profile_pic_border_width: e.target.value})} />
               </FormField>
               <FormField>
                 <label>Profile Photo Border Line Colour</label><br></br><br></br>
                 <SketchPicker onChange={(color) => { console.log(color.rgb);setProfilePictureFrameColor(color.rgb); setData({...data, profile_pic_border_color: color.rgb}); }} color={bodyColor} />
               </FormField>
                 </AccordionDetails>
              </Accordion>
               <br></br> 
               </> 
               : ''
              }

              { data.company_logo_show === 1 ? 
               <>
                <form enctype="multipart/form-data">
                 <FormField>
                  <label><ImageIcon style={{fontSize: '17px'}}/> Company Logo</label>
                  <input type='file' name='file' id="files" onChange={handleCompanyLogoChange}></input>
                  { company_logo_upload === true ?
                   <p style={{fontSize: '13px'}}>Uploading...</p>
                   : ''
                  }
                 </FormField>
                </form>
               
               { data.company_logo !== '' ?
                 <div id="profile_pic_view">
                   <img src={'https://nfcleads.au/nodeapi/uploads/' + data.company_logo} />
                   <button className="testimonial-btn red-btn" type="button" onClick={removeCompanyLogo}>Remove</button>
                 </div>
                 : ''
               }
          
               <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                 <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel5bh-content"
                    id="panel1bh-header"
                  >
                  <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    <label><SettingsIcon/> Customize Company Logo</label>
                  </Typography>
                 </AccordionSummary>
                 <AccordionDetails>
               <FormField>
                 <label>Company Logo Shape</label>
                 <select value={data.company_logo_shape} onChange={(e) => setData({...data, company_logo_shape: e.target.value})}>
                   <option value="50%">Rounded</option>
                   <option value="0">Square</option>  
                 </select>
               </FormField>
               <FormField>
                 <label>Company Logo Vertical Position</label>
                 <input type="number" value={data.company_logo_vertical_pos} onInput={(e) => setData({...data, company_logo_vertical_pos: e.target.value})} />
               </FormField>
               <FormField>
                 <label>Company Logo Horizontal Position</label>
                 <select value={data.company_logo_horizontal_pos} onChange={(e) => setData({...data, company_logo_horizontal_pos: e.target.value})}>
                   <option value="center">Center</option>
                   <option value="left">Left</option>
                   <option value="right">Right</option>
                 </select>
               </FormField>
               <FormField>
                 <label>Company Logo Border Line Thickness</label>
                 <input type="number" value={data.company_logo_border_width} onInput={(e) => setData({...data, company_logo_border_width: e.target.value})} />
               </FormField>
               <FormField>
                 <label>Company Logo Border Line Colour</label><br></br><br></br>
                 <SketchPicker onChange={(color) => { setCompanyLogoBorderColor(color.rgb); setData({...data, company_logo_border_color: color.rgb}); }} color={company_logo_border_color} />
               </FormField>
                 </AccordionDetails>
                </Accordion>
                 <br></br>
               </>
              : ''
             }

               <FormField>
                 <Switch>
                   { data.company_logo_show === 1 ? 
                     <input type="checkbox" checked onChange={(e) => setData({...data, company_logo_show: 0})}/>
                     : 
                     <input type="checkbox" onChange={(e) => setData({...data, company_logo_show: 1})}/>
                   }
                   <label>Add Company Logo instead of Profile Photo & Header</label>
                 </Switch>
               </FormField>  
               <br></br>

               <FormField>
                 <label><Person2Icon style={{fontSize: '18px', verticalAlign: 'text-top'}}/> Name <span className="customize" onClick={() => setCustomize({...customize, modal: true, content: 'name'})} title="Styles"><SettingsIcon/></span></label>
                 <div style={{display: 'flex', gap: '10px'}}>
                   <div>
                    <input type="text" value={data.firstname} onChange={(e) => setData({...data, firstname: e.target.value})} required placeholder="First Name"/>
                   </div>
                   <div>
                    <input type="text" value={data.lastname} onChange={(e) => setData({...data, lastname: e.target.value})} required placeholder="Last Name"/>
                   </div>
                 </div>
               </FormField>
               <FormField>
                 <label><LocationOnIcon/> Address <span className="customize" onClick={() => setCustomize({...customize, modal: true, content: 'address'})} title="Styles"><SettingsIcon/></span></label>
                 <input type="text" value={data.address} onChange={(e) => setData({...data, address: e.target.value})} required placeholder="Place you live"/>
               </FormField>
              </Card>
              <br></br><br></br>

              <Card>  
               <h4 className="card_setting"><SettingsIcon/> Professional Details</h4><br></br>
               <FormField>
                 <label><WorkOutlineIcon/> Profession <span className="customize" onClick={() => setCustomize({...customize, modal: true, content: 'profession'})} title="Styles"><SettingsIcon/></span></label>
                 <input type="text" value={data.jobtitle} onChange={(e) => setData({...data, jobtitle: e.target.value})} required placeholder="Role or position"/>
               </FormField>

               <FormField>
                 <label><MapsHomeWorkIcon/> Company <span className="customize" onClick={() => setCustomize({...customize, modal: true, content: 'company'})} title="Styles"><SettingsIcon/></span></label>
                 <input type="text" value={data.company} onChange={(e) => setData({...data, company: e.target.value})} required placeholder="Company you work for"/>
               </FormField>
               
               <FormField>
                 <label><BorderColorIcon/> Profile Description<span className="customize" onClick={() => setCustomize({...customize, modal: true, content: 'description'})} title="Styles"><SettingsIcon/></span></label>
                 <textarea value={data.description} onChange={(e) => setData({...data, description: e.target.value})} required placeholder="Describe briefly about yourself"/>
               </FormField>
              </Card> 

              <br></br><br></br>
              <Card>  
              <h4 className="card_setting">Contact Details <span className="customize" onClick={() => setCustomize({...customize, modal: true, content: 'contact'})} title="Styles"><SettingsIcon/></span></h4><br></br>
               <FormField>
                 <label><LocalPhoneIcon/> Mobile</label>
                 <input type="number" value={data.number_mobile} onChange={(e) => setData({...data, number_mobile: e.target.value})} required placeholder="Phone number"/>
               </FormField>

               <FormField>
                 <label><ChatIcon/> SMS</label>
                 <input type="number" value={data.number_sms} onChange={(e) => setData({...data, number_sms: e.target.value})} required placeholder="SMS number"/>
               </FormField>

               <FormField>
                 <label><WhatsAppIcon/> WhatsApp</label>
                 <input type="number" value={data.number_whatsapp} onChange={(e) => setData({...data, number_whatsapp: e.target.value})} required placeholder="WhatsApp number"/>
               </FormField>

               <FormField>
                 <label><EmailIcon/> Email</label>
                 <input type="email" value={data.email} onChange={(e) => setData({...data, email: e.target.value})} required placeholder="Email Address"/>
               </FormField>

               <FormField>
                 <label><LanguageIcon/> Website</label>
                 <input type="text" value={data.website} onChange={(e) => setData({...data, website: e.target.value})} required placeholder="Example: https://www.example.com"/>
               </FormField>

               <FormField>
                 <label><LocationOnIcon/> Google Map Location</label>
                 <input type="text" value={data.location} onChange={(e) => setData({...data, location: e.target.value})} required placeholder="Example: https://goo.gl/maps/NSzjjgvWRKAJPxpw9"/>
               </FormField>

               <FormField>
                 <label><FacebookIcon/> Facebook</label>
                 <input type="text" value={data.facebook} onChange={(e) => setData({...data, facebook: e.target.value})} required placeholder="Enter facebook profile url"/>
               </FormField>

               <FormField>
                 <label><InstagramIcon/> Instagram</label>
                 <input type="text" value={data.instagram} onChange={(e) => setData({...data, instagram: e.target.value})} required placeholder="Enter instagram profile url"/>
               </FormField>

               <FormField>
                 <label><LinkedInIcon/> Linkedin</label>
                 <input type="text" value={data.linkedin} onChange={(e) => setData({...data, linkedin: e.target.value})} required placeholder="Enter linkedin profile url"/>
               </FormField>

               <FormField>
                 <label><img src={skype} style={{width: '15px', verticalAlign: 'text-top'}}/> Skype</label>
                 <input type="text" value={data.skype} onChange={(e) => setData({...data, skype: e.target.value})} required placeholder="Enter "/>
               </FormField>

               <FormField>
                 <label><img src={messenger} style={{width: '15px', verticalAlign: 'text-top'}}/> Messenger</label>
                 <input type="text" value={data.messenger} onChange={(e) => setData({...data, messenger: e.target.value})} required placeholder="Enter "/>
               </FormField>

               <FormField>
                 <label><img src={pinterest} style={{width: '15px', verticalAlign: 'text-top'}}/> Pinterest</label>
                 <input type="text" value={data.pinterest} onChange={(e) => setData({...data, pinterest: e.target.value})} required placeholder="Enter "/>
               </FormField>

               <FormField>
                 <label><img src={twitter} style={{width: '15px', verticalAlign: 'text-top'}}/> Twitter</label>
                 <input type="text" value={data.twitter} onChange={(e) => setData({...data, twitter: e.target.value})} required placeholder="Enter "/>
               </FormField>

               <FormField>
                 <label><img src={telegram} style={{width: '15px', verticalAlign: 'text-top'}}/> Telegram</label>
                 <input type="text" value={data.telegram} onChange={(e) => setData({...data, telegram: e.target.value})} required placeholder="Enter "/>
               </FormField>

               <FormField>
                 <label><img src={snapchat} style={{width: '15px', verticalAlign: 'text-top'}}/> SnapChat</label>
                 <input type="text" value={data.snapchat} onChange={(e) => setData({...data, snapchat: e.target.value})} required placeholder="Enter "/>
               </FormField>

               <FormField>
                 <label><img src={viber} style={{width: '15px', verticalAlign: 'text-top'}}/> Viber</label>
                 <input type="text" value={data.viber} onChange={(e) => setData({...data, viber: e.target.value})} required placeholder="Enter "/>
               </FormField>

               <FormField>
                 <label><img src={tumblr} style={{width: '15px', verticalAlign: 'text-top'}}/> Tumblr</label>
                 <input type="text" value={data.tumblr} onChange={(e) => setData({...data, tumblr: e.target.value})} required placeholder="Enter "/>
               </FormField>

               <FormField>
                 <label><img src={wechat} style={{width: '15px', verticalAlign: 'text-top'}}/> WeChat</label>
                 <input type="text" value={data.wechat} onChange={(e) => setData({...data, wechat: e.target.value})} required placeholder="Enter "/>
               </FormField>

               <FormField>
                 <label><img src={youtube} style={{width: '15px', verticalAlign: 'text-top'}}/> YouTube</label>
                 <input type="text" value={data.youtube} onChange={(e) => setData({...data, youtube: e.target.value})} required placeholder="Enter "/>
               </FormField>

               <FormField>
                 <label><img src={reddit} style={{width: '15px', verticalAlign: 'text-top'}}/> Reddit</label>
                 <input type="text" value={data.reddit} onChange={(e) => setData({...data, reddit: e.target.value})} required placeholder="Enter "/>
               </FormField>

               <FormField>
                 <label><img src={tiktok} style={{width: '15px', verticalAlign: 'text-top'}}/> Tiktok</label>
                 <input type="text" value={data.tiktok} onChange={(e) => setData({...data, tiktok: e.target.value})} required placeholder="Enter "/>
               </FormField>
              </Card>  

              <br></br><br></br>
              <Card>  
               <h4 className="card_setting"><SettingsIcon/> Add Blocks</h4><br></br>
                              <FormField>
                 <Switch>
                   { data.audio === 1 ? 
                     <input type="checkbox" checked onChange={(e) => setData({...data, audio: 0})}/>
                     : 
                     <input type="checkbox" onChange={(e) => setData({...data, audio: 1})}/>
                   }
                   <label>Insert Audio</label>
                 </Switch>
               </FormField>               

               { data.audio === 1 ?
                 <FormField>
                   <SwitchSmall>
                   { data.audio_title === 1 ? 
                     <input type="checkbox" checked onChange={(e) => setData({...data, audio_title: 0})}/>
                     : 
                     <input type="checkbox" onChange={(e) => setData({...data, audio_title: 1})}/>
                   }
                     <label>Show Audio Title</label>
                   </SwitchSmall>
                   { data.audio_title === 1 ? 
                    <div className="header_title">
                     <input type="text" value={data.audio_title_label} onChange={(e) => setData({...data, audio_title_label: e.target.value})} required placeholder="Enter the audio title"/>
                     <span className="customize" onClick={() => setCustomize({...customize, modal: true, content: 'audio-title'})} title="Styles"><SettingsIcon/></span>
                    </div>
                     : ''
                   }
                   <label>Audio Link</label>
                   <input type="text" value={data.audiolink} onChange={(e) => setData({...data, audiolink: e.target.value})} required placeholder="Enter the audio link"/>
                   <label>Space Top</label>
                   <input type="number" value={data.audio_padding_top} onChange={(e) => setData({...data, audio_padding_top: e.target.value})} />
                   <label>Space Bottom</label>
                   <input type="number" value={data.audio_padding_bottom} onChange={(e) => setData({...data, audio_padding_bottom: e.target.value})}/>
                 </FormField>
                 : ''
               }
               <FormField>
                 <Switch>
                   { data.video === 1 ? 
                     <input type="checkbox" checked onChange={(e) => setData({...data, video: 0})}/>
                     : 
                     <input type="checkbox" onChange={(e) => setData({...data, video: 1})}/>
                   }
                   <label>Insert Video</label>
                 </Switch>
               </FormField>               

               { data.video === 1 ?
                 <FormField>
                   <SwitchSmall>
                   { data.video_title === 1 ? 
                     <input type="checkbox" checked onChange={(e) => setData({...data, video_title: 0})}/>
                     : 
                     <input type="checkbox" onChange={(e) => setData({...data, video_title: 1})}/>
                   }
                     <label>Show Video Title</label>
                   </SwitchSmall>
                   { data.video_title === 1 ? 
                    <div className="header_title">
                     <input type="text" value={data.video_title_label} onChange={(e) => setData({...data, video_title_label: e.target.value})} required placeholder="Enter the video title"/>
                     <span className="customize" onClick={() => setCustomize({...customize, modal: true, content: 'video-title'})} title="Styles"><SettingsIcon/></span>
                    </div>
                     : ''
                   }
                   <label>Video Link</label>
                   <input type="text" value={data.videolink} onChange={(e) => setData({...data, videolink: e.target.value})} required placeholder="Enter the video link"/>
                   <label>Space Top</label>
                   <input type="number" value={data.video_padding_top} onChange={(e) => setData({...data, video_padding_top: e.target.value})} />
                   <label>Space Bottom</label>
                   <input type="number" value={data.video_padding_bottom} onChange={(e) => setData({...data, video_padding_bottom: e.target.value})}/>
                 </FormField>
                 : ''
               }
               
               <FormField>
                 <Switch>
                   { data.showTestimony === 1 ? 
                     <input type="checkbox" checked onChange={(e) => setData({...data, showTestimony: 0})}/>
                     : 
                     <input type="checkbox" onChange={(e) => setData({...data, showTestimony: 1})}/>
                   }
                   <label>Insert Testimonials</label>
                 </Switch>
               </FormField>
               
               { data.showTestimony === 1 ?
                <>
                 <SwitchSmall>
                   { data.testimonial_title === 1 ? 
                     <input type="checkbox" checked onChange={(e) => setData({...data, testimonial_title: 0})}/>
                     : 
                     <input type="checkbox" onChange={(e) => setData({...data, testimonial_title: 1})}/>
                   }
                     <label>Show Testimonials Title</label>
                   </SwitchSmall>
                   { data.testimonial_title === 1 ? 
                     <div className="header_title">
                       <input type="text" value={data.testimonial_title_label} onChange={(e) => setData({...data, testimonial_title_label: e.target.value})} required placeholder="Enter testimonial title"/>
                       <span className="customize" onClick={() => setCustomize({...customize, modal: true, content: 'testimonial-title'})} title="Styles"><SettingsIcon/></span>
                     </div>
                     : ''
                   }
                 <label>Upload Image(s) as Testimonial(s)</label><br></br>
                 <span style={{fontSize: '12px', fontStyle: 'italic', margin: '8px 0', display: 'inline-block'}}>Recomended dimension for images - 400xauto</span>
                 <input
                   id="galleryUpload"
                   accept="image/*"
                   type="file"
                   multiple
                   onChange={handleTestimonialUpload}
                 />

                { testimonial_upload === 1 ? <span style={{fontSize: '14px'}}>Uploading...</span> : '' }
                { testimonial_upload === 2 ? <span style={{fontSize: '14px'}}><CheckCircleIcon style={{verticalAlign: 'sub', fontSize: '18px', color: '#4caf50'}}/> Uploaded</span> : '' }

           { data.testimonial && (
             <Gallery>
               { data.testimonial.map((img, i) => {
                 return (
                  <div className="gallery-item">
                    <img className="preview" src={'https://nfcleads.au/nodeapi/uploads/' + img} />
                    <button className="testimonial-btn red-btn" type="button" id={img} onClick={removeFromTestimony}>Remove</button>
                  </div>
                 );
               })}
              </Gallery>
            )}
               </>
                : ''
               }
               { data.showTestimony === 1 ?
                 <>
                 <label>Space Top</label>
                 <input type="number" value={data.testimonial_padding_top} onChange={(e) => setData({...data, testimonial_padding_top: e.target.value})}/>
                 <label>Space Bottom</label>
                 <input type="number" value={data.testimonial_padding_bottom} onChange={(e) => setData({...data, testimonial_padding_bottom: e.target.value})}/>
                <br></br>
                 </>
                 : '' 
               }
                <br></br>

                <FormField>
                 <Switch>
                   { data.custom_text_show === 1 ? 
                     <input type="checkbox" checked onChange={(e) => setData({...data, custom_text_show: 0})}/>
                     : 
                     <input type="checkbox" onChange={(e) => setData({...data, custom_text_show: 1})}/>
                   }
                   <label>Insert Custom Text (One)</label>
                 </Switch>
               </FormField>

               { data.custom_text_show === 1 ?
                 <FormField>
                   <SwitchSmall>
                   { data.custom_text_title === 1 ? 
                     <input type="checkbox" checked onChange={(e) => setData({...data, custom_text_title: 0})}/>
                     : 
                     <input type="checkbox" onChange={(e) => setData({...data, custom_text_title: 1})}/>
                   }
                     <label>Show Custom Text Title</label>
                   </SwitchSmall>
                   { data.custom_text_title === 1 ? 
                    <div className="header_title">
                     <input type="text" value={data.custom_text_title_label} onChange={(e) => setData({...data, custom_text_title_label: e.target.value})} required placeholder="Enter custom text title"/>
                     <span className="customize" onClick={() => setCustomize({...customize, modal: true, content: 'custom-text-title'})} title="Styles"><SettingsIcon/></span>
                    </div>
                     : ''
                   }
                   <FormField>
                    <label>Custom Text <span className="customize-custom-text" onClick={() => setCustomize({...customize, modal: true, content: 'custom-text'})} title="Styles"><SettingsIcon/></span></label>
                    <textarea value={data.custom_text} onChange={(e) => setData({...data, custom_text: e.target.value})} required placeholder="Custom Text"/>
                   </FormField>
                 </FormField>
                 : ''
               }

               <FormField>
                 <Switch>
                   { data.second_custom_text_show === 1 ? 
                     <input type="checkbox" checked onChange={(e) => setData({...data, second_custom_text_show: 0})}/>
                     : 
                     <input type="checkbox" onChange={(e) => setData({...data, second_custom_text_show: 1})}/>
                   }
                   <label>Insert Custom Text (Two)</label>
                 </Switch>
               </FormField>

               { data.second_custom_text_show === 1 ?
                 <FormField>
                   <SwitchSmall>
                   { data.second_custom_text_title === 1 ? 
                     <input type="checkbox" checked onChange={(e) => setData({...data, second_custom_text_title: 0})}/>
                     : 
                     <input type="checkbox" onChange={(e) => setData({...data, second_custom_text_title: 1})}/>
                   }
                     <label>Show Custom Text Title</label>
                   </SwitchSmall>
                   { data.second_custom_text_title === 1 ? 
                    <div className="header_title">
                     <input type="text" value={data.second_custom_text_title_label} onChange={(e) => setData({...data, second_custom_text_title_label: e.target.value})} required placeholder="Enter custom text title"/>
                     <span className="customize" onClick={() => setCustomize({...customize, modal: true, content: 'custom-text-title-second'})} title="Styles"><SettingsIcon/></span>
                    </div>
                     : ''
                   }
                   <FormField>
                    <label>Custom Text <span className="customize-custom-text" onClick={() => setCustomize({...customize, modal: true, content: 'custom-text-second'})} title="Styles"><SettingsIcon/></span></label>
                    <textarea onChange={(e) => setData({...data, second_custom_text: e.target.value})} required placeholder="Custom Text"/>
                   </FormField>
                 </FormField>
                 : ''
               }

               <FormField>
                 <Switch>
                   { data.custom_image_show === 1 ? 
                     <input type="checkbox" checked onChange={(e) => setData({...data, custom_image_show: 0})}/>
                     : 
                     <input type="checkbox" onChange={(e) => setData({...data, custom_image_show: 1})}/>
                   }
                   <label>Insert Custom Image (One)</label>
                 </Switch>
               </FormField>

               { data.custom_image_show === 1 ?
                 <FormField>
                   <SwitchSmall>
                   { data.custom_image_title === 1 ? 
                     <input type="checkbox" checked onChange={(e) => setData({...data, custom_image_title: 0})}/>
                     : 
                     <input type="checkbox" onChange={(e) => setData({...data, custom_image_title: 1})}/>
                   }
                     <label>Show Custom Image Title</label>
                   </SwitchSmall>
                   { data.custom_image_title === 1 ? 
                      <div className="header_title">
                       <input type="text" value={data.custom_image_title_label} onChange={(e) => setData({...data, custom_image_title_label: e.target.value})} required placeholder="Enter custom image title"/>
                       <span className="customize" onClick={() => setCustomize({...customize, modal: true, content: 'custom-image-title'})} title="Styles"><SettingsIcon/></span>
                      </div> 
                     : ''
                   }
                   <label><ImageIcon style={{fontSize: '17px'}}/> Upload Image</label>
                   <span style={{fontSize: '12px', fontStyle: 'italic', margin: '8px 0px 8px 10px', display: 'inline-block'}}>(Recomended dimension for images - 400xauto)</span>
                   <input type='file' name='custom_img' id="custom_img" onChange={handleUploadCustomImage}></input>
                   { custom_image_upload === true ?
                     <p style={{fontSize: '13px'}}>Uploading...</p>
                     : ''
                   }
                   { data.custom_image !== '' ?
                     <>
                      <div id="profile_pic_view">
                       <img src={'https://nfcleads.au/nodeapi/uploads/' + data.custom_image} style={{width: '300px', margin: '30px 0'}}/>
                       <button className="testimonial-btn red-btn" type="button" onClick={removeCustomImage}>Remove</button>
                      </div>
                      <br></br>
                      <label><ImageIcon style={{fontSize: '17px'}}/> Image Border Line Thickness</label>
                      <input type="number" value={data.custom_image_border_width} onInput={(e) => setData({...data, custom_image_border_width: e.target.value})} /><br></br>
                      <label><ImageIcon style={{fontSize: '17px'}}/> Image Border Line Colour</label><br></br><br></br>
                      <SketchPicker onChange={(color) => { setCustomImageBorderColor(color.rgb); setData({...data, custom_image_border_color: color.rgb}); }} color={custom_image_border_color} />
                      <br></br>
                      <label>Space Top</label>
                      <input type="number" value={data.custom_image_padding_top} onChange={(e) => setData({...data, custom_image_padding_top: e.target.value})}/>
                      <label>Space Bottom</label>
                      <input type="number" value={data.custom_image_padding_bottom} onChange={(e) => setData({...data, custom_image_padding_bottom: e.target.value})}/>
                      <br></br>
                     </>
                     : ''
                   }
                 </FormField>
                 : ''
               }

               <FormField>
                 <Switch>
                   { data.second_custom_image_show === 1 ? 
                     <input type="checkbox" checked onChange={(e) => setData({...data, second_custom_image_show: 0})}/>
                     : 
                     <input type="checkbox" onChange={(e) => setData({...data, second_custom_image_show: 1})}/>
                   }
                   <label>Insert Custom Image (Two)</label>
                 </Switch>
               </FormField>

               { data.second_custom_image_show === 1 ?
                 <FormField>
                   <SwitchSmall>
                   { data.second_custom_image_title === 1 ? 
                     <input type="checkbox" checked onChange={(e) => setData({...data, second_custom_image_title: 0})}/>
                     : 
                     <input type="checkbox" onChange={(e) => setData({...data, second_custom_image_title: 1})}/>
                   }
                     <label>Show Custom Image Title</label>
                   </SwitchSmall>
                   { data.second_custom_image_title === 1 ? 
                      <div className="header_title">
                       <input type="text" value={data.second_custom_image_title_label} onChange={(e) => setData({...data, second_custom_image_title_label: e.target.value})} required placeholder="Enter custom image title"/>
                       <span className="customize" onClick={() => setCustomize({...customize, modal: true, content: 'custom-image-title-second'})} title="Styles"><SettingsIcon/></span>
                      </div> 
                     : ''
                   }
                   <label><ImageIcon style={{fontSize: '17px'}}/> Upload Image</label>
                   <span style={{fontSize: '12px', fontStyle: 'italic', margin: '8px 0px 8px 10px', display: 'inline-block'}}>(Recomended dimension for images - 400xauto)</span>
                   <input type='file' name='custom_img' id="custom_img" onChange={handleUploadSecondCustomImage}></input>
                   { second_custom_image_upload === true ?
                     <p style={{fontSize: '13px'}}>Uploading...</p>
                     : ''
                   }
                   { data.second_custom_image !== '' ?
                    <>
                     <div id="profile_pic_view">
                      <img src={'https://nfcleads.au/nodeapi/uploads/' + data.second_custom_image} style={{width: '300px', margin: '30px 0'}}/>
                      <button className="testimonial-btn red-btn" type="button" onClick={removeSecondCustomImage}>Remove</button>
                     </div>
                     <br></br>
                     <label><ImageIcon style={{fontSize: '17px'}}/> Image Border Line Thickness</label>
                     <input type="number" value={data.second_custom_image_border_width} onInput={(e) => setData({...data, second_custom_image_border_width: e.target.value})} /><br></br>
                     <label><ImageIcon style={{fontSize: '17px'}}/> Image Border Line Colour</label><br></br><br></br>
                     <SketchPicker onChange={(color) => { setSecondCustomImageBorderColor(color.rgb); setData({...data, second_custom_image_border_color: color.rgb}); }} color={second_custom_image_border_color} />
                     <br></br>
                     <label>Space Top</label>
                     <input type="number" value={data.second_custom_image_padding_top} onChange={(e) => setData({...data, second_custom_image_padding_top: e.target.value})}/>
                     <label>Space Bottom</label>
                     <input type="number" value={data.second_custom_image_padding_bottom} onChange={(e) => setData({...data, second_custom_image_padding_bottom: e.target.value})}/>
                     <br></br>
                    </>
                     : ''
                   }                   
                 </FormField>
                 : ''
               }

               <FormField>
                 <Switch>
                   { data.image_grid_show === 1 ? 
                     <input type="checkbox" checked onChange={(e) => setData({...data, image_grid_show: 0})}/>
                     : 
                     <input type="checkbox" onChange={(e) => setData({...data, image_grid_show: 1})}/>
                   }
                   <label>Insert Photo Gallery</label>
                 </Switch>
               </FormField>

               { data.image_grid_show === 1 ?
                <>
                 <SwitchSmall>
                   { data.image_grid_title === 1 ? 
                     <input type="checkbox" checked onChange={(e) => setData({...data, image_grid_title: 0})}/>
                     : 
                     <input type="checkbox" onChange={(e) => setData({...data, image_grid_title: 1})}/>
                   }
                     <label>Show Photo Gallery Title</label>
                   </SwitchSmall>
                   { data.image_grid_title === 1 ? 
                     <div className="header_title">
                       <input type="text" value={data.image_grid_title_label} onChange={(e) => setData({...data, image_grid_title_label: e.target.value})} required placeholder="Enter image gallery title"/>
                       <span className="customize" onClick={() => setCustomize({...customize, modal: true, content: 'image-grid-title'})} title="Styles"><SettingsIcon/></span>
                     </div> 
                     : ''
                   }
                 <label>Upload Photos to Gallery</label><br></br>
                 <span style={{fontSize: '12px', fontStyle: 'italic', margin: '8px 0', display: 'inline-block'}}>Square images are best fit</span>
                 <input
                   id="imagegridUpload"
                   accept="image/*"
                   type="file"
                   multiple
                   onChange={handleImageGridUpload}
                 />

                { image_grid_upload === 1 ? <span style={{fontSize: '14px'}}>Uploading...</span> : '' }
                { image_grid_upload === 2 ? <span style={{fontSize: '14px'}}><CheckCircleIcon style={{verticalAlign: 'sub', fontSize: '18px', color: '#4caf50'}}/> Uploaded</span> : '' }

           {data.image_grid && (
             <Gallery>
               {data.image_grid.map((img, i) => {
                 return (
                  <div className="gallery-item">
                    <img className="preview" src={'https://nfcleads.au/nodeapi/uploads/' + img} />
                    <button className="testimonial-btn red-btn" type="button" id={img} onClick={removeFromImageGrid}>Remove</button>
                  </div>
                 );
               })}
              </Gallery>
            )}
               </>
                : ''
               }

               { data.image_grid_show === 1 ?
                 <>
                 <label>Select Number of columns</label>
                 <select value={data.image_grid_column} onChange={(e) => setData({...data, image_grid_column: e.target.value})}>
                   <option value="1">1</option>
                   <option value="2">2</option>
                   <option value="3">3</option>
                 </select>
                 <label>Space Top</label>
                 <input type="number" value={data.testimonial_padding_top} onChange={(e) => setData({...data, testimonial_padding_top: e.target.value})}/>
                 <label>Space Bottom</label>
                 <input type="number" value={data.testimonial_padding_bottom} onChange={(e) => setData({...data, testimonial_padding_bottom: e.target.value})}/>
                 <br></br>
                 </>
                 : '' 
               }
               
               <br></br>

               <FormField>
                 <Switch>
                   { data.map === 1 ? 
                     <input type="checkbox" checked onChange={(e) => setData({...data, map: 0})}/>
                     : 
                     <input type="checkbox" onChange={(e) => setData({...data, map: 1})}/>
                   }
                   <label>Insert Google Map</label>
                 </Switch>
               </FormField>

               { data.map === 1 ?
                 <FormField>
                   <SwitchSmall>
                   { data.map_title === 1 ? 
                     <input type="checkbox" checked onChange={(e) => setData({...data, map_title: 0})}/>
                     : 
                     <input type="checkbox" onChange={(e) => setData({...data, map_title: 1})}/>
                   }
                     <label>Show Map Title</label>
                   </SwitchSmall>
                   { data.map_title === 1 ? 
                     <div className="header_title">
                       <input type="text" value={data.map_title_label} onChange={(e) => setData({...data, map_title_label: e.target.value})} required placeholder="Enter map title"/>
                       <span className="customize" onClick={() => setCustomize({...customize, modal: true, content: 'map-title'})} title="Styles"><SettingsIcon/></span>
                     </div> 
                     : ''
                   }
                   <label>Latitude</label>
                   <input type="text" value={data.latitude} onChange={(e) => setData({...data, latitude: e.target.value})} required placeholder="Ex. 37.42216"/>
                   <label>Longitude</label>
                   <input type="text" value={data.longitude} onChange={(e) => setData({...data, longitude: e.target.value})} required placeholder="Ex. -122.08427"/>
                   <br></br><br></br>
                   <label>Space Top</label>
                   <input type="number" value={data.map_padding_top} onChange={(e) => setData({...data, map_padding_top: e.target.value})}/>
                   <label>Space Bottom</label>
                   <input type="number" value={data.map_padding_bottom} onChange={(e) => setData({...data, map_padding_bottom: e.target.value})}/>
                 </FormField>
                 : ''
               }

               <FormField>
                 <Switch>
                   { data.add_accordion === 1 ? 
                     <input type="checkbox" checked onChange={(e) => setData({...data, add_accordion: 0})}/>
                     : 
                     <input type="checkbox" onChange={(e) => setData({...data, add_accordion: 1})}/>
                   }
                   <label>Insert Accordion</label>
                 </Switch>
               </FormField>

               { data.add_accordion === 1 ?
                 <FormField>
                   <SwitchSmall>
                   { data.accordion_title === 1 ? 
                     <input type="checkbox" checked onChange={(e) => setData({...data, accordion_title: 0})}/>
                     : 
                     <input type="checkbox" onChange={(e) => setData({...data, accordion_title: 1})}/>
                   }
                     <label>Show Accordion Title</label>
                   </SwitchSmall>
                   { data.accordion_title === 1 ? 
                     <div className="header_title">
                       <input type="text" value={data.accordion_title_label} onChange={(e) => setData({...data, accordion_title_label: e.target.value})} required placeholder="Enter accordion title"/>
                       <span className="customize" onClick={() => setCustomize({...customize, modal: true, content: 'accordion-title'})} title="Styles"><SettingsIcon/></span>
                     </div> 
                     : ''
                   }
                   <div id="accordion">
                    { data.accordions.map((accordion,index) => 
                       <div className="accordion-section">
                        <input type="text" id={'name_accordion-' + accordion.id} value={accordion.name} onChange={(e) =>{ const newTitle = e.target.value; updateAccordionTitle(index, newTitle); }}/>
                        <textarea id={'content_accordion-' + accordion.id} value={accordion.content} onChange={(e) =>{ const newContent = e.target.value; updateAccordionContent(index, newContent); }} />
                        <button className="accordion-btn red-btn" type="button" id={accordion.id} onClick={removeFromAccordion}>Remove</button>
                       </div>) 
                    }
                    { show_new_accordion === true ?
                        <>
                         { numbers.map((number) => 
                          <div className="accordion-section">
                           <input type="text" id={'name_accordion-' + number.id} placeholder="Header" />
                           <textarea id={'content_accordion-' + number.id} placeholder="Content" />
                           <button className="accordion-btn" type="button" id={number.id} onClick={addToAccordion}>Add</button>
                           <button className="accordion-btn red-btn" type="button" id={number.id} onClick={removeFromAccordion}>Remove</button>
                          </div>) 
                         }
                        </>
                      : '' 
                    }
                   </div>
                   <FormField>
                     <span onClick={addAccordion} style={{fontSize: '13px', fontWeight: '500', cursor: 'pointer'}}><AddIcon style={{background: '#ff9800', color: '#fff', borderRadius: '50%', padding: '2px', fontSize: '20px', cursor: 'pointer', verticalAlign: 'bottom', marginRight: '3px'}} /> Add New Accordion Header and Content</span>
                   </FormField>
                   <br></br>
                   <label>Space Top</label>
                   <input type="number" value={data.accordion_padding_top} onChange={(e) => setData({...data, accordion_padding_top: e.target.value})}/>
                   <label>Space Bottom</label>
                   <input type="number" value={data.accordion_padding_bottom} onChange={(e) => setData({...data, accordion_padding_bottom: e.target.value})}/>
                 </FormField>
                 : ''
               }

               <FormField>
                 <Switch>
                   { data.add_table === 1 ? 
                     <input type="checkbox" checked onChange={(e) => setData({...data, add_table: 0})}/>
                     : 
                     <input type="checkbox" onChange={(e) => setData({...data, add_table: 1})}/>
                   }
                   <label>Insert Table</label>
                 </Switch>
               </FormField>

               { data.add_table === 1 ?
                 <FormField>
                   <SwitchSmall>
                   { data.table_title === 1 ? 
                     <input type="checkbox" checked onChange={(e) => setData({...data, table_title: 0})}/>
                     : 
                     <input type="checkbox" onChange={(e) => setData({...data, table_title: 1})}/>
                   }
                     <label>Show Table Title?</label>
                   </SwitchSmall>
                   { data.table_title === 1 ? 
                     <div className="header_title">
                       <input type="text" value={data.table_title_label} onChange={(e) => setData({...data, table_title_label: e.target.value})} required placeholder="Enter table title"/>
                       <span className="customize" onClick={() => setCustomize({...customize, modal: true, content: 'table-title'})} title="Styles"><SettingsIcon/></span>
                     </div> 
                     : ''
                   }
                   <br></br>
                   <Row>
                     <div className="column-popup">
                       <label>Number of Rows</label>
                       <input type="number" value={rows} onChange={handleRowsChange}/> 
                     </div>
                     <div className="column-popup">
                       <label>Number of Columns</label>
                       <input max="3" type="number" value={columns} onChange={handleColumnsChange}/> 
                     </div>
                   </Row>
                   
    {data.table.length > 0 && (
      <CardTable> 
        <table>
          <tbody>
            {data.table.map((rowData, rowIndex) => (
              <tr key={rowIndex}>
                {rowData.map((cellData, columnIndex) => (
                  <td key={columnIndex}>
                    <input
                      type="text"
                      value={cellData}
                      onChange={(e) => handleInputChange(e, rowIndex, columnIndex)}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
       </CardTable> 
      )}

                  <Row>
                    <div className="column-popup">
                      <button className="table-btn" type="button" onClick={handleSave}>{ create_table === true ? 'Create Table' : 'Reset Table'}</button>
                    </div>
                    <div className="column-popup">
                      <button className="table-btn-1 customize-tbl" type="button" onClick={() => setCustomize({...customize, modal: true, content: 'table-styles'})}><SettingsIcon/> Customize Table</button>
                    </div>
                  </Row>
                   
                   <br></br>
                   <label>Space Top</label>
                   <input type="number" value={data.table_padding_top} onChange={(e) => setData({...data, table_padding_top: e.target.value})}/>
                   <label>Space Bottom</label>
                   <input type="number" value={data.table_padding_bottom} onChange={(e) => setData({...data, table_padding_bottom: e.target.value})}/>
                 </FormField>
                 : ''
               }

               <FormField>
                 <Switch>
                   { data.add_block_image_text === 1 ? 
                     <input type="checkbox" checked onChange={(e) => setData({...data, add_block_image_text: 0})}/>
                     : 
                     <input type="checkbox" onChange={(e) => setData({...data, add_block_image_text: 1})}/>
                   }
                   <label>Insert Image and Text Block</label>
                 </Switch>
               </FormField>

               { data.add_block_image_text === 1 ?
                 <FormField>
                   <SwitchSmall>
                   { data.block_image_text_title === 1 ? 
                     <input type="checkbox" checked onChange={(e) => setData({...data, block_image_text_title: 0})}/>
                     : 
                     <input type="checkbox" onChange={(e) => setData({...data, block_image_text_title: 1})}/>
                   }
                     <label>Show Image and Text Block Title</label>
                   </SwitchSmall>
                   { data.block_image_text_title === 1 ? 
                     <div className="header_title">
                       <input type="text" value={data.block_image_text_title_label} onChange={(e) => setData({...data, block_image_text_title_label: e.target.value})} required placeholder="Enter title"/>
                       <span className="customize" onClick={() => setCustomize({...customize, modal: true, content: 'block-image-text-title'})} title="Styles"><SettingsIcon/></span>
                     </div> 
                     : ''
                   }
                   <label><ImageIcon style={{fontSize: '17px'}}/> Upload Image</label>
                   <span style={{fontSize: '12px', fontStyle: 'italic', margin: '8px 0px 8px 10px', display: 'inline-block'}}>(Recomended dimension for images - 400xauto)</span>
                   <input type='file' name='block_image_text_img' id="block_image_text_img" onChange={handleUploadBlockImage}></input>
                   { block_image_text_upload === true ?
                     <p style={{fontSize: '13px'}}>Uploading...</p>
                     : ''
                   }
                   { data.block_image_text_img !== '' ?
                    <div id="profile_pic_view">
                      <img src={'https://nfcleads.au/nodeapi/uploads/' + data.block_image_text_img} style={{width: '250px', margin: '30px 0'}}/>
                      <button className="testimonial-btn red-btn" type="button" onClick={removeBlockImage}>Remove</button>
                      <span className="customize-1" onClick={() => setCustomize({...customize, modal: true, content: 'block-image-text-img'})} title="Styles"><SettingsIcon/>Customize Image</span>
                     </div>
                     : ''
                   } 
                   <br></br>
                   <label><BorderColorIcon style={{fontSize: '17px'}}/> Paragraph <span className="customize" onClick={() => setCustomize({...customize, modal: true, content: 'block-image-text'})} title="Styles"><SettingsIcon/></span></label>
                   <textarea value={data.block_image_text_paragraph} onChange={(e) => setData({...data, block_image_text_paragraph: e.target.value})} required placeholder="Paragraph"/> 
                   <br></br><br></br>
                   <label>Space Top</label>
                   <input type="number" value={data.block_image_text_padding_top} onChange={(e) => setData({...data, block_image_text_padding_top: e.target.value})}/>
                   <label>Space Bottom</label>
                   <input type="number" value={data.block_image_text_padding_bottom} onChange={(e) => setData({...data, block_image_text_padding_bottom: e.target.value})}/>
                 </FormField>
                 : ''
               }

               <FormField>
                 <Switch>
                   { data.two_image_show === 1 ? 
                     <input type="checkbox" checked onChange={(e) => setData({...data, two_image_show: 0})}/>
                     : 
                     <input type="checkbox" onChange={(e) => setData({...data, two_image_show: 1})}/>
                   }
                   <label>Insert Two Images with Hyperlinks</label>
                 </Switch>
               </FormField>

               { data.two_image_show === 1 ?
                 <FormField>
                   <SwitchSmall>
                   { data.two_image_title === 1 ? 
                     <input type="checkbox" checked onChange={(e) => setData({...data, two_image_title: 0})}/>
                     : 
                     <input type="checkbox" onChange={(e) => setData({...data, two_image_title: 1})}/>
                   }
                     <label>Show Two Images with Hyperlinks Block Title</label>
                   </SwitchSmall>
                   { data.two_image_title === 1 ? 
                     <div className="header_title">
                       <input type="text" value={data.two_image_title_label} onChange={(e) => setData({...data, two_image_title_label: e.target.value})} required placeholder="Enter title"/>
                       <span className="customize" onClick={() => setCustomize({...customize, modal: true, content: 'two-image-title'})} title="Styles"><SettingsIcon/></span>
                     </div> 
                     : ''
                   }
                   <label><ImageIcon style={{fontSize: '17px'}}/> Upload Image 1</label><br></br>
                   <span style={{fontSize: '12px', fontStyle: 'italic', margin: '8px 0', display: 'inline-block'}}>Recomended dimension for images - 210px</span>
                   <input type='file' name='two_image_upload_first' className="two_image_upload" onChange={handleUploadTwoImageFirst}></input>
                   { two_image_upload_first === true ?
                     <p style={{fontSize: '13px'}}>Uploading...</p>
                     : ''
                   }
                   { data.two_image_first !== '' ?
                    <div id="profile_pic_view">
                      <img src={'https://nfcleads.au/nodeapi/uploads/' + data.two_image_first} style={{width: '210px', margin: '30px 0'}}/>
                      <button className="testimonial-btn red-btn" type="button" onClick={removeTwoImgFirst}>Remove</button>
                     </div>
                     : ''
                   } 
                   <input type="text" value={data.two_image_first_link} onChange={(e) => setData({...data, two_image_first_link: e.target.value})} placeholder="HyperLink Image 1"/>
                   <br></br>
                   <SwitchSmall>
                   { data.two_image_first_link_show === 1 ? 
                     <input type="checkbox" checked onChange={(e) => setData({...data, two_image_first_link_show: 0})}/>
                     : 
                     <input type="checkbox" onChange={(e) => setData({...data, two_image_first_link_show: 1})}/>
                   }
                     <label>Show Hyperlink for Image 1</label>
                   </SwitchSmall>
                   <span className="two_image_hyper_customize" onClick={() => setCustomize({...customize, modal: true, content: 'custom-img-1'})}><SettingsIcon/> Customize Image 1</span>
                   <br></br><br></br>
                   <label><ImageIcon style={{fontSize: '17px'}}/> Upload Image 2</label><br></br>
                   <span style={{fontSize: '12px', fontStyle: 'italic', margin: '8px 0', display: 'inline-block'}}>Recomended dimension for image - 210px</span>
                   <input type='file' name='two_image_upload_first' className="two_image_upload" onChange={handleUploadTwoImageLast}></input>
                   { two_image_upload_last === true ?
                     <p style={{fontSize: '13px'}}>Uploading...</p>
                     : ''
                   }
                   { data.two_image_last !== '' ?
                    <div id="profile_pic_view">
                       <img src={'https://nfcleads.au/nodeapi/uploads/' + data.two_image_last} style={{width: '210px', margin: '30px 0'}}/>
                       <button className="testimonial-btn red-btn" type="button" onClick={removeTwoImgLast}>Remove</button>
                     </div>
                     : ''
                   } 
                   <input type="text" value={data.two_image_last_link} onChange={(e) => setData({...data, two_image_last_link: e.target.value})} placeholder="HyperLink Image 2"/>
                   <br></br>
                   <SwitchSmall>
                   { data.two_image_last_link_show === 1 ? 
                     <input type="checkbox" checked onChange={(e) => setData({...data, two_image_last_link_show: 0})}/>
                     : 
                     <input type="checkbox" onChange={(e) => setData({...data, two_image_last_link_show: 1})}/>
                   }
                     <label>Show Hyperlink for Image 2</label>
                   </SwitchSmall>
                   <span className="two_image_hyper_customize" onClick={() => setCustomize({...customize, modal: true, content: 'custom-img-2'})}><SettingsIcon/> Customize Image 2</span>
                   <br></br><br></br>
                   <label>Space Top</label>
                   <input type="number" value={data.two_image_padding_top} onChange={(e) => setData({...data, two_image_padding_top: e.target.value})}/>
                   <label>Space Bottom</label>
                   <input type="number" value={data.two_image_padding_bottom} onChange={(e) => setData({...data, two_image_padding_bottom: e.target.value})}/>
                 </FormField>
                 : ''
               }

               <FormField>
                 <Switch>
                   { data.add_iframe === 1 ? 
                     <input type="checkbox" checked onChange={(e) => setData({...data, add_iframe: 0})}/>
                     : 
                     <input type="checkbox" onChange={(e) => setData({...data, add_iframe: 1})}/>
                   }
                   <label>Insert iframe (First)</label>
                 </Switch>
               </FormField>

               { data.add_iframe === 1 ?
                 <FormField>
                   <SwitchSmall>
                   { data.iframe_title === 1 ? 
                     <input type="checkbox" checked onChange={(e) => setData({...data, iframe_title: 0})}/>
                     : 
                     <input type="checkbox" onChange={(e) => setData({...data, iframe_title: 1})}/>
                   }
                     <label>Show iframe Title</label>
                   </SwitchSmall>
                   { data.iframe_title === 1 ? 
                     <div className="header_title">
                       <input type="text" value={data.iframe_title_label} onChange={(e) => setData({...data, iframe_title_label: e.target.value})} required placeholder="Enter iframe title"/>
                       <span className="customize" onClick={() => setCustomize({...customize, modal: true, content: 'iframe-title'})} title="Styles"><SettingsIcon/></span>
                     </div> 
                     : ''
                   }
                   <label>Iframe URL</label>
                   <input type="text" value={data.iframe_url} onChange={(e) => setData({...data, iframe_url: e.target.value})} required placeholder="Enter iframe URL"/>
                   <label>Iframe Width</label>
                   <input type="number" value={data.iframe_width} onChange={(e) => setData({...data, iframe_width: e.target.value})} placeholder="Enter Iframe Width"/> 
                   <label>Iframe Height</label>
                   <input type="number" value={data.iframe_height} onChange={(e) => setData({...data, iframe_height: e.target.value})} placeholder="Enter Iframe Height"/> 
                   <label>Iframe Scrollbar</label>
                   <select value={data.iframe_scrollbar} onChange={(e) => setData({...data, iframe_scrollbar: e.target.value})}>
                     <option value="no">No</option>
                     <option value="yes">Yes</option>
                   </select>
                   <label>Show Iframe Border</label>
                   <select value={data.iframe_show_border} onChange={(e) => setData({...data, iframe_show_border: e.target.value})}>
                     <option value="no">No</option>
                     <option value="yes">Yes</option>
                   </select>
                   <label>Iframe Border Type</label>
                   <select value={data.iframe_border_type} onChange={(e) => setData({...data, iframe_border_type: e.target.value})}>
                     <option value="solid">Solid</option>
                     <option value="dotted">Dotted</option>
                     <option value="dashed">Dashed</option>
                   </select>
                   <label>Iframe Border Size</label>
                   <input type="number" value={data.iframe_border_width} onChange={(e) => setData({...data, iframe_border_width: e.target.value})}/> 
                   <label>Iframe Border Colour</label>
                   <SketchPicker onChange={(color) => { setIframeBorderColor(color.rgb); setData({...data, iframe_border_color: color.rgb}); }} color={iframe_border_color} />
                   <br></br>
                   <label>Space Top</label>
                   <input type="number" value={data.iframe_padding_top} onChange={(e) => setData({...data, iframe_padding_top: e.target.value})}/>
                   <label>Space Bottom</label>
                   <input type="number" value={data.iframe_padding_bottom} onChange={(e) => setData({...data, iframe_padding_bottom: e.target.value})}/>
                 </FormField>
                 : ''
               }

               <FormField>
                 <Switch>
                   { data.second_add_iframe === 1 ? 
                     <input type="checkbox" checked onChange={(e) => setData({...data, second_add_iframe: 0})}/>
                     : 
                     <input type="checkbox" onChange={(e) => setData({...data, second_add_iframe: 1})}/>
                   }
                   <label>Insert iframe (Second)</label>
                 </Switch>
               </FormField>

               { data.second_add_iframe === 1 ?
                 <FormField>
                   <SwitchSmall>
                   { data.second_iframe_title === 1 ? 
                     <input type="checkbox" checked onChange={(e) => setData({...data, second_iframe_title: 0})}/>
                     : 
                     <input type="checkbox" onChange={(e) => setData({...data, second_iframe_title: 1})}/>
                   }
                     <label>Show iframe Title</label>
                   </SwitchSmall>
                   { data.second_iframe_title === 1 ? 
                     <div className="header_title">
                       <input type="text" value={data.second_iframe_title_label} onChange={(e) => setData({...data, second_iframe_title_label: e.target.value})} required placeholder="Enter iframe title"/>
                       <span className="customize" onClick={() => setCustomize({...customize, modal: true, content: 'second-iframe-title'})} title="Styles"><SettingsIcon/></span>
                     </div> 
                     : ''
                   }
                   <label>Iframe URL</label>
                   <input type="text" onChange={(e) => setData({...data, second_iframe_url: e.target.value})} required placeholder="Enter iframe URL"/>
                   <label>Iframe Width</label>
                   <input type="number" value={data.second_iframe_width} onChange={(e) => setData({...data, second_iframe_width: e.target.value})} placeholder="Enter Iframe Width"/> 
                   <label>Iframe Height</label>
                   <input type="number" value={data.second_iframe_height} onChange={(e) => setData({...data, second_iframe_height: e.target.value})} placeholder="Enter Iframe Height"/> 
                   <label>Iframe Scrollbar</label>
                   <select value={data.second_iframe_scrollbar} onChange={(e) => setData({...data, second_iframe_scrollbar: e.target.value})}>
                     <option value="no">No</option>
                     <option value="yes">Yes</option>
                   </select>
                   <label>Show Iframe Border</label>
                   <select value={data.second_iframe_show_border} onChange={(e) => setData({...data, second_iframe_show_border: e.target.value})}>
                     <option value="no">No</option>
                     <option value="yes">Yes</option>
                   </select>
                   <label>Iframe Border Type</label>
                   <select value={data.second_iframe_border_type} onChange={(e) => setData({...data, second_iframe_border_type: e.target.value})}>
                     <option value="solid">Solid</option>
                     <option value="dotted">Dotted</option>
                     <option value="dashed">Dashed</option>
                   </select>
                   <label>Iframe Border Size</label>
                   <input type="number" value={data.second_iframe_border_width} onChange={(e) => setData({...data, second_iframe_border_width: e.target.value})} placeholder=""/> 
                   <label>Iframe Border Colour</label>
                   <SketchPicker onChange={(color) => { setSecondIframeBorderColor(color.rgb); setData({...data, second_iframe_border_color: color.rgb}); }} color={second_iframe_border_color} />
                   <br></br>
                   <label>Space Top</label>
                   <input type="number" value={data.second_iframe_padding_top} onChange={(e) => setData({...data, second_iframe_padding_top: e.target.value})}/>
                   <label>Space Bottom</label>
                   <input type="number" value={data.second_iframe_padding_bottom} onChange={(e) => setData({...data, second_iframe_padding_bottom: e.target.value})}/>
                 </FormField>
                 : ''
               }

               <FormField>
                 <Switch>
                   { data.third_add_iframe === 1 ? 
                     <input type="checkbox" checked onChange={(e) => setData({...data, third_add_iframe: 0})}/>
                     : 
                     <input type="checkbox" onChange={(e) => setData({...data, third_add_iframe: 1})}/>
                   }
                   <label>Insert iframe (Third)</label>
                 </Switch>
               </FormField>

               { data.third_add_iframe === 1 ?
                 <FormField>
                   <SwitchSmall>
                   { data.third_iframe_title === 1 ? 
                     <input type="checkbox" checked onChange={(e) => setData({...data, third_iframe_title: 0})}/>
                     : 
                     <input type="checkbox" onChange={(e) => setData({...data, third_iframe_title: 1})}/>
                   }
                     <label>Show iframe Title</label>
                   </SwitchSmall>
                   { data.third_iframe_title === 1 ? 
                     <div className="header_title">
                       <input type="text" value={data.third_iframe_title_label} onChange={(e) => setData({...data, third_iframe_title_label: e.target.value})} required placeholder="Enter iframe title"/>
                       <span className="customize" onClick={() => setCustomize({...customize, modal: true, content: 'third-iframe-title'})} title="Styles"><SettingsIcon/></span>
                     </div> 
                     : ''
                   }
                   <label>Iframe URL</label>
                   <input type="text" onChange={(e) => setData({...data, third_iframe_url: e.target.value})} required placeholder="Enter iframe URL"/>
                   <label>Iframe Width</label>
                   <input type="number" value={data.third_iframe_width} onChange={(e) => setData({...data, third_iframe_width: e.target.value})} placeholder="Enter Iframe Width"/> 
                   <label>Iframe Height</label>
                   <input type="number" value={data.third_iframe_height} onChange={(e) => setData({...data, third_iframe_height: e.target.value})} placeholder="Enter Iframe Height"/> 
                   <label>Iframe Scrollbar</label>
                   <select value={data.third_iframe_scrollbar} onChange={(e) => setData({...data, third_iframe_scrollbar: e.target.value})}>
                     <option value="no">No</option>
                     <option value="yes">Yes</option>
                   </select>
                   <label>Show Iframe Border</label>
                   <select value={data.third_iframe_show_border} onChange={(e) => setData({...data, third_iframe_show_border: e.target.value})}>
                     <option value="no">No</option>
                     <option value="yes">Yes</option>
                   </select>
                   <label>Iframe Border Type</label>
                   <select value={data.third_iframe_border_type} onChange={(e) => setData({...data, third_iframe_border_type: e.target.value})}>
                     <option value="solid">Solid</option>
                     <option value="dotted">Dotted</option>
                     <option value="dashed">Dashed</option>
                   </select>
                   <label>Iframe Border Size</label>
                   <input type="number" value={data.third_iframe_border_width} onChange={(e) => setData({...data, third_iframe_border_width: e.target.value})} placeholder=""/> 
                   <label>Iframe Border Colour</label>
                   <SketchPicker onChange={(color) => { setThirdIframeBorderColor(color.rgb); setData({...data, third_iframe_border_color: color.rgb}); }} color={third_iframe_border_color} />
                   <br></br>
                   <label>Space Top</label>
                   <input type="number" value={data.third_iframe_padding_top} onChange={(e) => setData({...data, third_iframe_padding_top: e.target.value})}/>
                   <label>Space Bottom</label>
                   <input type="number" value={data.third_iframe_padding_bottom} onChange={(e) => setData({...data, third_iframe_padding_bottom: e.target.value})}/>
                 </FormField>
                 : ''
               }

               <FormField>
                 <Switch>
                   { data.fourth_add_iframe === 1 ? 
                     <input type="checkbox" checked onChange={(e) => setData({...data, fourth_add_iframe: 0})}/>
                     : 
                     <input type="checkbox" onChange={(e) => setData({...data, fourth_add_iframe: 1})}/>
                   }
                   <label>Insert iframe (Fourth)</label>
                 </Switch>
               </FormField>

               { data.fourth_add_iframe === 1 ?
                 <FormField>
                   <SwitchSmall>
                   { data.fourth_iframe_title === 1 ? 
                     <input type="checkbox" checked onChange={(e) => setData({...data, fourth_iframe_title: 0})}/>
                     : 
                     <input type="checkbox" onChange={(e) => setData({...data, fourth_iframe_title: 1})}/>
                   }
                     <label>Show iframe Title</label>
                   </SwitchSmall>
                   { data.fourth_iframe_title === 1 ? 
                     <div className="header_title">
                       <input type="text" value={data.fourth_iframe_title_label} onChange={(e) => setData({...data, fourth_iframe_title_label: e.target.value})} required placeholder="Enter iframe title"/>
                       <span className="customize" onClick={() => setCustomize({...customize, modal: true, content: 'fourth-iframe-title'})} title="Styles"><SettingsIcon/></span>
                     </div> 
                     : ''
                   }
                   <label>Iframe URL</label>
                   <input type="text" onChange={(e) => setData({...data, fourth_iframe_url: e.target.value})} required placeholder="Enter iframe URL"/>
                   <label>Iframe Width</label>
                   <input type="number" value={data.fourth_iframe_width} onChange={(e) => setData({...data, fourth_iframe_width: e.target.value})} placeholder="Enter Iframe Width"/> 
                   <label>Iframe Height</label>
                   <input type="number" value={data.fourth_iframe_height} onChange={(e) => setData({...data, fourth_iframe_height: e.target.value})} placeholder="Enter Iframe Height"/> 
                   <label>Iframe Scrollbar</label>
                   <select value={data.fourth_iframe_scrollbar} onChange={(e) => setData({...data, fourth_iframe_scrollbar: e.target.value})}>
                     <option value="no">No</option>
                     <option value="yes">Yes</option>
                   </select>
                   <label>Show Iframe Border</label>
                   <select value={data.fourth_iframe_show_border} onChange={(e) => setData({...data, fourth_iframe_show_border: e.target.value})}>
                     <option value="no">No</option>
                     <option value="yes">Yes</option>
                   </select>
                   <label>Iframe Border Type</label>
                   <select value={data.fourth_iframe_border_type} onChange={(e) => setData({...data, fourth_iframe_border_type: e.target.value})}>
                     <option value="solid">Solid</option>
                     <option value="dotted">Dotted</option>
                     <option value="dashed">Dashed</option>
                   </select>
                   <label>Iframe Border Size</label>
                   <input type="number" value={data.fourth_iframe_border_width} onChange={(e) => setData({...data, fourth_iframe_border_width: e.target.value})} placeholder=""/> 
                   <label>Iframe Border Colour</label>
                   <SketchPicker onChange={(color) => { setFourthIframeBorderColor(color.rgb); setData({...data, fourth_iframe_border_color: color.rgb}); }} color={fourth_iframe_border_color} />
                   <br></br>
                   <label>Space Top</label>
                   <input type="number" value={data.fourth_iframe_padding_top} onChange={(e) => setData({...data, fourth_iframe_padding_top: e.target.value})}/>
                   <label>Space Bottom</label>
                   <input type="number" value={data.fourth_iframe_padding_bottom} onChange={(e) => setData({...data, fourth_iframe_padding_bottom: e.target.value})}/>
                 </FormField>
                 : ''
               }

               <FormField>
                 <Switch>
                   { data.add_text_between_line === 1 ? 
                     <input type="checkbox" checked onChange={(e) => setData({...data, add_text_between_line: 0})}/>
                     : 
                     <input type="checkbox" onChange={(e) => setData({...data, add_text_between_line: 1})}/>
                   }
                   <label>Insert Word in the Middle of Horizontal Line (One)</label>
                 </Switch>
               </FormField>

               { data.add_text_between_line === 1 ?
                 <FormField>
                   <label>Words</label>
                   <input type="text" value={data.text_between_line_word} onChange={(e) => setData({...data, text_between_line_word: e.target.value})}/>
                   <label>Word Font Size</label>
                   <input type="number" value={data.text_between_line_word_font_size} onChange={(e) => setData({...data, text_between_line_word_font_size: e.target.value})}/>
                   <label>Word Font Weight</label>
                   <input type="number" value={data.text_between_line_word_font_weight} onChange={(e) => setData({...data, text_between_line_word_font_weight: e.target.value})}/>
                   <label>Word Format</label>
                   <select value={data.text_between_line_word_format} onChange={(e) => setData({...data, text_between_line_word_format: e.target.value})}>
                     <option>Select Format</option>
                     <option value="regular">Regular</option>
                     <option value="italic">Italic</option>
                   </select>
                   <label>Word Colour</label>
                   <SketchPicker onChange={(color) => { setTextBetweenLineWordColor(color.rgb); setData({...data, text_between_line_word_color: color.rgb}); }} color={text_between_line_word_color} />
                   <br></br><br></br>
                   <label>Line Size</label>
                   <input type="number" value={data.text_between_line_size} onChange={(e) => setData({...data, text_between_line_size: e.target.value})}/>
                   <label>Line Colour</label>
                   <SketchPicker onChange={(color) => { setTextBetweenLineColor(color.rgb); setData({...data, text_between_line_color: color.rgb}); }} color={text_between_line_color} />
                   <br></br><br></br>
                   <label>Space Top</label>
                   <input type="number" value={data.text_between_line_space_top} onChange={(e) => setData({...data, text_between_line_space_top: e.target.value})}/>
                   <label>Space Bottom</label>
                   <input type="number" value={data.text_between_line_space_bottom} onChange={(e) => setData({...data, text_between_line_space_bottom: e.target.value})}/>
                 </FormField>
                 : ''
               }

               <FormField>
                 <Switch>
                   { data.second_add_text_between_line === 1 ? 
                     <input type="checkbox" checked onChange={(e) => setData({...data, second_add_text_between_line: 0})}/>
                     : 
                     <input type="checkbox" onChange={(e) => setData({...data, second_add_text_between_line: 1})}/>
                   }
                   <label>Insert Word in the Middle of Horizontal Line (Two)</label>
                 </Switch>
               </FormField>

               { data.second_add_text_between_line === 1 ?
                 <FormField>
                   <label>Words</label>
                   <input type="text" value={data.second_text_between_line_word} onChange={(e) => setData({...data, second_text_between_line_word: e.target.value})}/>
                   <label>Word Font Size</label>
                   <input type="number" value={data.second_text_between_line_word_font_size} onChange={(e) => setData({...data, second_text_between_line_word_font_size: e.target.value})}/>
                   <label>Word Font Weight</label>
                   <input type="number" value={data.second_text_between_line_word_font_weight} onChange={(e) => setData({...data, second_text_between_line_word_font_weight: e.target.value})}/>
                   <label>Word Format</label>
                   <select onChange={(e) => setData({...data, second_text_between_line_word_format: e.target.value})}>
                     <option>Select Format</option>
                     <option value="regular">Regular</option>
                     <option value="italic">Italic</option>
                   </select>
                   <label>Word Colour</label>
                   <SketchPicker onChange={(color) => { setSecondTextBetweenLineWordColor(color.rgb); setData({...data, second_text_between_line_word_color: color.rgb}); }} color={second_text_between_line_word_color} />
                   <br></br><br></br>
                   <label>Line Size</label>
                   <input type="number" value={data.second_text_between_line_size} onChange={(e) => setData({...data, second_text_between_line_size: e.target.value})}/>
                   <label>Line Colour</label>
                   <SketchPicker onChange={(color) => { setSecondTextBetweenLineColor(color.rgb); setData({...data, second_text_between_line_color: color.rgb}); }} color={second_text_between_line_color} />
                   <br></br><br></br>
                   <label>Space Top</label>
                   <input type="number" value={data.second_text_between_line_space_top} onChange={(e) => setData({...data, second_text_between_line_space_top: e.target.value})}/>
                   <label>Space Bottom</label>
                   <input type="number" value={data.second_text_between_line_space_bottom} onChange={(e) => setData({...data, second_text_between_line_space_bottom: e.target.value})}/>
                 </FormField>
                 : ''
               }

               <FormField>
                 <Switch>
                   { data.third_add_text_between_line === 1 ? 
                     <input type="checkbox" checked onChange={(e) => setData({...data, third_add_text_between_line: 0})}/>
                     : 
                     <input type="checkbox" onChange={(e) => setData({...data, third_add_text_between_line: 1})}/>
                   }
                   <label>Insert Word in the Middle of Horizontal Line (Third)</label>
                 </Switch>
               </FormField>

               { data.third_add_text_between_line === 1 ?
                 <FormField>
                   <label>Words</label>
                   <input type="text" value={data.third_text_between_line_word} onChange={(e) => setData({...data, third_text_between_line_word: e.target.value})}/>
                   <label>Word Font Size</label>
                   <input type="number" value={data.third_text_between_line_word_font_size} onChange={(e) => setData({...data, third_text_between_line_word_font_size: e.target.value})}/>
                   <label>Word Font Weight</label>
                   <input type="number" value={data.third_text_between_line_word_font_weight} onChange={(e) => setData({...data, third_text_between_line_word_font_weight: e.target.value})}/>
                   <label>Word Format</label>
                   <select onChange={(e) => setData({...data, third_text_between_line_word_format: e.target.value})}>
                     <option>Select Format</option>
                     <option value="regular">Regular</option>
                     <option value="italic">Italic</option>
                   </select>
                   <label>Word Colour</label>
                   <SketchPicker onChange={(color) => { setThirdTextBetweenLineWordColor(color.rgb); setData({...data, third_text_between_line_word_color: color.rgb}); }} color={third_text_between_line_word_color} />
                   <br></br><br></br>
                   <label>Line Size</label>
                   <input type="number" value={data.third_text_between_line_size} onChange={(e) => setData({...data, third_text_between_line_size: e.target.value})}/>
                   <label>Line Colour</label>
                   <SketchPicker onChange={(color) => { setThirdTextBetweenLineColor(color.rgb); setData({...data, third_text_between_line_color: color.rgb}); }} color={third_text_between_line_color} />
                   <br></br><br></br>
                   <label>Space Top</label>
                   <input type="number" value={data.third_text_between_line_space_top} onChange={(e) => setData({...data, third_text_between_line_space_top: e.target.value})}/>
                   <label>Space Bottom</label>
                   <input type="number" value={data.third_text_between_line_space_bottom} onChange={(e) => setData({...data, third_text_between_line_space_bottom: e.target.value})}/>
                 </FormField>
                 : ''
               }

               <FormField>
                 <Switch>
                   { data.fourth_add_text_between_line === 1 ? 
                     <input type="checkbox" checked onChange={(e) => setData({...data, fourth_add_text_between_line: 0})}/>
                     : 
                     <input type="checkbox" onChange={(e) => setData({...data, fourth_add_text_between_line: 1})}/>
                   }
                   <label>Insert Word in the Middle of Horizontal Line (Fourth)</label>
                 </Switch>
               </FormField>

               { data.fourth_add_text_between_line === 1 ?
                 <FormField>
                   <label>Words</label>
                   <input type="text" value={data.fourth_text_between_line_word} onChange={(e) => setData({...data, fourth_text_between_line_word: e.target.value})}/>
                   <label>Word Font Size</label>
                   <input type="number" value={data.fourth_text_between_line_word_font_size} onChange={(e) => setData({...data, fourth_text_between_line_word_font_size: e.target.value})}/>
                   <label>Word Font Weight</label>
                   <input type="number" value={data.fourth_text_between_line_word_font_weight} onChange={(e) => setData({...data, fourth_text_between_line_word_font_weight: e.target.value})}/>
                   <label>Word Format</label>
                   <select onChange={(e) => setData({...data, fourth_text_between_line_word_format: e.target.value})}>
                     <option>Select Format</option>
                     <option value="regular">Regular</option>
                     <option value="italic">Italic</option>
                   </select>
                   <label>Word Colour</label>
                   <SketchPicker onChange={(color) => { setFourthTextBetweenLineWordColor(color.rgb); setData({...data, fourth_text_between_line_word_color: color.rgb}); }} color={fourth_text_between_line_word_color} />
                   <br></br><br></br>
                   <label>Line Size</label>
                   <input type="number" value={data.fourth_text_between_line_size} onChange={(e) => setData({...data, fourth_text_between_line_size: e.target.value})}/>
                   <label>Line Colour</label>
                   <SketchPicker onChange={(color) => { setFourthTextBetweenLineColor(color.rgb); setData({...data, fourth_text_between_line_color: color.rgb}); }} color={fourth_text_between_line_color} />
                   <br></br><br></br>
                   <label>Space Top</label>
                   <input type="number" value={data.fourth_text_between_line_space_top} onChange={(e) => setData({...data, fourth_text_between_line_space_top: e.target.value})}/>
                   <label>Space Bottom</label>
                   <input type="number" value={data.fourth_text_between_line_space_bottom} onChange={(e) => setData({...data, fourth_text_between_line_space_bottom: e.target.value})}/>
                 </FormField>
                 : ''
               }

               <FormField>
                 <Switch>
                   { data.add_custom_button === 1 ? 
                     <input type="checkbox" checked onChange={(e) => setData({...data, add_custom_button: 0})}/>
                     : 
                     <input type="checkbox" onChange={(e) => setData({...data, add_custom_button: 1})}/>
                   }
                   <label>Insert Custom Button (One)</label>
                 </Switch>
               </FormField>

               { data.add_custom_button === 1 ?
                 <FormField>
                   <label>Button Text</label>
                   <div className="header_title">
                     <input type="text" value={data.custom_button_text} onChange={(e) => setData({...data, custom_button_text: e.target.value})}/>
                     <span className="customize" onClick={() => setCustomize({...customize, modal: true, content: 'custom-button'})} title="Styles"><SettingsIcon/></span>
                   </div>
                   <label>Button Link</label>
                   <input type="text" value={data.custom_button_link} onChange={(e) => setData({...data, custom_button_link: e.target.value})} placeholder="Enter button link"/>
                   <br></br>
                   <label>Space Top</label>
                   <input type="number" value={data.custom_button_margin_top} onChange={(e) => setData({...data, custom_button_margin_top: e.target.value})}/>
                   <label>Space Bottom</label>
                   <input type="number" value={data.custom_button_margin_bottom} onChange={(e) => setData({...data, custom_button_margin_bottom: e.target.value})}/>
                 </FormField>
                 : ''
               }

              <FormField>
                 <Switch>
                   { data.second_add_custom_button === 1 ? 
                     <input type="checkbox" checked onChange={(e) => setData({...data, second_add_custom_button: 0})}/>
                     : 
                     <input type="checkbox" onChange={(e) => setData({...data, second_add_custom_button: 1})}/>
                   }
                   <label>Insert Custom Button (Two)</label>
                 </Switch>
               </FormField>

               { data.second_add_custom_button === 1 ?
                 <FormField>
                   <label>Button Text (Two)</label>
                   <div className="header_title">
                     <input type="text" value={data.second_custom_button_text} onChange={(e) => setData({...data, second_custom_button_text: e.target.value})}/>
                     <span className="customize" onClick={() => setCustomize({...customize, modal: true, content: 'custom-button-second'})} title="Styles"><SettingsIcon/></span>
                   </div>
                   <label>Button Link</label>
                   <input type="text" value={data.second_custom_button_link} onChange={(e) => setData({...data, second_custom_button_link: e.target.value})} placeholder="Enter button link"/>
                   <br></br>
                   <label>Space Top</label>
                   <input type="number" value={data.second_custom_button_margin_top} onChange={(e) => setData({...data, second_custom_button_margin_top: e.target.value})}/>
                   <label>Space Bottom</label>
                   <input type="number" value={data.second_custom_button_margin_bottom} onChange={(e) => setData({...data, second_custom_button_margin_bottom: e.target.value})}/>
                 </FormField>
                 : ''
               }

               <FormField>
                 <Switch>
                   { data.add_payment_button === 1 ? 
                     <input type="checkbox" checked onChange={(e) => setData({...data, add_payment_button: 0})}/>
                     : 
                     <input type="checkbox" onChange={(e) => setData({...data, add_payment_button: 1})}/>
                   }
                   <label>Insert Product(s) & Shopping Cart</label>
                 </Switch>
               </FormField>

               { data.add_payment_button === 1 ?
                <>  
                <SwitchSmall>
                   { data.payment_button_title === 1 ? 
                     <input type="checkbox" checked onChange={(e) => setData({...data, payment_button_title: 0})}/>
                     : 
                     <input type="checkbox" onChange={(e) => setData({...data, payment_button_title: 1})}/>
                   }
                     <label>Show Block Title</label>
                   </SwitchSmall>
                   { data.payment_button_title === 1 ? 
                     <div className="header_title">
                       <input type="text" value={data.payment_button_title_label} onChange={(e) => setData({...data, payment_button_title_label: e.target.value})} required placeholder="Enter title"/>
                       <span className="customize" onClick={() => setCustomize({...customize, modal: true, content: 'products-title'})} title="Styles"><SettingsIcon/></span>
                     </div> 
                     : ''
                   }
                <div id="product">
                   { data.products.map((product,index) => 
                      <div className="product-section">
                        <label>Product Name</label><br></br>
                        <input type="text" value={product.title} onChange={(e) =>{ const newTitleP = e.target.value; updateProductTitle(index, newTitleP); }}/>
                        <label>Product Price</label><br></br>
                        <input type="number" value={product.price} onChange={(e) =>{ const newPrice = e.target.value; updateProductPrice(index, newPrice); }}/>
                        <label>Product Description</label><br></br>
                        <textarea value={product.description} onChange={(e) =>{ const newDescriptionP = e.target.value; updateProductDescription(index, newDescriptionP); }} />                       
                        <br></br>
                        <label>Upload Product Image</label><br></br>
                        <input accept="image/*" type="file" onChange={(e) => handleProductUpload(index,e)} />
                        { product_upload === 1 ? <span style={{fontSize: '14px'}}>Uploading...<br></br></span> : '' }
                        { product.product_image !== '' ?
                          <div className="product_pic_view">
                           <img src={'https://nfcleads.au/nodeapi/uploads/' + product.product_image} style={{width: '100%'}}/>
                           <span className="remove-product-icon" onClick={(e) => removeProductImage(index)}><CloseRoundedIcon/></span>
                          </div>
                          : ''
                        } 
                        <button type="button" className="remove-product" onClick={() => removeFromProducts(index)}>Remove Product</button>
                      </div>) 
                    }
                  <FormField>
                    <br></br>
                    <span onClick={addNewproduct} style={{fontSize: '13px', fontWeight: '500', cursor: 'pointer'}}><AddIcon style={{background: '#ff9800', color: '#fff', borderRadius: '50%', padding: '2px', fontSize: '20px', cursor: 'pointer', verticalAlign: 'bottom', marginRight: '3px'}} /> Add New Product</span>
                   </FormField>
                 </div>
              
                 <FormField>
                   <label>Add To Cart Button Text</label>
                   <div className="header_title">
                     <input type="text" value={data.payment_button_text} onChange={(e) => setData({...data, payment_button_text: e.target.value})}/>
                     <span className="customize" onClick={() => setCustomize({...customize, modal: true, content: 'payment-button'})} title="Styles"><SettingsIcon/></span>
                   </div>
                   <br></br>
                   <SwitchSmall>
                   { data.paypal_live_mode === 1 ? 
                     <input type="checkbox" checked onChange={(e) => setData({...data, paypal_live_mode: 0})}/>
                     : 
                     <input type="checkbox" onChange={(e) => setData({...data, paypal_live_mode: 1})}/>
                   }
                     <label>Paypal Live Business Account/Paypal Sandbox Business Account</label>
                   </SwitchSmall>
                   { data.paypal_live_mode === 1 ? 
                       <input type="text" value={data.paypal_business_account_live} onChange={(e) => setData({...data, paypal_business_account_live: e.target.value})} required placeholder="Paypal Live Business Account"/>
                     : ''
                   }
                   { data.paypal_live_mode === 0 ? 
                       <input type="text" value={data.paypal_business_account_sandbox} onChange={(e) => setData({...data, paypal_business_account_sandbox: e.target.value})} required placeholder="Paypal Sandbox Account"/>
                     : ''
                   }
                   <label>Space Top</label>
                   <input type="number" value={data.second_custom_button_margin_top} onChange={(e) => setData({...data, second_custom_button_margin_top: e.target.value})}/>
                   <label>Space Bottom</label>
                   <input type="number" value={data.second_custom_button_margin_bottom} onChange={(e) => setData({...data, second_custom_button_margin_bottom: e.target.value})}/>
                 </FormField>
                 </>
                 : ''
               }

               <FormField>
                 <Switch>
                   { data.appointment === 1 ? 
                     <input type="checkbox" checked onChange={(e) => setData({...data, appointment: 0})}/>
                     : 
                     <input type="checkbox" onChange={(e) => setData({...data, appointment: 1})}/>
                   }
                   <label>Insert Appointment Widget</label>
                 </Switch>
               </FormField>

               { data.appointment === 1 ?
               <FormField>
                  <SwitchSmall>
                     { data.appointment_title === 1 ? 
                       <input type="checkbox" checked onChange={(e) => setData({...data, appointment_title: 0})}/>
                       : 
                       <input type="checkbox" onChange={(e) => setData({...data, appointment_title: 1})}/>
                     }
                     <label>Show Appointment Widget Title</label>
                     </SwitchSmall>
                     { data.appointment_title === 1 ? 
                       <div className="header_title">
                         <input type="text" value={data.appointment_title_label} onChange={(e) => setData({...data, appointment_title_label: e.target.value})} required placeholder="Enter appointment title"/>
                         <span className="customize" onClick={() => setCustomize({...customize, modal: true, content: 'appointment-title'})} title="Styles"><SettingsIcon/></span>
                       </div> 
                       : ''
                     }
                  <label>Email Recipient</label>
                  <input type="text" value={data.appointment_email_recipient} onChange={(e) => setData({...data, appointment_email_recipient: e.target.value})} placeholder="Enter recipient email id"/>
                  <br></br>   
                  <label>Space Top</label>
                  <input type="number" value={data.appointment_padding_top} onChange={(e) => setData({...data, appointment_padding_top: e.target.value})}/>
                  <label>Space Bottom</label>
                  <input type="number" value={data.appointment_padding_bottom} onChange={(e) => setData({...data, appointment_padding_bottom: e.target.value})}/> 
               </FormField>
               : ''
               }

               <FormField>
                 <Switch>
                   { data.contactForm === 1 ? 
                     <input type="checkbox" checked onChange={(e) => setData({...data, contactForm: 0})}/>
                     : 
                     <input type="checkbox" onChange={(e) => setData({...data, contactForm: 1})}/>
                   }
                   <label>Insert Contact Form</label>
                 </Switch>
               </FormField>

              { data.contactForm === 1 ?
               <FormField>
                  <SwitchSmall>
                     { data.contactForm_title === 1 ? 
                       <input type="checkbox" checked onChange={(e) => setData({...data, contactForm_title: 0})}/>
                       : 
                       <input type="checkbox" onChange={(e) => setData({...data, contactForm_title: 1})}/>
                     }
                     <label>Show Contact Form Title</label>
                     </SwitchSmall>
                     { data.contactForm_title === 1 ? 
                       <div className="header_title">
                         <input type="text" value={data.contactForm_title_label} onChange={(e) => setData({...data, contactForm_title_label: e.target.value})} required placeholder="Enter contact form title"/>
                         <span className="customize" onClick={() => setCustomize({...customize, modal: true, content: 'contactForm-title'})} title="Styles"><SettingsIcon/></span>
                       </div> 
                       : ''
                     }
                  <label>Email Recipient</label>
                  <input type="text" value={data.contactForm_email_recipient} onChange={(e) => setData({...data, contactForm_email_recipient: e.target.value})} placeholder="Enter recipient email id"/>
                  <br></br>
                  <label>Space Top</label>
                  <input type="number" value={data.contact_form_padding_top} onChange={(e) => setData({...data, contact_form_padding_top: e.target.value})}/>
                  <label>Space Bottom</label>
                  <input type="number" value={data.contact_form_padding_bottom} onChange={(e) => setData({...data, contact_form_padding_bottom: e.target.value})}/> 
               </FormField>
               : ''
              }

              { data.contactForm === 1 || data.appointment === 1 ?
                  <div id="email_configuration">
                    <label>Host Server</label>
                    <input type="text" value={data.contactForm_email_host} onChange={(e) => setData({...data, contactForm_email_host: e.target.value})} placeholder="Enter host server"/>
                    <label>Username</label>
                    <input type="text" value={data.contactForm_email_username} onChange={(e) => setData({...data, contactForm_email_username: e.target.value})} placeholder="Enter username"/>
                    <label>Password</label>
                    <input type="text" value={data.contactForm_email_password} onChange={(e) => setData({...data, contactForm_email_password: e.target.value})} placeholder="Enter password"/>
                  </div>
                : ''
              }
             </Card>  
              
<br></br>
<Captcha onChange={handleCaptchaChange} />
<br></br>
        <SubmitButton type="button" onClick={saveCard}>
          {editStatus === "pending" ? "Saving..." : "Update"}
        </SubmitButton>
      </StyledForm>
        </EditView>

        <MobilePreview>
          <div className={'screen' + (scrollLimit === true ? ' scroll' : '')}>
            <Wrapper>
              { template === 1 ? 
                <TemplateOne info={data} />
                : ''
              }
              { template === 2 ? 
                <TemplateTwo info={data} />
                : ''
              }
            </Wrapper>
          </div>
        </MobilePreview>
      </TwoColumn>
    </StyledEditCard>

         <Footer>
          <p><a href="/"><CopyrightIcon/> 2023. nfcleads.au</a></p>
         </Footer>
      </Content>
     </StyledDashboard>


    { preview === true ?
      <Preview>
       <div style={{minHeight: '100%', background: `${body_background}`}}>
        <CloseRoundedIcon onClick={() => setPreview(false)} style={{position: 'fixed', top: '10px', left: '10px', background: 'red', color: '#fff', borderRadius: '50%', fontSize: '32px', padding: '2px', cursor: 'pointer'}}/>
        <div style={{maxWidth: '30rem', margin: '0 auto'}}>
          <PreviewTemplate info={data} />
        </div>
       </div> 
      </Preview>
      : ''
    }

    { customize.modal === true ?
      <CustomizePreview>
       <div style={{minHeight: '100%', backgroundColor: 'rgb(22 22 22 / 80%)', paddingTop: '20px'}}>
        <CloseRoundedIcon onClick={() => setCustomize({...customize, modal: false, content: ''})} style={{position: 'fixed', top: '10px', right: '10px', background: 'red', color: '#fff', borderRadius: '50%', fontSize: '32px', padding: '2px', cursor: 'pointer'}}/>
        <div style={{maxWidth: customize.content === 'table-styles' || customize.content === 'custom-button' || customize.content === 'custom-button-second' || customize.content === 'payment-button' ? '47rem' : '34rem', margin: '0 auto', padding: '20px', background: '#f6f6f6', height: '100%'}}>
          { customize.content === 'name' ? 
            <>
              <h4>Customize name field</h4><br></br>
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Size</label><br></br>
                <input type="number" value={data.font_size_name} onChange={(e) => setData({...data, font_size_name: e.target.value})} />
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                <label>Font Weight</label>
                <input type="number" value={data.font_weight_name} onChange={(e) => setData({...data, font_weight_name: e.target.value})} />
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                <label>Space Top</label>
                <input type="number" value={data.name_margin_top} onChange={(e) => setData({...data, name_margin_top: e.target.value})} />
              </FormField>
                </div>
              </Row> 

              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Style</label>
                <select value={data.font_style_name} onChange={(e) => setData({...data, font_style_name: e.target.value})}>
                  <option value="normal">Normal</option>
                  <option value="italic">Italic</option>
                </select>
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                <label>Font Underline</label>
                <select value={data.font_underline_name} onChange={(e) => setData({...data, font_underline_name: e.target.value})}>
                  <option value="none">No</option>
                  <option value="underline">Yes</option>
                </select>
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Alignment</label>
                   <select value={data.name_text_align} onChange={(e) => setData({...data, name_text_align: e.target.value})}>
                     <option value="center">Center</option>
                     <option value="left">Left</option>
                     <option value="right">Right</option>
                     <option value="justify">Justify</option>
                   </select>
                 </FormField> 
                </div>
              </Row> 

              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Colour</label><br></br>
                <SketchPicker onChange={(color) => { setNameColor(color.rgb); setData({...data, name_color: color.rgb}); }} color={name_color} />
              </FormField>
                </div>
              </Row>  
            </>
            :
             ''
          }

          { customize.content === 'address' ? 
            <>
              <h4>Customize address field</h4><br></br>

              <Row>
                <div className="column-popup">
                 <FormField>
                <label>Font Size</label><br></br>
                <input type="number" value={data.font_size_address} onChange={(e) => setData({...data, font_size_address: e.target.value})} />
              </FormField> 
                </div>
                <div className="column-popup">
                  <FormField>
                <label>Font Weight</label>
                <input type="number" value={data.font_weight_address} onChange={(e) => setData({...data, font_weight_address: e.target.value})} />
              </FormField>
                </div>
              </Row> 

              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Style</label>
                <select value={data.font_style_address} onChange={(e) => setData({...data, font_style_address: e.target.value})}>
                  <option value="normal">Normal</option>
                  <option value="italic">Italic</option>
                </select>
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                <label>Font Underline</label>
                <select value={data.font_underline_address} onChange={(e) => setData({...data, font_underline_address: e.target.value})}>
                  <option value="none">No</option>
                  <option value="underline">Yes</option>
                </select>
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Alignment</label>
                   <select value={data.address_text_align} onChange={(e) => setData({...data, address_text_align: e.target.value})}>
                     <option value="center">Center</option>
                     <option value="left">Left</option>
                     <option value="right">Right</option>
                     <option value="justify">Justify</option>
                   </select>
                 </FormField> 
                </div>
              </Row> 

              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Colour</label><br></br>
                <SketchPicker onChange={(color) => { setAddressColor(color.rgb); setData({...data, address_color: color.rgb}); }} color={address_color} />
              </FormField>
                </div>
              </Row> 
            </>
            :
             ''
          }

          { customize.content === 'profession' ? 
            <>
              <h4>Customize profession field</h4><br></br>
              <Row>
                <div className="column-popup">
                  <FormField>
                   <label>Font Size</label><br></br>
                   <input type="number" value={data.font_size_jobtitle} onChange={(e) => setData({...data, font_size_jobtitle: e.target.value})} />
                  </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Weight</label>
                   <input type="number" value={data.font_weight_jobtitle} onChange={(e) => setData({...data, font_weight_jobtitle: e.target.value})} />
                  </FormField>
                </div>
              </Row>

              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Style</label>
                <select value={data.font_style_jobtitle} onChange={(e) => setData({...data, font_style_jobtitle: e.target.value})}>
                  <option value="normal">Normal</option>
                  <option value="italic">Italic</option>
                </select>
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                <label>Font Underline</label>
                <select value={data.font_underline_jobtitle} onChange={(e) => setData({...data, font_underline_jobtitle: e.target.value})}>
                  <option value="none">No</option>
                  <option value="underline">Yes</option>
                </select>
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Alignment</label>
                   <select value={data.jobtitle_text_align} onChange={(e) => setData({...data, jobtitle_text_align: e.target.value})}>
                     <option value="center">Center</option>
                     <option value="left">Left</option>
                     <option value="right">Right</option>
                     <option value="justify">Justify</option>
                   </select>
                 </FormField> 
                </div>
              </Row>  

              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Colour</label><br></br>
                <SketchPicker onChange={(color) => { setJobtitleColor(color.rgb); setData({...data, jobtitle_color: color.rgb}); }} color={jobtitle_color} />
              </FormField>
                </div>
              </Row> 
            </>
            :
             ''
          }

          { customize.content === 'company' ? 
            <>
              <h4>Customize company field</h4><br></br>

              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Size</label><br></br>
                <input type="number" value={data.font_size_company} onChange={(e) => setData({...data, font_size_company: e.target.value})} />
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                <label>Font Weight</label>
                <input type="number" value={data.font_weight_company} onChange={(e) => setData({...data, font_weight_company: e.target.value})} />
              </FormField>
                </div>
              </Row> 

              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Style</label>
                <select value={data.font_style_company} onChange={(e) => setData({...data, font_style_company: e.target.value})}>
                  <option value="normal">Normal</option>
                  <option value="italic">Italic</option>
                </select>
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                <label>Font Underline</label>
                <select value={data.font_underline_company} onChange={(e) => setData({...data, font_underline_company: e.target.value})}>
                  <option value="none">No</option>
                  <option value="underline">Yes</option>
                </select>
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Alignment</label>
                   <select value={data.company_text_align} onChange={(e) => setData({...data, company_text_align: e.target.value})}>
                     <option value="center">Center</option>
                     <option value="left">Left</option>
                     <option value="right">Right</option>
                     <option value="justify">Justify</option>
                   </select>
                 </FormField> 
                </div>
              </Row> 

              <Row>
                <div className="column-popup">
                   <FormField>
                <label>Font Colour</label><br></br>
                <SketchPicker onChange={(color) => { setCompanyColor(color.rgb); setData({...data, company_color: color.rgb}); }} color={company_color} />
              </FormField>
                </div>
              </Row> 
            </>
            :
             ''
          }

          { customize.content === 'description' ? 
            <>
              <h4>Customize description field</h4><br></br>

              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Size</label><br></br>
                <input type="number" value={data.font_size_description} onChange={(e) => setData({...data, font_size_description: e.target.value})} />
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                <label>Font Weight</label>
                <input type="number" value={data.font_weight_description} onChange={(e) => setData({...data, font_weight_description: e.target.value})} />
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                <label>Line Height</label>
                <input type="number" value={data.description_line_height} onChange={(e) => setData({...data, description_line_height: e.target.value})} />
              </FormField>
                </div>
              </Row> 

              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Style</label>
                <select value={data.font_style_description} onChange={(e) => setData({...data, font_style_description: e.target.value})}>
                  <option value="normal">Normal</option>
                  <option value="italic">Italic</option>
                </select>
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                <label>Font Underline</label>
                <select value={data.font_underline_description} onChange={(e) => setData({...data, font_underline_description: e.target.value})}>
                  <option value="none">No</option>
                  <option value="underline">Yes</option>
                </select>
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Alignment</label>
                   <select value={data.description_text_align} onChange={(e) => setData({...data, description_text_align: e.target.value})}>
                     <option value="center">Center</option>
                     <option value="left">Left</option>
                     <option value="right">Right</option>
                     <option value="justify">Justify</option>
                   </select>
                 </FormField> 
                </div>
              </Row> 
              <Row>
                <div className="column-popup">
                 <FormField>
                  <label>Space Top</label><br></br>
                   <input type="number" value={data.description_padding_top} onChange={(e) => setData({...data, description_padding_top: e.target.value})} />
                 </FormField> 
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Space Bottom</label>
                   <input type="number" value={data.description_padding_bottom} onChange={(e) => setData({...data, description_padding_bottom: e.target.value})} />
                 </FormField> 
                </div>
              </Row> 
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Colour</label><br></br>
                <SketchPicker onChange={(color) => { setDescriptionColor(color.rgb); setData({...data, company_color: color.rgb}); }} color={description_color} />
              </FormField>
                </div>
              </Row> 
            </>
            :
             ''
          }

          { customize.content === 'contact' ? 
            <>
              <h4>Adjust Space - Contact Details</h4><br></br>
              <Row>
                <div className="column-popup">
                  <FormField>
                   <label>Space Top</label><br></br>
                   <input type="number" value={data.contact_details_padding_top} onChange={(e) => setData({...data, contact_details_padding_top: e.target.value})} />
                   <br></br><br></br>
                   <label>Space Bottom</label>
                   <input type="number" value={data.contact_details_padding_bottom} onChange={(e) => setData({...data, contact_details_padding_bottom: e.target.value})} />
                  </FormField>
                </div> 
              </Row>
            </>
            : 
            ''
          }

          { customize.content === 'audio-title' ? 
            <>
              <h4>Customize audio title field</h4><br></br>
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Size</label><br></br>
                <input type="number" value={data.font_size_audio_title} onChange={(e) => setData({...data, font_size_audio_title: e.target.value})} />
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Weight</label>
                   <input type="number" value={data.font_weight_audio_title} onChange={(e) => setData({...data, font_weight_audio_title: e.target.value})} />
                  </FormField>
                </div>
              </Row> 
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Style</label>
                <select value={data.font_style_audio_title} onChange={(e) => setData({...data, font_style_audio_title: e.target.value})}>
                  <option value="normal">Normal</option>
                  <option value="italic">Italic</option>
                </select>
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Underline</label>
                    <select value={data.font_underline_audio_title} onChange={(e) => setData({...data, font_underline_audio_title: e.target.value})}>
                     <option value="none">No</option>
                     <option value="underline">Yes</option>
                   </select>
                  </FormField>
                </div>
              </Row> 
              <Row>
                <div className="column-popup">
                 <FormField>
                  <label>Space Top</label><br></br>
                   <input type="number" value={data.audio_title_padding_top} onChange={(e) => setData({...data, audio_title_padding_top: e.target.value})} />
                 </FormField> 
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Space Bottom</label>
                   <input type="number" value={data.audio_title_padding_bottom} onChange={(e) => setData({...data, audio_title_padding_bottom: e.target.value})} />
                 </FormField> 
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Alignment</label>
                   <select onChange={(e) => setData({...data, audio_title_text_align: e.target.value})}>
                     <option value="center">Center</option>
                     <option value="left">Left</option>
                     <option value="right">Right</option>
                     <option value="justify">Justify</option>
                   </select>
                 </FormField> 
                </div>
              </Row> 
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Colour</label><br></br>
                <SketchPicker onChange={(color) => { setAudioTitleColor(color.rgb); setData({...data, audio_title_color: color.rgb}); }} color={audio_title_color} />
              </FormField>
                </div>
              </Row> 
            </>
            :
             ''
          }

          { customize.content === 'video-title' ? 
            <>
              <h4>Customize video title field</h4><br></br>
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Size</label><br></br>
                <input type="number" value={data.font_size_video_title} onChange={(e) => setData({...data, font_size_video_title: e.target.value})} />
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Weight</label>
                   <input type="number" value={data.font_weight_video_title} onChange={(e) => setData({...data, font_weight_video_title: e.target.value})} />
                  </FormField>
                </div>
              </Row> 
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Style</label>
                <select value={data.font_style_video_title} onChange={(e) => setData({...data, font_style_video_title: e.target.value})}>
                  <option value="normal">Normal</option>
                  <option value="italic">Italic</option>
                </select>
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Underline</label>
                    <select value={data.font_underline_video_title} onChange={(e) => setData({...data, font_underline_video_title: e.target.value})}>
                     <option value="none">No</option>
                     <option value="underline">Yes</option>
                   </select>
                  </FormField>
                </div>
              </Row> 
              <Row>
                <div className="column-popup">
                 <FormField>
                  <label>Space Top</label><br></br>
                   <input type="number" value={data.video_title_padding_top} onChange={(e) => setData({...data, video_title_padding_top: e.target.value})} />
                 </FormField> 
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Space Bottom</label>
                   <input type="number" value={data.video_title_padding_bottom} onChange={(e) => setData({...data, video_title_padding_bottom: e.target.value})} />
                 </FormField> 
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Alignment</label>
                   <select value={data.video_title_text_align} onChange={(e) => setData({...data, video_title_text_align: e.target.value})}>
                     <option value="center">Center</option>
                     <option value="left">Left</option>
                     <option value="right">Right</option>
                     <option value="justify">Justify</option>
                   </select>
                 </FormField> 
                </div>
              </Row> 
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Colour</label><br></br>
                <SketchPicker onChange={(color) => { setVideoTitleColor(color.rgb); setData({...data, video_title_color: color.rgb}); }} color={video_title_color} />
              </FormField>
                </div>
              </Row> 
            </>
            :
             ''
          }

          { customize.content === 'testimonial-title' ? 
            <>
              <h4>Customize testimonial title field</h4><br></br>
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Size</label><br></br>
                <input type="number" value={data.font_size_testimonial_title} onChange={(e) => setData({...data, font_size_testimonial_title: e.target.value})} />
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Weight</label>
                   <input type="number" value={data.font_weight_testimonial_title} onChange={(e) => setData({...data, font_weight_testimonial_title: e.target.value})} />
                  </FormField>
                </div>
              </Row> 
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Style</label>
                <select value={data.font_style_testimonial_title} onChange={(e) => setData({...data, font_style_testimonial_title: e.target.value})}>
                  <option value="normal">Normal</option>
                  <option value="italic">Italic</option>
                </select>
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Underline</label>
                    <select value={data.font_underline_testimonial_title} onChange={(e) => setData({...data, font_underline_testimonial_title: e.target.value})}>
                     <option value="none">No</option>
                     <option value="underline">Yes</option>
                   </select>
                  </FormField>
                </div>
              </Row> 
              <Row>
                <div className="column-popup">
                 <FormField>
                  <label>Space Top</label><br></br>
                   <input type="number" value={data.testimonial_title_padding_top} onChange={(e) => setData({...data, testimonial_title_padding_top: e.target.value})} />
                 </FormField> 
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Space Bottom</label>
                   <input type="number" value={data.testimonial_title_padding_bottom} onChange={(e) => setData({...data, testimonial_title_padding_bottom: e.target.value})} />
                 </FormField> 
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Alignment</label>
                   <select value={data.testimonial_title_text_align} onChange={(e) => setData({...data, testimonial_title_text_align: e.target.value})}>
                     <option value="center">Center</option>
                     <option value="left">Left</option>
                     <option value="right">Right</option>
                     <option value="justify">Justify</option>
                   </select>
                 </FormField> 
                </div>
              </Row> 
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Colour</label><br></br>
                <SketchPicker onChange={(color) => { setTestimonialTitleColor(color.rgb); setData({...data, testimonial_title_color: color.rgb}); }} color={testimonial_title_color} />
              </FormField>
                </div>
              </Row> 
            </>
            :
             ''
          }

          { customize.content === 'map-title' ? 
            <>
              <h4>Customize map title field</h4><br></br>
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Size</label><br></br>
                <input type="number" value={data.font_size_map_title} onChange={(e) => setData({...data, font_size_map_title: e.target.value})} />
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Weight</label>
                   <input type="number" value={data.font_weight_map_title} onChange={(e) => setData({...data, font_weight_map_title: e.target.value})} />
                  </FormField>
                </div>
              </Row> 
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Style</label>
                <select value={data.font_style_map_title} onChange={(e) => setData({...data, font_style_map_title: e.target.value})}>
                  <option value="normal">Normal</option>
                  <option value="italic">Italic</option>
                </select>
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Underline</label>
                    <select value={data.font_underline_map_title} onChange={(e) => setData({...data, font_underline_map_title: e.target.value})}>
                     <option value="none">No</option>
                     <option value="underline">Yes</option>
                   </select>
                  </FormField>
                </div>
              </Row> 
              <Row>
                <div className="column-popup">
                 <FormField>
                  <label>Space Top</label><br></br>
                   <input type="number" value={data.map_title_padding_top} onChange={(e) => setData({...data, map_title_padding_top: e.target.value})} />
                 </FormField> 
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Space Bottom</label>
                   <input type="number" value={data.map_title_padding_bottom} onChange={(e) => setData({...data, map_title_padding_bottom: e.target.value})} />
                 </FormField> 
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Alignment</label>
                   <select value={data.map_title_text_align} onChange={(e) => setData({...data, map_title_text_align: e.target.value})}>
                     <option value="center">Center</option>
                     <option value="left">Left</option>
                     <option value="right">Right</option>
                     <option value="justify">Justify</option>
                   </select>
                 </FormField> 
                </div>
              </Row> 
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Colour</label><br></br>
                <SketchPicker onChange={(color) => { setMapTitleColor(color.rgb); setData({...data, map_title_color: color.rgb}); }} color={map_title_color} />
              </FormField>
                </div>
              </Row> 
            </>
            :
             ''
          }

           { customize.content === 'products-title' ? 
            <>
              <h4>Customize block title</h4><br></br>
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Size</label><br></br>
                <input type="number" value={data.font_size_payment_button_title} onChange={(e) => setData({...data, font_size_payment_button_title: e.target.value})} />
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Weight</label>
                   <input type="number" value={data.font_weight_payment_button_title} onChange={(e) => setData({...data, font_weight_payment_button_title: e.target.value})} />
                  </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Underline</label>
                    <select value={data.font_underline_payment_button_title} onChange={(e) => setData({...data, font_underline_payment_button_title: e.target.value})}>
                     <option value="none">No</option>
                     <option value="underline">Yes</option>
                   </select>
                  </FormField>
                </div>
              </Row> 
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Style</label>
                <select value={data.font_style_payment_button_title} onChange={(e) => setData({...data, font_style_payment_button_title: e.target.value})}>
                  <option value="normal">Normal</option>
                  <option value="italic">Italic</option>
                </select>
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Alignment</label>
                   <select onChange={(e) => setData({...data, payment_button_title_text_align: e.target.value})}>
                     <option value="center">Center</option>
                     <option value="left">Left</option>
                     <option value="right">Right</option>
                     <option value="justify">Justify</option>
                   </select>
                 </FormField> 
                </div>
              </Row>  
              <Row>
                <div className="column-popup">
                  <label>Space Top</label>
                  <input type="number" value={data.payment_button_title_padding_top} onChange={(e) => setData({...data, payment_button_title_padding_top: e.target.value})} />
                </div>
                <div className="column-popup">
                  <label>Space Bottom</label>
                  <input type="number" value={data.payment_button_title_padding_bottom} onChange={(e) => setData({...data, payment_button_title_padding_bottom: e.target.value})}/>
                </div>
              </Row>
              <br></br>
              <Row>
                <div className="column-popup">
                  <FormField>
                    <label>Font Colour</label><br></br>
                    <SketchPicker onChange={(color) => { setPaymentButtonTitleColor(color.rgb); setData({...data, payment_button_title_color: color.rgb}); }} color={payment_button_title_color} />
                  </FormField>
                </div>
              </Row> 
            </>
            :
             ''
          }

           { customize.content === 'iframe-title' ? 
            <>
              <h4>Customize block title</h4><br></br>
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Size</label><br></br>
                <input type="number" value={data.font_size_iframe_title} onChange={(e) => setData({...data, font_size_iframe_title: e.target.value})} />
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Weight</label>
                   <input type="number" value={data.font_weight_iframe_title} onChange={(e) => setData({...data, font_weight_iframe_title: e.target.value})} />
                  </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Underline</label>
                    <select value={data.font_underline_iframe_title} onChange={(e) => setData({...data, font_underline_iframe_title: e.target.value})}>
                     <option value="none">No</option>
                     <option value="underline">Yes</option>
                   </select>
                  </FormField>
                </div>
              </Row> 
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Style</label>
                <select value={data.font_style_iframe_title} onChange={(e) => setData({...data, font_style_iframe_title: e.target.value})}>
                  <option value="normal">Normal</option>
                  <option value="italic">Italic</option>
                </select>
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Alignment</label>
                   <select onChange={(e) => setData({...data, iframe_title_text_align: e.target.value})}>
                     <option value="center">Center</option>
                     <option value="left">Left</option>
                     <option value="right">Right</option>
                     <option value="justify">Justify</option>
                   </select>
                 </FormField> 
                </div>
              </Row>  
              <Row>
                <div className="column-popup">
                  <label>Space Top</label>
                  <input type="number" value={data.iframe_title_padding_top} onChange={(e) => setData({...data, iframe_title_padding_top: e.target.value})} />
                </div>
                <div className="column-popup">
                  <label>Space Bottom</label>
                  <input type="number" value={data.iframe_title_padding_bottom} onChange={(e) => setData({...data, iframe_title_padding_bottom: e.target.value})}/>
                </div>
              </Row>
              <br></br>
              <Row>
                <div className="column-popup">
                  <FormField>
                    <label>Font Colour</label><br></br>
                    <SketchPicker onChange={(color) => { setIframeTitleColor(color.rgb); setData({...data, iframe_title_color: color.rgb}); }} color={iframe_title_color} />
                  </FormField>
                </div>
              </Row> 
            </>
            :
             ''
          }

          { customize.content === 'second-iframe-title' ? 
            <>
              <h4>Customize block title</h4><br></br>
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Size</label><br></br>
                <input type="number" value={data.second_font_size_iframe_title} onChange={(e) => setData({...data, second_font_size_iframe_title: e.target.value})} />
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Weight</label>
                   <input type="number" value={data.second_font_weight_iframe_title} onChange={(e) => setData({...data, second_font_weight_iframe_title: e.target.value})} />
                  </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Underline</label>
                    <select value={data.second_font_underline_iframe_title} onChange={(e) => setData({...data, second_font_underline_iframe_title: e.target.value})}>
                     <option value="none">No</option>
                     <option value="underline">Yes</option>
                   </select>
                  </FormField>
                </div>
              </Row> 
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Style</label>
                <select value={data.second_font_style_iframe_title} onChange={(e) => setData({...data, second_font_style_iframe_title: e.target.value})}>
                  <option value="normal">Normal</option>
                  <option value="italic">Italic</option>
                </select>
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Alignment</label>
                   <select onChange={(e) => setData({...data, second_iframe_title_text_align: e.target.value})}>
                     <option value="center">Center</option>
                     <option value="left">Left</option>
                     <option value="right">Right</option>
                     <option value="justify">Justify</option>
                   </select>
                 </FormField> 
                </div>
              </Row>  
              <Row>
                <div className="column-popup">
                  <label>Space Top</label>
                  <input type="number" value={data.second_iframe_title_padding_top} onChange={(e) => setData({...data, second_iframe_title_padding_top: e.target.value})} />
                </div>
                <div className="column-popup">
                  <label>Space Bottom</label>
                  <input type="number" value={data.second_iframe_title_padding_bottom} onChange={(e) => setData({...data, second_iframe_title_padding_bottom: e.target.value})}/>
                </div>
              </Row>
              <br></br>
              <Row>
                <div className="column-popup">
                  <FormField>
                    <label>Font Colour</label><br></br>
                    <SketchPicker onChange={(color) => { setSecondIframeTitleColor(color.rgb); setData({...data, second_iframe_title_color: color.rgb}); }} color={second_iframe_title_color} />
                  </FormField>
                </div>
              </Row> 
            </>
            :
             ''
          }

          { customize.content === 'third-iframe-title' ? 
            <>
              <h4>Customize block title</h4><br></br>
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Size</label><br></br>
                <input type="number" value={data.third_font_size_iframe_title} onChange={(e) => setData({...data, third_font_size_iframe_title: e.target.value})} />
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Weight</label>
                   <input type="number" value={data.third_font_weight_iframe_title} onChange={(e) => setData({...data, third_font_weight_iframe_title: e.target.value})} />
                  </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Underline</label>
                    <select value={data.third_font_underline_iframe_title} onChange={(e) => setData({...data, third_font_underline_iframe_title: e.target.value})}>
                     <option value="none">No</option>
                     <option value="underline">Yes</option>
                   </select>
                  </FormField>
                </div>
              </Row> 
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Style</label>
                <select value={data.third_font_style_iframe_title} onChange={(e) => setData({...data, third_font_style_iframe_title: e.target.value})}>
                  <option value="normal">Normal</option>
                  <option value="italic">Italic</option>
                </select>
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Alignment</label>
                   <select onChange={(e) => setData({...data, third_iframe_title_text_align: e.target.value})}>
                     <option value="center">Center</option>
                     <option value="left">Left</option>
                     <option value="right">Right</option>
                     <option value="justify">Justify</option>
                   </select>
                 </FormField> 
                </div>
              </Row>  
              <Row>
                <div className="column-popup">
                  <label>Space Top</label>
                  <input type="number" value={data.third_iframe_title_padding_top} onChange={(e) => setData({...data, third_iframe_title_padding_top: e.target.value})} />
                </div>
                <div className="column-popup">
                  <label>Space Bottom</label>
                  <input type="number" value={data.third_iframe_title_padding_bottom} onChange={(e) => setData({...data, third_iframe_title_padding_bottom: e.target.value})}/>
                </div>
              </Row>
              <br></br>
              <Row>
                <div className="column-popup">
                  <FormField>
                    <label>Font Colour</label><br></br>
                    <SketchPicker onChange={(color) => { setThirdIframeTitleColor(color.rgb); setData({...data, third_iframe_title_color: color.rgb}); }} color={third_iframe_title_color} />
                  </FormField>
                </div>
              </Row> 
            </>
            :
             ''
          }

          { customize.content === 'fourth-iframe-title' ? 
            <>
              <h4>Customize block title</h4><br></br>
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Size</label><br></br>
                <input type="number" value={data.fourth_font_size_iframe_title} onChange={(e) => setData({...data, fourth_font_size_iframe_title: e.target.value})} />
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Weight</label>
                   <input type="number" value={data.fourth_font_weight_iframe_title} onChange={(e) => setData({...data, fourth_font_weight_iframe_title: e.target.value})} />
                  </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Underline</label>
                    <select value={data.fourth_font_underline_iframe_title} onChange={(e) => setData({...data, fourth_font_underline_iframe_title: e.target.value})}>
                     <option value="none">No</option>
                     <option value="underline">Yes</option>
                   </select>
                  </FormField>
                </div>
              </Row> 
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Style</label>
                <select value={data.fourth_font_style_iframe_title} onChange={(e) => setData({...data, fourth_font_style_iframe_title: e.target.value})}>
                  <option value="normal">Normal</option>
                  <option value="italic">Italic</option>
                </select>
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Alignment</label>
                   <select onChange={(e) => setData({...data, fourth_iframe_title_text_align: e.target.value})}>
                     <option value="center">Center</option>
                     <option value="left">Left</option>
                     <option value="right">Right</option>
                     <option value="justify">Justify</option>
                   </select>
                 </FormField> 
                </div>
              </Row>  
              <Row>
                <div className="column-popup">
                  <label>Space Top</label>
                  <input type="number" value={data.fourth_iframe_title_padding_top} onChange={(e) => setData({...data, fourth_iframe_title_padding_top: e.target.value})} />
                </div>
                <div className="column-popup">
                  <label>Space Bottom</label>
                  <input type="number" value={data.fourth_iframe_title_padding_bottom} onChange={(e) => setData({...data, fourth_iframe_title_padding_bottom: e.target.value})}/>
                </div>
              </Row>
              <br></br>
              <Row>
                <div className="column-popup">
                  <FormField>
                    <label>Font Colour</label><br></br>
                    <SketchPicker onChange={(color) => { setFourthIframeTitleColor(color.rgb); setData({...data, fourth_iframe_title_color: color.rgb}); }} color={fourth_iframe_title_color} />
                  </FormField>
                </div>
              </Row> 
            </>
            :
             ''
          }

          { customize.content === 'appointment-title' ?
            <>
              <h4>Customize appointment title field</h4><br></br>
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Size</label><br></br>
                <input type="number" value={data.font_size_appointment_title} onChange={(e) => setData({...data, font_size_appointment_title: e.target.value})} />
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Weight</label>
                   <input type="number" value={data.font_weight_appointment_title} onChange={(e) => setData({...data, font_weight_appointment_title: e.target.value})} />
                  </FormField>
                </div>
              </Row> 
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Style</label>
                <select value={data.font_style_appointment_title} onChange={(e) => setData({...data, font_style_appointment_title: e.target.value})}>
                  <option value="normal">Normal</option>
                  <option value="italic">Italic</option>
                </select>
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Underline</label>
                    <select value={data.font_underline_appointment_title} onChange={(e) => setData({...data, font_underline_appointment_title: e.target.value})}>
                     <option value="none">No</option>
                     <option value="underline">Yes</option>
                   </select>
                  </FormField>
                </div>
              </Row> 
              <Row>
                <div className="column-popup">
                 <FormField>
                  <label>Space Top</label><br></br>
                   <input type="number" value={data.appointment_title_padding_top} onChange={(e) => setData({...data, appointment_title_padding_top: e.target.value})} />
                 </FormField> 
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Space Bottom</label>
                   <input type="number" value={data.appointment_title_padding_bottom} onChange={(e) => setData({...data, appointment_title_padding_bottom: e.target.value})} />
                 </FormField> 
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Alignment</label>
                   <select value={data.appointment_title_text_align} onChange={(e) => setData({...data, appointment_title_text_align: e.target.value})}>
                     <option value="center">Center</option>
                     <option value="left">Left</option>
                     <option value="right">Right</option>
                     <option value="justify">Justify</option>
                   </select>
                 </FormField> 
                </div>
              </Row> 
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Colour</label><br></br>
                <SketchPicker onChange={(color) => { setAppointmentTitleColor(color.rgb); setData({...data, appointment_title_color: color.rgb}); }} color={appointment_title_color} />
              </FormField>
                </div>
              </Row> 
            </>
            :
             ''
          }

          { customize.content === 'contactForm-title' ?
            <>
              <h4>Customize contact form title field</h4><br></br>
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Size</label><br></br>
                <input type="number" value={data.font_size_contactForm_title} onChange={(e) => setData({...data, font_size_contactForm_title: e.target.value})} />
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Weight</label>
                   <input type="number" value={data.font_weight_contactForm_title} onChange={(e) => setData({...data, font_weight_contactForm_title: e.target.value})} />
                  </FormField>
                </div>
              </Row> 
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Style</label>
                <select value={data.font_style_contactForm_title} onChange={(e) => setData({...data, font_style_contactForm_title: e.target.value})}>
                  <option value="normal">Normal</option>
                  <option value="italic">Italic</option>
                </select>
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Underline</label>
                    <select value={data.font_underline_contactForm_title} onChange={(e) => setData({...data, font_underline_contactForm_title: e.target.value})}>
                     <option value="none">No</option>
                     <option value="underline">Yes</option>
                   </select>
                  </FormField>
                </div>
              </Row> 
              <Row>
                <div className="column-popup">
                 <FormField>
                  <label>Space Top</label><br></br>
                   <input type="number" value={data.contactForm_title_padding_top} onChange={(e) => setData({...data, contactForm_title_padding_top: e.target.value})} />
                 </FormField> 
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Space Bottom</label>
                   <input type="number" value={data.contactForm_title_padding_bottom} onChange={(e) => setData({...data, contactForm_title_padding_bottom: e.target.value})} />
                 </FormField> 
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Alignment</label>
                   <select value={data.contactForm_title_text_align} onChange={(e) => setData({...data, contactForm_title_text_align: e.target.value})}>
                     <option value="center">Center</option>
                     <option value="left">Left</option>
                     <option value="right">Right</option>
                     <option value="justify">Justify</option>
                   </select>
                 </FormField> 
                </div>
              </Row> 
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Colour</label><br></br>
                <SketchPicker onChange={(color) => { setContactFormTitleColor(color.rgb); setData({...data, contactForm_title_color: color.rgb}); }} color={contactForm_title_color} />
              </FormField>
                </div>
              </Row> 
            </>
            :
             ''
          }

          { customize.content === 'custom-image-title' ?
            <>
              <h4>Customize custom image title field</h4><br></br>
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Size</label><br></br>
                <input type="number" value={data.font_size_custom_image_title} onChange={(e) => setData({...data, font_size_custom_image_title: e.target.value})} />
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Weight</label>
                   <input type="number" value={data.font_weight_custom_image_title} onChange={(e) => setData({...data, font_weight_custom_image_title: e.target.value})} />
                  </FormField>
                </div>
              </Row> 
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Style</label>
                <select value={data.font_style_custom_image_title} onChange={(e) => setData({...data, font_style_custom_image_title: e.target.value})}>
                  <option value="normal">Normal</option>
                  <option value="italic">Italic</option>
                </select>
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Underline</label>
                    <select value={data.font_underline_custom_image_title} onChange={(e) => setData({...data, font_underline_custom_image_title: e.target.value})}>
                     <option value="none">No</option>
                     <option value="underline">Yes</option>
                   </select>
                  </FormField>
                </div>
              </Row> 
              <Row>
                <div className="column-popup">
                 <FormField>
                  <label>Space Top</label><br></br>
                   <input type="number" value={data.custom_image_title_padding_top} onChange={(e) => setData({...data, custom_image_title_padding_top: e.target.value})} />
                 </FormField> 
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Space Bottom</label>
                   <input type="number" value={data.custom_image_title_padding_bottom} onChange={(e) => setData({...data, custom_image_title_padding_bottom: e.target.value})} />
                 </FormField> 
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Alignment</label>
                   <select value={data.custom_image_title_text_align} onChange={(e) => setData({...data, custom_image_title_text_align: e.target.value})}>
                     <option value="center">Center</option>
                     <option value="left">Left</option>
                     <option value="right">Right</option>
                     <option value="justify">Justify</option>
                   </select>
                 </FormField> 
                </div>
              </Row> 
              <Row>
                <div className="column-popup">
                  <FormField>
                   <label>Font Colour</label><br></br>
                   <SketchPicker onChange={(color) => { setCustomImageTitleColor(color.rgb); setData({...data, custom_image_title_color: color.rgb}); }} color={custom_image_title_color} />
                  </FormField>
                </div>
              </Row> 
            </>
            :
             ''
          }

          { customize.content === 'custom-image-title-second' ?
            <>
              <h4>Customize custom image title field</h4><br></br>
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Size</label><br></br>
                <input type="number" value={data.second_font_size_custom_image_title} onChange={(e) => setData({...data, second_font_size_custom_image_title: e.target.value})} />
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Weight</label>
                   <input type="number" value={data.second_font_weight_custom_image_title} onChange={(e) => setData({...data, second_font_weight_custom_image_title: e.target.value})} />
                  </FormField>
                </div>
              </Row> 
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Style</label>
                <select value={data.second_font_style_custom_image_title} onChange={(e) => setData({...data, second_font_style_custom_image_title: e.target.value})}>
                  <option value="normal">Normal</option>
                  <option value="italic">Italic</option>
                </select>
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Underline</label>
                    <select value={data.second_font_underline_custom_image_title} onChange={(e) => setData({...data, second_font_underline_custom_image_title: e.target.value})}>
                     <option value="none">No</option>
                     <option value="underline">Yes</option>
                   </select>
                  </FormField>
                </div>
              </Row> 
              <Row>
                <div className="column-popup">
                 <FormField>
                  <label>Space Top</label><br></br>
                   <input type="number" value={data.second_custom_image_title_padding_top} onChange={(e) => setData({...data, second_custom_image_title_padding_top: e.target.value})} />
                 </FormField> 
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Space Bottom</label>
                   <input type="number" value={data.second_custom_image_title_padding_bottom} onChange={(e) => setData({...data, second_custom_image_title_padding_bottom: e.target.value})} />
                 </FormField> 
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Alignment</label>
                   <select onChange={(e) => setData({...data, second_custom_image_title_text_align: e.target.value})}>
                     <option value="center">Center</option>
                     <option value="left">Left</option>
                     <option value="right">Right</option>
                     <option value="justify">Justify</option>
                   </select>
                 </FormField> 
                </div>
              </Row> 
              <Row>
                <div className="column-popup">
                  <FormField>
                   <label>Font Colour</label><br></br>
                   <SketchPicker onChange={(color) => { setSecondCustomImageTitleColor(color.rgb); setData({...data, second_custom_image_title_color: color.rgb}); }} color={second_custom_image_title_color} />
                  </FormField>
                </div>
              </Row> 
            </>
            :
             ''
          }

          { customize.content === 'image-grid-title' ?
            <>
              <h4>Customize image gallery title field</h4><br></br>
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Size</label><br></br>
                <input type="number" value={data.font_size_image_grid_title} onChange={(e) => setData({...data, font_size_image_grid_title: e.target.value})} />
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Weight</label>
                   <input type="number" value={data.font_weight_image_grid_title} onChange={(e) => setData({...data, font_weight_image_grid_title: e.target.value})} />
                  </FormField>
                </div>
              </Row> 
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Style</label>
                <select value={data.font_style_image_grid_title} onChange={(e) => setData({...data, font_style_image_grid_title: e.target.value})}>
                  <option value="normal">Normal</option>
                  <option value="italic">Italic</option>
                </select>
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Underline</label>
                    <select value={data.font_underline_image_grid_title} onChange={(e) => setData({...data, font_underline_image_grid_title: e.target.value})}>
                     <option value="none">No</option>
                     <option value="underline">Yes</option>
                   </select>
                  </FormField>
                </div>
              </Row> 
              <Row>
                <div className="column-popup">
                 <FormField>
                  <label>Space Top</label><br></br>
                   <input type="number" value={data.image_grid_title_padding_top} onChange={(e) => setData({...data, image_grid_title_padding_top: e.target.value})} />
                 </FormField> 
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Space Bottom</label>
                   <input type="number" value={data.image_grid_title_padding_bottom} onChange={(e) => setData({...data, image_grid_title_padding_bottom: e.target.value})} />
                 </FormField> 
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Alignment</label>
                   <select value={data.image_grid_title_text_align} onChange={(e) => setData({...data, image_grid_title_text_align: e.target.value})}>
                     <option value="center">Center</option>
                     <option value="left">Left</option>
                     <option value="right">Right</option>
                     <option value="justify">Justify</option>
                   </select>
                 </FormField> 
                </div>
              </Row> 
              <Row>
                <div className="column-popup">
                  <FormField>
                   <label>Font Colour</label><br></br>
                   <SketchPicker onChange={(color) => { setImageGridTitleColor(color.rgb); setData({...data, image_grid_title_color: color.rgb}); }} color={image_grid_title_color} />
                  </FormField>
                </div>
              </Row> 
            </>
            :
             ''
          }

          { customize.content === 'custom-text' ? 
            <>
              <h4>Customize custom text field</h4><br></br>
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Size</label><br></br>
                <input type="number" value={data.custom_text_font_size} onChange={(e) => setData({...data, custom_text_font_size: e.target.value})} />
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Weight</label>
                   <input type="number" value={data.custom_text_font_weight} onChange={(e) => setData({...data, custom_text_font_weight: e.target.value})} />
                  </FormField>
                </div>
              </Row> 
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Style</label>
                <select value={data.custom_text_font_style} onChange={(e) => setData({...data, custom_text_font_style: e.target.value})}>
                  <option value="normal">Normal</option>
                  <option value="italic">Italic</option>
                </select>
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Alignment</label>
                   <select value={data.custom_text_font_text_align} onChange={(e) => setData({...data, custom_text_font_text_align: e.target.value})}>
                     <option value="center">Center</option>
                     <option value="left">Left</option>
                     <option value="right">Right</option>
                     <option value="justify">Justify</option>
                   </select>
                 </FormField> 
                </div>
              </Row>  
              <Row>
                <div className="column-popup">
                  <label>Space Top</label>
                  <input type="number" value={data.custom_text_padding_top} onChange={(e) => setData({...data, custom_text_padding_top: e.target.value})} />
                </div>
                <div className="column-popup">
                  <label>Space Bottom</label>
                  <input type="number" value={data.custom_text_padding_bottom} onChange={(e) => setData({...data, custom_text_padding_bottom: e.target.value})}/>
                </div>
              </Row>
              <br></br>
              <Row>
                <div className="column-popup">
                  <FormField>
                    <label>Font Colour</label><br></br>
                    <SketchPicker onChange={(color) => { setCustomTextFontColor(color.rgb); setData({...data, custom_text_font_color: color.rgb}); }} color={custom_text_font_color} />
                  </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Background Colour</label><br></br>
                   <SketchPicker onChange={(color) => { setCustomTextBackgroundColor(color.rgb); setData({...data, custom_text_background_color: color.rgb}); }} color={custom_text_background_color} />
                  </FormField>
                </div>
              </Row> 
            </>
            :
             ''
          }

          { customize.content === 'custom-text-second' ? 
            <>
              <h4>Customize custom text field</h4><br></br>
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Size</label><br></br>
                <input type="number" value={data.second_custom_text_font_size} onChange={(e) => setData({...data, second_custom_text_font_size: e.target.value})} />
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Weight</label>
                   <input type="number" value={data.second_custom_text_font_weight} onChange={(e) => setData({...data, second_custom_text_font_weight: e.target.value})} />
                  </FormField>
                </div>
              </Row> 
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Style</label>
                <select value={data.second_custom_text_font_style} onChange={(e) => setData({...data, second_custom_text_font_style: e.target.value})}>
                  <option value="normal">Normal</option>
                  <option value="italic">Italic</option>
                </select>
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Alignment</label>
                   <select onChange={(e) => setData({...data, second_custom_text_font_text_align: e.target.value})}>
                     <option value="center">Center</option>
                     <option value="left">Left</option>
                     <option value="right">Right</option>
                     <option value="justify">Justify</option>
                   </select>
                 </FormField> 
                </div>
              </Row>  
              <Row>
                <div className="column-popup">
                  <label>Space Top</label>
                  <input type="number" value={data.second_custom_text_padding_top} onChange={(e) => setData({...data, second_custom_text_padding_top: e.target.value})} />
                </div>
                <div className="column-popup">
                  <label>Space Bottom</label>
                  <input type="number" value={data.second_custom_text_padding_bottom} onChange={(e) => setData({...data, second_custom_text_padding_bottom: e.target.value})}/>
                </div>
              </Row>
              <br></br>
              <Row>
                <div className="column-popup">
                  <FormField>
                    <label>Font Colour</label><br></br>
                    <SketchPicker onChange={(color) => { setSecondCustomTextFontColor(color.rgb); setData({...data, second_custom_text_font_color: color.rgb}); }} color={second_custom_text_font_color} />
                  </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Background Colour</label><br></br>
                   <SketchPicker onChange={(color) => { setSecondCustomTextBackgroundColor(color.rgb); setData({...data, second_custom_text_background_color: color.rgb}); }} color={second_custom_text_background_color} />
                  </FormField>
                </div>
              </Row> 
            </>
            :
             ''
          }

          { customize.content === 'custom-text-title' ? 
            <>
              <h4>Customize custom text title field</h4><br></br>
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Size</label><br></br>
                <input type="number" value={data.font_size_custom_text_title} onChange={(e) => setData({...data, font_size_custom_text_title: e.target.value})} />
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Weight</label>
                   <input type="number" value={data.font_weight_custom_text_title} onChange={(e) => setData({...data, font_weight_custom_text_title: e.target.value})} />
                  </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Underline</label>
                    <select value={data.font_underline_custom_text_title} onChange={(e) => setData({...data, font_underline_custom_text_title: e.target.value})}>
                     <option value="none">No</option>
                     <option value="underline">Yes</option>
                   </select>
                  </FormField>
                </div>
              </Row> 
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Style</label>
                <select value={data.font_style_custom_text_title} onChange={(e) => setData({...data, font_style_custom_text_title: e.target.value})}>
                  <option value="normal">Normal</option>
                  <option value="italic">Italic</option>
                </select>
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Alignment</label>
                   <select value={data.custom_text_title_text_align} onChange={(e) => setData({...data, custom_text_title_text_align: e.target.value})}>
                     <option value="center">Center</option>
                     <option value="left">Left</option>
                     <option value="right">Right</option>
                     <option value="justify">Justify</option>
                   </select>
                 </FormField> 
                </div>
              </Row>  
              <Row>
                <div className="column-popup">
                  <label>Space Top</label>
                  <input type="number" value={data.custom_text_title_padding_top} onChange={(e) => setData({...data, custom_text_title_padding_top: e.target.value})} />
                </div>
                <div className="column-popup">
                  <label>Space Bottom</label>
                  <input type="number" value={data.custom_text_title_padding_bottom} onChange={(e) => setData({...data, custom_text_title_padding_bottom: e.target.value})}/>
                </div>
              </Row>
              <br></br>
              <Row>
                <div className="column-popup">
                  <FormField>
                    <label>Font Colour</label><br></br>
                    <SketchPicker onChange={(color) => { setCustomTextTitleColor(color.rgb); setData({...data, custom_text_title_color: color.rgb}); }} color={custom_text_title_color} />
                  </FormField>
                </div>
              </Row> 
            </>
            :
             ''
          }

          { customize.content === 'custom-text-title-second' ? 
            <>
              <h4>Customize custom text title field</h4><br></br>
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Size</label><br></br>
                <input type="number" value={data.second_font_size_custom_text_title} onChange={(e) => setData({...data, second_font_size_custom_text_title: e.target.value})} />
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Weight</label>
                   <input type="number" value={data.second_font_weight_custom_text_title} onChange={(e) => setData({...data, second_font_weight_custom_text_title: e.target.value})} />
                  </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Underline</label>
                    <select value={data.second_font_underline_custom_text_title} onChange={(e) => setData({...data, second_font_underline_custom_text_title: e.target.value})}>
                     <option value="none">No</option>
                     <option value="underline">Yes</option>
                   </select>
                  </FormField>
                </div>
              </Row> 
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Style</label>
                <select value={data.second_font_style_custom_text_title} onChange={(e) => setData({...data, second_font_style_custom_text_title: e.target.value})}>
                  <option value="normal">Normal</option>
                  <option value="italic">Italic</option>
                </select>
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Alignment</label>
                   <select onChange={(e) => setData({...data, second_custom_text_title_text_align: e.target.value})}>
                     <option value="center">Center</option>
                     <option value="left">Left</option>
                     <option value="right">Right</option>
                     <option value="justify">Justify</option>
                   </select>
                 </FormField> 
                </div>
              </Row>  
              <Row>
                <div className="column-popup">
                  <label>Space Top</label>
                  <input type="number" value={data.second_custom_text_title_padding_top} onChange={(e) => setData({...data, second_custom_text_title_padding_top: e.target.value})} />
                </div>
                <div className="column-popup">
                  <label>Space Bottom</label>
                  <input type="number" value={data.second_custom_text_title_padding_bottom} onChange={(e) => setData({...data, second_custom_text_title_padding_bottom: e.target.value})}/>
                </div>
              </Row>
              <br></br>
              <Row>
                <div className="column-popup">
                  <FormField>
                    <label>Font Colour</label><br></br>
                    <SketchPicker onChange={(color) => { setSecondCustomTextTitleColor(color.rgb); setData({...data, second_custom_text_title_color: color.rgb}); }} color={second_custom_text_title_color} />
                  </FormField>
                </div>
              </Row> 
            </>
            :
             ''
          }

          { customize.content === 'table-styles' ? 
            <>
              <h4>Customize Table</h4><br></br>
              <Row>
                <div className="column-popup">
                  <FormField>
                   <label>Font Size</label><br></br>
                   <input type="number" value={data.table_font_size} onChange={(e) => setData({...data, table_font_size: e.target.value})} />
                  </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Weight</label>
                   <input type="number" value={data.table_font_weight} onChange={(e) => setData({...data, table_font_weight: e.target.value})} />
                  </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                <label>Font Style</label>
                <select value={data.table_font_style} onChange={(e) => setData({...data, table_font_style: e.target.value})}>
                  <option value="normal">Normal</option>
                  <option value="italic">Italic</option>
                </select>
              </FormField>
                </div>
              </Row>
              <Row>
                <div className="column-popup">
                  <FormField>
                   <label>Table Cell Alignment</label>
                   <select onChange={(e) => setData({...data, table_cell_alignment: e.target.value})}>
                     <option value="center">Center</option>
                     <option value="left">Left</option>
                     <option value="right">Right</option>
                   </select>
                 </FormField> 
                </div>
                <div className="column-popup">
                 <FormField>
                   <label>Table Cell Padding</label>
                   <input type="number" value={data.table_cell_padding} onChange={(e) => setData({...data, table_cell_padding: e.target.value})} />
                  </FormField>
                </div>
                <div className="column-popup">
                 <FormField>
                   <label>Border Width</label>
                   <input type="number" value={data.table_border_width} onChange={(e) => setData({...data, table_border_width: e.target.value})} />
                  </FormField>
                </div>
              </Row> 
              <Row>
                <div className="column-popup">
                  <FormField>
                    <label>Font Colour</label><br></br>
                    <SketchPicker onChange={(color) => { setTableFontColor(color.rgb); setData({...data, table_font_color: color.rgb}); }} color={table_font_color} />
                  </FormField>
                </div>
                 <div className="column-popup">
                   <FormField>
                    <label>Border Colour</label><br></br>
                    <SketchPicker onChange={(color) => { setTableBorderColor(color.rgb); setData({...data, table_border_color: color.rgb}); }} color={table_border_color} />
                  </FormField>
                </div>
                <div className="column-popup">
                   <FormField>
                    <label>Cell Colour</label><br></br>
                    <SketchPicker onChange={(color) => { setTableCellBackground(color.rgb); setData({...data, table_cell_background: color.rgb}); }} color={table_cell_background} />
                  </FormField>
                </div>
              </Row> 
            </>
            :
             ''
          }

          { customize.content === 'table-title' ? 
            <>
              <h4>Customize table title field</h4><br></br>
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Size</label><br></br>
                <input type="number" value={data.font_size_table_title} onChange={(e) => setData({...data, font_size_table_title: e.target.value})} />
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Weight</label>
                   <input type="number" value={data.font_weight_table_title} onChange={(e) => setData({...data, font_weight_table_title: e.target.value})} />
                  </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Underline</label>
                    <select value={data.font_underline_table_title} onChange={(e) => setData({...data, font_underline_table_title: e.target.value})}>
                     <option value="none">No</option>
                     <option value="underline">Yes</option>
                   </select>
                  </FormField>
                </div>
              </Row> 
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Style</label>
                <select value={data.font_style_table_title} onChange={(e) => setData({...data, font_style_table_title: e.target.value})}>
                  <option value="normal">Normal</option>
                  <option value="italic">Italic</option>
                </select>
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Alignment</label>
                   <select value={data.table_title_text_align} onChange={(e) => setData({...data, table_title_text_align: e.target.value})}>
                     <option value="center">Center</option>
                     <option value="left">Left</option>
                     <option value="right">Right</option>
                     <option value="justify">Justify</option>
                   </select>
                 </FormField> 
                </div>
              </Row>  
              <Row>
                <div className="column-popup">
                  <label>Space Top</label>
                  <input type="number" value={data.table_title_padding_top} onChange={(e) => setData({...data, table_title_padding_top: e.target.value})} />
                </div>
                <div className="column-popup">
                  <label>Space Bottom</label>
                  <input type="number" value={data.table_title_padding_bottom} onChange={(e) => setData({...data, table_title_padding_bottom: e.target.value})}/>
                </div>
              </Row>
              <br></br>
              <Row>
                <div className="column-popup">
                  <FormField>
                    <label>Font Colour</label><br></br>
                    <SketchPicker onChange={(color) => { setTableTitleColor(color.rgb); setData({...data, table_title_color: color.rgb}); }} color={table_title_color} />
                  </FormField>
                </div>
              </Row> 
            </>
            :
             ''
          }

          { customize.content === 'two-image-title' ? 
            <>
              <h4>Customize block title</h4><br></br>
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Size</label><br></br>
                <input type="number" value={data.font_size_two_image_title} onChange={(e) => setData({...data, font_size_two_image_title: e.target.value})} />
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Weight</label>
                   <input type="number" value={data.font_weight_two_image_title} onChange={(e) => setData({...data, font_weight_two_image_title: e.target.value})} />
                  </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Underline</label>
                    <select value={data.font_underline_two_image_title} onChange={(e) => setData({...data, font_underline_two_image_title: e.target.value})}>
                     <option value="none">No</option>
                     <option value="underline">Yes</option>
                   </select>
                  </FormField>
                </div>
              </Row> 
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Style</label>
                <select value={data.font_style_two_image_title} onChange={(e) => setData({...data, font_style_two_image_title: e.target.value})}>
                  <option value="normal">Normal</option>
                  <option value="italic">Italic</option>
                </select>
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Alignment</label>
                   <select value={data.two_image_title_text_align} onChange={(e) => setData({...data, two_image_title_text_align: e.target.value})}>
                     <option value="center">Center</option>
                     <option value="left">Left</option>
                     <option value="right">Right</option>
                     <option value="justify">Justify</option>
                   </select>
                 </FormField> 
                </div>
              </Row>  
              <Row>
                <div className="column-popup">
                  <label>Space Top</label>
                  <input type="number" value={data.two_image_title_padding_top} onChange={(e) => setData({...data, two_image_title_padding_top: e.target.value})} />
                </div>
                <div className="column-popup">
                  <label>Space Bottom</label>
                  <input type="number" value={data.two_image_title_padding_bottom} onChange={(e) => setData({...data, two_image_title_padding_bottom: e.target.value})}/>
                </div>
              </Row>
              <br></br>
              <Row>
                <div className="column-popup">
                  <FormField>
                    <label>Font Colour</label><br></br>
                    <SketchPicker onChange={(color) => { setTwoImageTitleColor(color.rgb); setData({...data, two_image_title_color: color.rgb}); }} color={two_image_title_color} />
                  </FormField>
                </div>
              </Row> 
            </>
            :
             ''
          }

          { customize.content === 'accordion-title' ? 
            <>
              <h4>Customize accordion title</h4><br></br>
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Size</label><br></br>
                <input type="number" value={data.font_size_accordion_title} onChange={(e) => setData({...data, font_size_accordion_title: e.target.value})} />
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Weight</label>
                   <input type="number" value={data.font_weight_accordion_title} onChange={(e) => setData({...data, font_weight_accordion_title: e.target.value})} />
                  </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Underline</label>
                    <select value={data.font_underline_accordion_title} onChange={(e) => setData({...data, font_underline_accordion_title: e.target.value})}>
                     <option value="none">No</option>
                     <option value="underline">Yes</option>
                   </select>
                  </FormField>
                </div>
              </Row> 
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Style</label>
                <select value={data.font_style_accordion_title} onChange={(e) => setData({...data, font_style_accordion_title: e.target.value})}>
                  <option value="normal">Normal</option>
                  <option value="italic">Italic</option>
                </select>
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Alignment</label>
                   <select value={data.accordion_title_text_align} onChange={(e) => setData({...data, accordion_title_text_align: e.target.value})}>
                     <option value="center">Center</option>
                     <option value="left">Left</option>
                     <option value="right">Right</option>
                     <option value="justify">Justify</option>
                   </select>
                 </FormField> 
                </div>
              </Row>  
              <Row>
                <div className="column-popup">
                  <label>Space Top</label>
                  <input type="number" value={data.accordion_title_padding_top} onChange={(e) => setData({...data, accordion_title_padding_top: e.target.value})} />
                </div>
                <div className="column-popup">
                  <label>Space Bottom</label>
                  <input type="number" value={data.accordion_title_padding_bottom} onChange={(e) => setData({...data, accordion_title_padding_bottom: e.target.value})}/>
                </div>
                <div className="column-popup">
                  <label>Text Line Sapcing</label>
                  <input type="number" value={data.accordion_content_line_height} onChange={(e) => setData({...data, accordion_content_line_height: e.target.value})}/>
                </div>
              </Row>
              <br></br>
              <Row>
                <div className="column-popup">
                  <FormField>
                    <label>Font Colour</label><br></br>
                    <SketchPicker onChange={(color) => { setAccordionTitleColor(color.rgb); setData({...data, accordion_title_color: color.rgb}); }} color={accordion_title_color} />
                  </FormField>
                </div>
              </Row> 
            </>
            :
             ''
          }

          { customize.content === 'block-image-text-title' ? 
            <>
              <h4>Customize the block title field</h4><br></br>
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Size</label><br></br>
                <input type="number" value={data.font_size_block_image_text_title} onChange={(e) => setData({...data, font_size_block_image_text_title: e.target.value})} />
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Weight</label>
                   <input type="number" value={data.font_weight_block_image_text_title} onChange={(e) => setData({...data, font_weight_block_image_text_title: e.target.value})} />
                  </FormField>
                </div>
              </Row> 
              <Row>
                <div className="column-popup">
                  <FormField>
                   <label>Font Style</label>
                   <select value={data.font_style_block_image_text_title} onChange={(e) => setData({...data, font_style_block_image_text_title: e.target.value})}>
                    <option value="normal">Normal</option>
                    <option value="italic">Italic</option>
                   </select>
                 </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Underline</label>
                    <select value={data.font_underline_block_image_text_title} onChange={(e) => setData({...data, font_underline_block_image_text_title: e.target.value})}>
                     <option value="none">No</option>
                     <option value="underline">Yes</option>
                   </select>
                  </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Alignment</label>
                   <select value={data.block_image_text_title_text_align} onChange={(e) => setData({...data, block_image_text_title_text_align: e.target.value})}>
                     <option value="center">Center</option>
                     <option value="left">Left</option>
                     <option value="right">Right</option>
                     <option value="justify">Justify</option>
                   </select>
                 </FormField> 
                </div>
              </Row> 
              <Row>
                <div className="column-popup">
                 <FormField>
                  <label>Space Top</label><br></br>
                   <input type="number" value={data.block_image_text_title_padding_top} onChange={(e) => setData({...data, block_image_text_title_padding_top: e.target.value})} />
                 </FormField> 
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Space Bottom</label>
                   <input type="number" value={data.block_image_text_title_padding_bottom} onChange={(e) => setData({...data, block_image_text_title_padding_bottom: e.target.value})} />
                 </FormField> 
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Space Left</label>
                   <input type="number" value={data.block_image_text_title_padding_left} onChange={(e) => setData({...data, block_image_text_title_padding_left: e.target.value})} />
                 </FormField> 
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Space Right</label>
                   <input type="number" value={data.block_image_text_title_padding_right} onChange={(e) => setData({...data, block_image_text_title_padding_right: e.target.value})} />
                 </FormField> 
                </div>
              </Row> 
              <Row>
                <div className="column-popup">
                  <FormField>
                    <label>Font Colour</label><br></br>
                    <SketchPicker onChange={(color) => { setBlockImageTextTitleColor(color.rgb); setData({...data, block_image_text_title_color: color.rgb}); }} color={block_image_text_title_color} />
                  </FormField>
                </div>
              </Row> 
            </>
            :
             ''
          }

          { customize.content === 'block-image-text' ? 
            <>
              <h4>Customize the block</h4><br></br>
              <Row>
                <div className="column-popup">
                  <FormField>
                <label>Font Size</label><br></br>
                <input type="number" value={data.block_image_text_font_size} onChange={(e) => setData({...data, block_image_text_font_size: e.target.value})} />
              </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Weight</label>
                   <input type="number" value={data.block_image_text_font_weight} onChange={(e) => setData({...data, block_image_text_font_weight: e.target.value})} />
                  </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Underline</label>
                    <select value={data.block_image_text_underline} onChange={(e) => setData({...data, block_image_text_underline: e.target.value})}>
                     <option value="none">No</option>
                     <option value="underline">Yes</option>
                   </select>
                  </FormField>
                </div>
              </Row> 
              <Row>
                <div className="column-popup">
                  <FormField>
                  <label>Font Style</label>
                  <select value={data.block_image_text_style} onChange={(e) => setData({...data, block_image_text_style: e.target.value})}>
                    <option value="normal">Normal</option>
                    <option value="italic">Italic</option>
                  </select>
                 </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Alignment</label>
                   <select value={data.block_image_text_alignment} onChange={(e) => setData({...data, block_image_text_alignment: e.target.value})}>
                     <option value="center">Center</option>
                     <option value="left">Left</option>
                     <option value="right">Right</option>
                     <option value="justify">Justify</option>
                   </select>
                 </FormField> 
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Line Height</label>
                   <input type="number" value={data.block_image_text_line_height} onChange={(e) => setData({...data, block_image_text_line_height: e.target.value})}/>
                 </FormField> 
                </div>
              </Row>  
              <Row>
               <div className="column-popup">
                 <FormField>
                   <label>Space Top</label>
                   <input type="number" value={data.block_image_text_paragraph_padding_top} onChange={(e) => setData({...data, block_image_text_paragraph_padding_top: e.target.value})}/>
                 </FormField> 
               </div>
               <div className="column-popup">
                 <FormField>
                   <label>Space Bottom</label>
                   <input type="number" value={data.block_image_text_paragraph_padding_bottom} onChange={(e) => setData({...data, block_image_text_paragraph_padding_bottom: e.target.value})}/>
                 </FormField> 
               </div>
               <div className="column-popup">
                 <FormField>
                   <label>Space Left</label>
                   <input type="number" value={data.block_image_text_paragraph_padding_left} onChange={(e) => setData({...data, block_image_text_paragraph_padding_left: e.target.value})}/>
                 </FormField> 
               </div>
               <div className="column-popup">
                 <FormField>
                   <label>Space Right</label>
                   <input type="number" value={data.block_image_text_paragraph_padding_right} onChange={(e) => setData({...data, block_image_text_paragraph_padding_right: e.target.value})}/>
                 </FormField> 
               </div>
              </Row>
              <Row>
                <div className="column-popup">
                  <FormField>
                    <label>Font Colour</label><br></br>
                    <SketchPicker onChange={(color) => { setBlockImageTextColor(color.rgb); setData({...data, block_image_text_color: color.rgb}); }} color={block_image_text_color} />
                  </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                    <label>Background Colour</label><br></br>
                    <SketchPicker onChange={(color) => { setBlockImageTextBackground(color.rgb); setData({...data, block_image_text_background: color.rgb}); }} color={block_image_text_background} />
                  </FormField>
                </div>
              </Row> 
            </>
            :
             ''
          }

          { customize.content === 'custom-img-1' ? 
            <>
              <h4>Custom Image 1 Styles</h4><br></br>
              <Row>
                <div className="column-popup">
                  <FormField>
                    <label>Shape</label>
                    <select value={data.two_image_first_shape} onChange={(e) => setData({...data, two_image_first_shape: e.target.value})}>
                      <option value="50%">Rounded</option>
                      <option value="0">Square</option>  
                    </select>
                  </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Border Line Thickness</label>
                   <input type="number" value={data.two_image_first_border_width} onChange={(e) => setData({...data, two_image_first_border_width: e.target.value})} />
                  </FormField>
                </div>
              </Row>  
              
              <Row>
                <div className="column-popup">
                  <FormField>
                    <label>Border Line Colour</label><br></br>
                    <SketchPicker onChange={(color) => { setTwoImageFirstBorderColor(color.rgb); setData({...data, two_image_first_border_color: color.rgb}); }} color={two_image_first_border_color} />
                  </FormField>
                </div>
              </Row> 
            </>
            :
             ''
          }

          { customize.content === 'custom-img-2' ? 
            <>
              <h4>Custom Image 2 Styles</h4><br></br>
              <Row>
                <div className="column-popup">
                  <FormField>
                    <label>Shape</label>
                    <select value={data.two_image_last_shape} onChange={(e) => setData({...data, two_image_last_shape: e.target.value})}>
                      <option value="50%">Rounded</option>
                      <option value="0">Square</option>  
                    </select>
                  </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Border Line Thickness</label>
                   <input type="number" value={data.two_image_last_border_width} onChange={(e) => setData({...data, two_image_last_border_width: e.target.value})} />
                  </FormField>
                </div>
              </Row>  
              
              <Row>
                <div className="column-popup">
                  <FormField>
                    <label>Border Line Colour</label><br></br>
                    <SketchPicker onChange={(color) => { setTwoImageLastBorderColor(color.rgb); setData({...data, two_image_last_border_color: color.rgb}); }} color={two_image_last_border_color} />
                  </FormField>
                </div>
              </Row> 
            </>
            :
             ''
          }

          { customize.content === 'block-image-text-img' ? 
            <>
              <h4>Customize Image</h4><br></br>              
              <Row>
                <div className="column-popup">
                  <label>Padding Top</label>
                  <input type="number" value={data.block_image_text_img_padding_top} onChange={(e) => setData({...data, block_image_text_img_padding_top: e.target.value})} />
                </div>
                <div className="column-popup">
                  <label>Padding Bottom</label>
                  <input type="number" value={data.block_image_text_img_padding_bottom} onChange={(e) => setData({...data, block_image_text_img_padding_bottom: e.target.value})}/>
                </div>
              </Row>
              <Row>
                <div className="column-popup">
                  <label>Padding Left</label>
                  <input type="number" value={data.block_image_text_img_padding_left} onChange={(e) => setData({...data, block_image_text_img_padding_left: e.target.value})} />
                </div>
                <div className="column-popup">
                  <label>Padding Right</label>
                  <input type="number" value={data.block_image_text_img_padding_right} onChange={(e) => setData({...data, block_image_text_img_padding_right: e.target.value})}/>
                </div>
              </Row>
              <br></br>
              <Row>
                <div className="column-popup">
                  <FormField>
                    <label>Image Line Thickness</label><br></br>
                    <input type="number" value={data.block_image_text_img_border_width} onChange={(e) => setData({...data, block_image_text_img_border_width: e.target.value})} />
                  </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                    <label>Image Line Colour</label><br></br>
                    <SketchPicker onChange={(color) => { setBlockImageTextImgBorderColor(color.rgb); setData({...data, block_image_text_img_border_color: color.rgb}); }} color={block_image_text_img_border_color} />
                  </FormField>
                </div>
              </Row> 
            </>
            :
             ''
          }

         { customize.content === 'custom-button' ? 
            <>
              <h4>Customize Button</h4><br></br>
              <Row>
                <div className="column-popup">
                 <FormField>
                  <label>Button Width</label><br></br>
                  <input type="number" value={data.custom_button_width} onChange={(e) => setData({...data, custom_button_width: e.target.value})} />
                 </FormField> 
                </div>
                <div className="column-popup">
                 <FormField>
                  <label>Button Height</label><br></br>
                  <input type="number" value={data.custom_button_height} onChange={(e) => setData({...data, custom_button_height: e.target.value})} />
                 </FormField> 
                </div>
              </Row>

              <Row>
                <div className="column-popup">
                 <FormField>
                  <label>Animation</label><br></br>
                  <select value={data.custom_button_animation} onChange={(e) => setData({...data, custom_button_animation: e.target.value})}>
                    <option value="1">Animation 1</option>
                    <option value="2">Animation 2</option>
                    <option value="3">Animation 3</option>
                    <option value="4">Animation 4</option>
                    <option value="5">Animation 5</option>
                  </select>
                 </FormField> 
                </div>
                <div className="column-popup">
                 <FormField>
                  <label>Font Size</label><br></br>
                  <input type="number" value={data.custom_button_font_size} onChange={(e) => setData({...data, custom_button_font_size: e.target.value})} />
                 </FormField> 
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Weight</label>
                   <input type="number" value={data.custom_button_font_weight} onChange={(e) => setData({...data, custom_button_font_weight: e.target.value})} />
                  </FormField>
                </div>
              </Row> 

              <Row>
                <div className="column-popup">
                  <FormField>
                   <label>Border Thickness</label>
                   <input type="number" value={data.custom_button_border_width} onChange={(e) => setData({...data, custom_button_border_width: e.target.value})} />
                  </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Border Radius</label>
                   <input type="number" value={data.custom_button_border_radious} onChange={(e) => setData({...data, custom_button_border_radious: e.target.value})} />
                  </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Alignment</label>
                   <select onChange={(e) => setData({...data, custom_button_position: e.target.value})}>
                     <option value="center">Center</option>
                     <option value="left">Left</option>
                     <option value="right">Right</option>
                     <option value="justify">Justify</option>
                   </select>
                 </FormField> 
                </div>
              </Row> 

              <Row>
                <div className="column-popup">
                  <FormField>
                   <label>Border Colour</label><br></br>
                   <SketchPicker onChange={(color) => { setCustomButtonBorderColor(color.rgb); setData({...data, custom_button_border_color: color.rgb}); }} color={custom_button_border_color} />
                  </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Colour</label><br></br>
                   <SketchPicker onChange={(color) => { setCustomButtonFontColor(color.rgb); setData({...data, custom_button_font_color: color.rgb}); }} color={custom_button_border_color} />
                  </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Button Colour</label><br></br>
                   <SketchPicker onChange={(color) => { setCustomButtonBackground(color.rgb); setData({...data, custom_button_background: color.rgb}); }} color={custom_button_background} />
                  </FormField>
                </div>
              </Row> 
            </>
            :
             ''
          }

          { customize.content === 'custom-button-second' ? 
            <>
              <h4>Customize Button</h4><br></br>
              <Row>
                <div className="column-popup">
                 <FormField>
                  <label>Button Width</label><br></br>
                  <input type="number" value={data.second_custom_button_width} onChange={(e) => setData({...data, second_custom_button_width: e.target.value})} />
                 </FormField> 
                </div>
                <div className="column-popup">
                 <FormField>
                  <label>Button Height</label><br></br>
                  <input type="number" value={data.second_custom_button_height} onChange={(e) => setData({...data, second_custom_button_height: e.target.value})} />
                 </FormField> 
                </div>
              </Row>
              <Row>
                <div className="column-popup">
                 <FormField>
                  <label>Animation</label><br></br>
                  <select value={data.second_custom_button_animation} onChange={(e) => setData({...data, second_custom_button_animation: e.target.value})}>
                    <option value="1">Animation 1</option>
                    <option value="2">Animation 2</option>
                    <option value="3">Animation 3</option>
                    <option value="4">Animation 4</option>
                    <option value="5">Animation 5</option>
                  </select>
                 </FormField> 
                </div>
                <div className="column-popup">
                 <FormField>
                  <label>Font Size</label><br></br>
                  <input type="number" value={data.second_custom_button_font_size} onChange={(e) => setData({...data, second_custom_button_font_size: e.target.value})} />
                 </FormField> 
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Weight</label>
                   <input type="number" value={data.second_custom_button_font_weight} onChange={(e) => setData({...data, second_custom_button_font_weight: e.target.value})} />
                  </FormField>
                </div>
              </Row> 

              <Row>
                <div className="column-popup">
                  <FormField>
                   <label>Border Thickness</label>
                   <input type="number" value={data.second_custom_button_border_width} onChange={(e) => setData({...data, second_custom_button_border_width: e.target.value})} />
                  </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Border Radius</label>
                   <input type="number" value={data.second_custom_button_border_radious} onChange={(e) => setData({...data, second_custom_button_border_radious: e.target.value})} />
                  </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Alignment</label>
                   <select onChange={(e) => setData({...data, second_custom_button_position: e.target.value})}>
                     <option value="center">Center</option>
                     <option value="left">Left</option>
                     <option value="right">Right</option>
                     <option value="justify">Justify</option>
                   </select>
                 </FormField> 
                </div>
              </Row> 

              <Row>
                <div className="column-popup">
                  <FormField>
                   <label>Border Colour</label><br></br>
                   <SketchPicker onChange={(color) => { setSecondCustomButtonBorderColor(color.rgb); setData({...data, second_custom_button_border_color: color.rgb}); }} color={second_custom_button_border_color} />
                  </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Colour</label><br></br>
                   <SketchPicker onChange={(color) => { setSecondCustomButtonFontColor(color.rgb); setData({...data, second_custom_button_font_color: color.rgb}); }} color={second_custom_button_border_color} />
                  </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Button Colour</label><br></br>
                   <SketchPicker onChange={(color) => { setSecondCustomButtonBackground(color.rgb); setData({...data, second_custom_button_background: color.rgb}); }} color={second_custom_button_background} />
                  </FormField>
                </div>
              </Row> 
            </>
            :
             ''
          }

          { customize.content === 'payment-button' ? 
            <>
              <h4>Customize Payment Button</h4><br></br>
              <Row>
                <div className="column-popup">
                 <FormField>
                  <label>Font Size</label><br></br>
                  <input type="number" value={data.payment_button_font_size} onChange={(e) => setData({...data, payment_button_font_size: e.target.value})} />
                 </FormField> 
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Weight</label>
                   <input type="number" value={data.payment_button_font_weight} onChange={(e) => setData({...data, payment_button_font_weight: e.target.value})} />
                  </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Height</label>
                   <input type="number" value={data.payment_button_height} onChange={(e) => setData({...data, payment_button_height: e.target.value})} />
                  </FormField>
                </div>
              </Row> 

              <Row>
                <div className="column-popup">
                  <FormField>
                   <label>Border Thickness</label>
                   <input type="number" value={data.payment_button_border_width} onChange={(e) => setData({...data, payment_button_border_width: e.target.value})} />
                  </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Border Radius</label>
                   <input type="number" value={data.payment_button_border_radious} onChange={(e) => setData({...data, payment_button_border_radious: e.target.value})} />
                  </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Alignment</label>
                   <select onChange={(e) => setData({...data, payment_button_text_align: e.target.value})}>
                     <option value="center">Center</option>
                     <option value="left">Left</option>
                     <option value="right">Right</option>
                     <option value="justify">Justify</option>
                   </select>
                 </FormField> 
                </div>
              </Row> 

              <Row>
                <div className="column-popup">
                  <FormField>
                   <label>Border Colour</label><br></br>
                   <SketchPicker onChange={(color) => { setPaymentButtonBorderColor(color.rgb); setData({...data, payment_button_border_color: color.rgb}); }} color={payment_button_border_color} />
                  </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Font Colour</label><br></br>
                   <SketchPicker onChange={(color) => { setPaymentButtonFontColor(color.rgb); setData({...data, payment_button_font_color: color.rgb}); }} color={payment_button_font_color} />
                  </FormField>
                </div>
                <div className="column-popup">
                  <FormField>
                   <label>Button Colour</label><br></br>
                   <SketchPicker onChange={(color) => { setPaymentButtonBackground(color.rgb); setData({...data, payment_button_background: color.rgb}); }} color={payment_button_background} />
                  </FormField>
                </div>
              </Row> 
            </>
            :
             ''
          }


        </div>
       </div> 
      </CustomizePreview>
      : ''
    }

    { download === 'prepare' ? 
      <PrepareDownload>
         <p style={{width: '100%', textAlign: 'center'}}>
           <img src={pulse} />
           <span>Preparing your card...</span>
         </p>
      </PrepareDownload>
      : ''
    }

    { download === 'complete' ?
       <CompleteDownload>
       <CloseRoundedIcon onClick={() => setDownload('')} style={{position: 'fixed', top: '10px', right: '10px', background: 'red', color: '#fff', borderRadius: '50%', fontSize: '32px', padding: '2px', cursor: 'pointer'}}/>
         <p style={{width: '100%', textAlign: 'center'}}>
           <img src={checkmark} />
           <span>The vcard is ready as Zip</span>
           <a href={download_link} download style={{
              color: '#fff',
              background: '#FF5722',
              padding: '11px 25px',
              marginTop: '10px',
              display: 'inline-block',
              fontWeight: '500',
              borderRadius: '4px',
           }}>Download</a>
         </p>
      </CompleteDownload>
      : ''
    }

    </>
  );
};

export default EditCard;

const CardTable = styled.div`
   div#table {
     margin: 0 12px;
     .column-main {
        display: flex;
        gap: 12px;
        input.table-column {
          border: 1px solid #cac6c6;
        }
     }
   }
`;

const Content = styled.div`
  margin-left: 230px;
  padding: 2.5rem 4rem;
  width: 100%;
  background: #fcfcfd;
`;

const StyledDashboard = styled.div`
  display: flex;
  height: 100%;
`;

const Gallery = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;

   .gallery-item {
      flex-basis: 48%;
      border: 1px solid rgb(238, 238, 238);
      padding: 12px;
      margin-bottom: 15px;
   }

   img {
     width: 100%;
     height: auto;
     object-fit: cover;
     margin: 6px;
     display: inline-block;
   }
`;

const CustomizePreview = styled.div`
   width: 100%;
   position: fixed;
   top:0;
   left:0;
   height: 100vh; 

    select,
  input {
    padding: 10px;
    outline: none;
    border-radius: 3px;
    border: 1px solid rgb(223 222 222);
    margin: 0.3rem 0;
    font-size: 14px;
    width: 100%;

    &:focus {
      border: 1px solid #ddd;
    }
  }

  select {
    color: rgb(95, 95, 95);
  }

  textarea {
    min-height:100px;
    width: 100%;
    padding: 10px;
    margin: 0.5rem 0 0;
    border: 1px solid rgb(223 222 222);
    border-radius: 3px;
    &:focus {
      border: 1px solid #ddd;
    }
    &:focus-visible {
      outline: none;
    }
  }

  label {
    font-size: 14px;
    font-weight: 500;

    svg {
      font-size: 15px;
          vertical-align: text-top;
    }
  }

  select {
    color: rgb(95, 95, 95);
  }
`;

const Row = styled.div`
   display: flex;
   height: 100%;

   .column-popup {
     width: 100%;
     margin: 0 10px;
   }
`;

const Footer = styled.div`
    width: 100%;
    text-align: center;
    padding: 15px 0;
    margin-top: 50px;

    a {
        font-size: 13px;
        text-decoration: none;

        svg { font-size: 13px; }
    }
`;

const CompleteDownload = styled.div`
   width:100%;
   height:100vh;
   position: fixed;
   background:#ffffffd4;
   display: flex;
   align-items: center;
   top:0;
   left:0;

   img {
     width: 70px;
   }
   span {
     display: block;
     margin-bottom:20px;
   }
`;

const PrepareDownload = styled.div`
   width:100%;
   height:100vh;
   position: fixed;
   background:#ffffffd4;
   display: flex;
   align-items: center;
   top:0;
   left:0;

   img {
     width: 70px;
   }
   span {
     display: block;
   }
`;

const Preview = styled.div`
   width: 100%;
   position: fixed;
   top:0;
   left:0;
   background:#f7f7f7;
   overflow-y: scroll;
   height: 100vh;
`;

const EditView = styled.div`
   width: 100%;
`;

const MobilePreview = styled.div`
  width: 100%;
  text-align: right;
`;

const Screen = styled.div`
    margin: 0 auto;
    height: auto;
    width: auto;
    display: inline-block;
    text-align: left;
    border-radius: 4rem;
    padding: 0.7rem;
    background: linear-gradient(45deg,#444,#111);
    box-shadow: 0 0px 30px rgb(0 0 0 / 20%);
    border: 0.3rem solid #444546;
    position: fixed;
    right:60px;
    top:40px;
`;

const Wrapper = styled.div`
      width: 375px;
      height: 725px;
      border-radius: 3rem;
      background: linear-gradient(45deg, #03a9f4, #fff);
      overflow-x: hidden;
`;

const TwoColumn = styled.div`
   display: flex;
   width: 100%;
`;

const Action = styled.div`
   width: 100%;
   display: flex;
   gap: 10px;
  margin-bottom: 25px;

   span {
      font-size: 13px;
      background: #eab389;
      border-radius: 4px;
      color: #fff;
      padding: 9px 12px;
      cursor: pointer;

      svg {
        font-size: 16px;
        vertical-align: bottom;
      }
   }

`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;

 select,
  input {
    padding: 10px;
    outline: none;
    border-radius: 3px;
    border: 1px solid rgb(223 222 222);
    margin: 0.3rem 0;
    font-size: 14px;
    margin-bottom: 10px;
        width: 100%;

    &:focus {
      border: 1px solid #ddd;
    }
  }

  select {
    color: rgb(95, 95, 95);
  }

  textarea {
    min-height:100px;
        width: 100%;
    padding: 10px;
    margin: 0.5rem 0;
    border: 1px solid rgb(223 222 222);
    border-radius: 3px;
    margin-bottom: 20px;
    &:focus {
      border: 1px solid #ddd;
    }
    &:focus-visible {
      outline: none;
    }
  }

  label {
    font-size: 14px;
    font-weight: 500;

    svg {
      font-size: 15px;
          vertical-align: text-top;
    }
  }

  select {
    color: rgb(95, 95, 95);
  }
`;

const StyledEditCard = styled.div`
  justify-content: space-between;
`;

const SubmitButton = styled.button`
   margin: 0 auto;
    width: 100%;
    font-size: 15px;
    padding: 12px 12px;
    border-radius: 3px;
    font-weight: 400;
    letter-spacing: 1.15px;
    background-color: #ff9800;
    color: #f9f9f9;
    border: none;
    outline: none;
    cursor: pointer;
`;

const Switch = styled.div`
  position: relative;
  margin-bottom: 10px;

input[type="checkbox"] {
      position: relative;
    width: 36px;
    height: 16px !important;
    -webkit-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #b2afaf;
    outline: none;
    border-radius: 2rem;
    cursor: pointer;
    padding: unset !important;
    border: unset;
    margin: unset;
    margin-right: 8px;
}

label {vertical-align: top;}

input[type="checkbox"]::before {
  content: "";
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  top: 1px;
  left: 1px;
  transition: 0.5s;
}

input[type="checkbox"]:checked::before {
  transform: translateX(144%);
  background: #fff;
}

input[type="checkbox"]:checked {
  background: #9c27b0;
}

`;

const SwitchSmall = styled.div`
  position: relative;
  line-height: 12px;
  margin-bottom: 15px;

input[type="checkbox"] {
    position: relative;
    width: 27px;
    height: 12px !important;
    -webkit-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: rgb(124 124 124);
    outline: none;
    border-radius: 2rem;
    cursor: pointer;
    padding: unset !important;
    border: unset;
    margin: unset;
    margin-right: 8px;
}

label {
  vertical-align: top;    
  font-size: 13px;
  color: #4f4f4f;
}

input[type="checkbox"]::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  top: 1px;
  left: 1px;
  transition: 0.5s;
}

input[type="checkbox"]:checked::before {
  transform: translateX(144%);
  background: #fff;
}

input[type="checkbox"]:checked {
  background: #08b347;
}
`;
