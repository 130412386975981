import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Outlet, useNavigate } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { url, setHeaders } from "../../slices/api";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Product = () => {

  const params = useParams(); 
  const navigate = useNavigate();
  const [product, setProduct] = useState({});
  const [prodImg, setProdImg] = useState('');
  const [shop, setShop] = useState({});
  const [gallery, setGallery] = useState([]);

  useEffect(() => {
    async function fetchData() {
       try {
          const response = await axios.get(`${url}/products/find/${params.id}`, setHeaders());
          setProduct(response.data);
          console.log(response.data);
          setProdImg(response.data.image.url);
          var galleryArr = response.data.gallery;
          setGallery(galleryArr);
          fetchShop(response.data.shop);
       } catch (error) {
          console.log(error);
       }
    }
    
    async function fetchShop(shopID) {
       try {
          const response = await axios.get(`${url}/shops/find/${shopID}`, setHeaders());
          console.log(response.data);
          setShop(response.data);
       } catch (error) {
          console.log(error);
       }
    }

    fetchData();
  }, []);

  return (
    <>
      <Container>
        <PrimaryButton onClick={() => navigate("/admin/products")} >
          <ArrowBackIcon/> Go back to Product List
        </PrimaryButton>
        <Details>
        <table>
          <tr>
            <td><img src={prodImg} style={{width:"100%", padding : "20px"}} /></td>
            <td>
              <ProdDetails>
                 <p>{product.name}</p>
                 <p><b>Price:</b> Rs. {product.price}</p>
                 <p><b>Shop:</b> {shop.name}</p>
                 <p><b>Brand:</b> {product.brand}</p>
                 <p style={{marginTop: '22px', color : '#888'}}>{product.desc}</p>
                 
              </ProdDetails>
            </td>
          </tr>
          <tr>
            <td colspan="2">
            <h3>Product Gallery</h3>
              {gallery && (
                 <Gallery>
                 { gallery.map((gl) => {
                     return(
                         <img src={gl.url} />
                      )
                   })
                 }
                 </Gallery>          
                 )}
            </td>
          </tr>
        </table>
        </Details>
       
      </Container>
    </>
  );

}

export default Product;

const Container = styled.div`
  width: 70%;
  display: block;
  margin: 0 auto;
  padding: 20px;
  margin-top: 7px;
  border-radius: 10px;
  justify-content: space-between;
  button {
    border: none;
    outline: none;
    padding: 5px 10px;
    color: white;
    border-radius: 3px;
    cursor: pointer;
  }
`;

const PrimaryButton = styled.button`
  padding: 9px 12px;
  border-radius: 5px;
  font-weight: 400;
  letter-spacing: 1.15px;
  background-color: #4b70e2;
  color: #f9f9f9;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 0.5rem 0;
  svg {
    vertical-align: middle;
  }
`;

const Details = styled.div`
  margin-top: 25px;
  table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0px 0px 22px 0px #cecece;
    border-radius: 10px;
    td {
      padding: 16px;
      font-size: 16px;
    } 
    td:first-child {
      width: 50%;
      vertical-align: baseline;
    }
    td:last-child {
      vertical-align: top;
      padding-top: 25px;
    }
  }
`;

const ProdDetails = styled.div`
    padding-left: 20px;
    p {
      margin-bottom: 10px;
      font-size: 16px;
    }
    p:first-child {
      font-size: 25px;
      font-weight: 600;
      margin-bottom: 20px;
      color: #5d5b5b;
    }
`;

const Gallery = styled.div`
    margin-top: 15px;
  img {
    float: left;
    margin-left: 15px;
    margin-bottom: 20px;
    width: 47%;
    height: 300px;
     object-fit: cover;
   }
`;

const View = styled.button`
  background-color: #5ebf63;
`;

const Edit = styled.button`
  background-color: #009688;
`;

const Delete = styled.button`
  background-color: #f44336;
`;


