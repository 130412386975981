import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import styled from "styled-components";
import './../App.css';
import AddIcon from '@mui/icons-material/Add';
import LoginIcon from '@mui/icons-material/Login';
import logo from './images/logo1.png';
import nfcleadhome from './images/nfcleadhome.jpg';

const Home = () => {
  const navigate = useNavigate();

  return (
    <>
      <Main>
        <div style={{width: '100%'}}>
          
<nav class="navbar navbar-expand-lg navbar-light bg-white border-bottom border-gray-100 ">
    <div class="container">
        <a class="navbar-brand" href="/"> <img src={logo} /></a>

        <div class="collapse navbar-collapse justify-content-end" id="main_navbar">
            <ul class="navbar-nav">
              <li class="nav-item">
                <a class="nav-link text-primary" href="/login"><LoginIcon/> Sign in</a>
              </li>  
            </ul>
        </div>
    </div>
</nav>

<div id="custom-home">
   <img src={nfcleadhome} />    
   <p class="custom-footer">nfcleads.au Copyright © 2024</p> 
</div>

        </div>
      </Main>
    </>
  );
};

export default Home;

const Main = styled.div`
   width: 100%;
   align-items: center;
   display: flex;
   justify-content: center;
`;
