import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import paypal from "./images/paypal.png";
import {
  addToCart,
  clearCart,
  decreaseCart,
  getTotals,
  removeFromCart,
} from "../slices/cartSlice";

import { Link } from "react-router-dom";
import PayButton from "./PayButton";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const Cart = () => {
  const cart = useSelector((state) => state.cart);
  const auth = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getTotals());
  }, [cart, dispatch]);

  const handleAddToCart = (product) => {
    dispatch(addToCart(product));
  };
  const handleDecreaseCart = (product) => {
    dispatch(decreaseCart(product));
  };
  const handleRemoveFromCart = (product) => {
    dispatch(removeFromCart(product));
  };
  const handleClearCart = () => {
    dispatch(clearCart());
  };
  return (
    <>
      
        <div className="cart-container">
        <h4 style={{paddingBottom: '12px', borderBottom: '1px solid #dbdbdb'}}>Your Cart</h4>
        {cart.cartItems.length !== 0 ? 
        <div>
          <div className="cart-items">
            {cart.cartItems &&
              cart.cartItems.map((cartItem) => (
                <div className="cart-item" key={cartItem._id}>
                  <div className="remove-from-cart">
                   <CloseRoundedIcon onClick={() => handleRemoveFromCart(cartItem)}/>
                  </div>
                  <div className="cart-product">
                    <div>
                      <h3>{cartItem.title}</h3>
                    </div>
                  </div>
                  <div className="cart-product-quantity">
                    <button onClick={() => handleDecreaseCart(cartItem)}>
                      -
                    </button>
                    <div className="count">{cartItem.cartQuantity}</div>
                    <button onClick={() => handleAddToCart(cartItem)}>+</button>
                  </div>
                  <div className="cart-product-total-price">
                    ${cartItem.price * cartItem.cartQuantity}
                  </div>
                </div>))
            }

          </div>
          <div className="cart-summary">
            <button className="clear-btn" onClick={() => handleClearCart()}>
              Clear Cart
            </button>
            <div className="cart-checkout">
              <div className="subtotal">
                <span>Subtotal:</span>
                <span className="amount">${cart.cartTotalAmount}</span>
              </div> 
              <div className="subtotal">
                <span>GST(10%):</span>
                <span className="amount">${(cart.cartTotalAmount/10).toFixed()}</span>
              </div> 
              <div className="subtotal">
                <span>Total:</span>
                <span className="amount">${Number(cart.cartTotalAmount) + Number((cart.cartTotalAmount/10).toFixed())}</span>
              </div>             
            </div>
          </div>
          <div id="paypal-btn">
            <button onClick={() => alert('Please download the vcard first')}><img src={paypal} /></button>
          </div>
        </div>
        :
        <>
        <br></br>
        <p>Your cart is empty</p>
        </>
      }
        </div>
      
    </>
  );
};

export default Cart;
