import { useState } from "react";
import styled from "styled-components";

const TemplateTwo = (props) => {

  var info = props.info;

 
  return (
    <>
    
    { info.name !== '' ? 
      <Field>
       <h1>{info.name}</h1>
      </Field>
       : '' 
    }

    { info.phone !== '' ? 
      <Field>
       <h1>{info.phone}</h1>
      </Field>
       : '' 
    }
    
    </>
  );
};

export default TemplateTwo;

const Field = styled.div`
    background: orange;
    font-size: 16px;
    padding: 30px;
    margin-bottom: 15px;

    h1 {
      color: blue;
    }
`;