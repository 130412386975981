import { useState, useEffect } from "react";
import { addToCart } from "../../../slices/cartSlice";
import Cart from "../../Cart";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import profile from "./profile.png";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import GoogleMapReact from 'google-map-react';
import Carousel from 'react-multi-carousel';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import 'react-multi-carousel/lib/styles.css';
import './test.css';
import MobileScreenShareIcon from '@mui/icons-material/MobileScreenShare';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SmsIcon from '@mui/icons-material/Sms';
import LanguageIcon from '@mui/icons-material/Language';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { SRLWrapper } from "simple-react-lightbox";
import Accordion1 from './Accordion1';
import skype from "./icons/skype.png";
import messenger from "./icons/messenger.png"; 
import viber from "./icons/viber.png";
import telegram from "./icons/telegram.png";
import twitter from "./icons/twitter.png";
import pinterest from "./icons/pinterest.png";
import snapchat from "./icons/snapchat.png";
import tumblr from "./icons/tumblr.png";
import wechat from "./icons/wechat.png";
import youtube from "./icons/youtube.png";
import reddit from "./icons/reddit.png";
import tiktok from "./icons/tiktok.png";
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import productImage from "./product-image.jpg";


const PreviewTemplate = (props) => {

  var info = props.info;
  var testimonial = props.info.testimonial;
  var accordions = info.accordions;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const cart = useSelector((state) => state.cart); 
  const handleAddToCart = (product) => {
    dispatch(addToCart(product));
  };

  var image_grid = props.info.image_grid;
  var image_grid_column = info.image_grid_column;
  const [gallery_image_style, setGalleryImageStyle] = useState({width: 0});
  useEffect(() => {
    if(image_grid_column === '1'){
       setGalleryImageStyle({...gallery_image_style, width: 439});
    }else if(image_grid_column === '2'){
       setGalleryImageStyle({...gallery_image_style, width: 215});
    }else{
       setGalleryImageStyle({...gallery_image_style, width: 140});
    }    
  }, [image_grid_column]);

  const [startDate, setStartDate] = useState(new Date());
  const [time, onChange] = useState('10:30');
  //var mapLink = "https://maps.google.com/maps?q=" + info.latitude + "," + info.longitude + "&hl=es&z=14&amp;output=embed&language=en";
 
 const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};

 // order blocks  
  var blocks = info.blocks;
 // const [save_contact_order, setSaveContactOrder] = useState(1);
  const [audio_order, setAudioOrder] = useState(2);
  const [two_image_order, setTwoImageOrder] = useState(3);
  const [image_text_order, setImageTextOrder] = useState(4);
  const [table_order, setTableOrder] = useState(5);
  const [accordion_order, setAccordionOrder] = useState(6);
  const [video_order, setVideoOrder] = useState(7);
  const [testimonial_order, setTestimonialOrder] = useState(8);
  const [gallery_order, setGalleryOrder] = useState(9);
  const [custom_text_order, setCustomTextOrder] = useState(10);
  const [custom_image_order, setCustomImageOrder] = useState(11); 
  const [map_order, setMapOrder] = useState(12);
  const [appointment_order, setAppointmentOrder] = useState(13);
  const [enquiry_order, setEnquiryOrder] = useState(14);
  const [iframe_order, setIframeOrder] = useState(15);
  const [text_between_line_order, setTextBetweenLineOrder] = useState(16);
  const [custom_button_order, setCustomButtonOrder] = useState(17);
  const [second_text_between_line_order, setSecondTextBetweenLineOrder] = useState(18);
  const [third_text_between_line_order, setThirdTextBetweenLineOrder] = useState(19);
  const [fourth_text_between_line_order, setFourthTextBetweenLineOrder] = useState(20);
  const [second_iframe_order, setSecondIframeOrder] = useState(21);
  const [third_iframe_order, setThirdIframeOrder] = useState(22);
  const [fourth_iframe_order, setFourthIframeOrder] = useState(23);
  const [second_custom_button_order, setSecondCustomButtonOrder] = useState(24);
  const [second_custom_text_order, setSecondCustomTextOrder] = useState(25);
  const [second_custom_image_order, setSecondCustomImageOrder] = useState(26); 
  const [payment_button_order, setPaymentButtonOrder] = useState(27); 

  // Accordian
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  
  useEffect(() => {
    console.log(blocks);
    blocks.forEach((value, index) => {
      console.log(value.name);
      if(value.name === 'Testimonial'){
           setTestimonialOrder(index + 1);
      }
      if(value.name === 'Custom Image'){
           setCustomImageOrder(index + 1);
      }
      if(value.name === 'Custom Image 2'){
           setSecondCustomImageOrder(index + 1);
      }
      if(value.name === 'Gallery'){
           setGalleryOrder(index + 1);
      }
      if(value.name === 'Custom Text'){
           setCustomTextOrder(index + 1);
      }
      if(value.name === 'Custom Text 2'){
           setSecondCustomTextOrder(index + 1);
      }
      if(value.name === 'Google Map Location'){
           setMapOrder(index + 1);
      }
      if(value.name === 'Video'){
          setVideoOrder(index + 1)
      }
      if(value.name === 'Appointment Form'){
           setAppointmentOrder(index + 1);
      }
      if(value.name === 'Enquiry Form'){
           setEnquiryOrder(index + 1);
      }
      if(value.name === 'Accordian'){
           setAccordionOrder(index + 1);
      }
      if(value.name === 'Table'){
           setTableOrder(index + 1);
      }
      if(value.name === 'Two Image'){
           setTwoImageOrder(index + 1);
      }
      if(value.name === 'Image Text'){
           setImageTextOrder(index + 1);
      }
      if(value.name === 'Audio'){
           setAudioOrder(index + 1);
      }
      if(value.name === 'Iframe'){
           setIframeOrder(index + 1);
      }
      if(value.name === 'text between line'){
           setTextBetweenLineOrder(index + 1);
      }
      if(value.name === 'Custom Button'){
           setCustomButtonOrder(index + 1);
      }
      if(value.name === 'Custom Button 2'){
           setSecondCustomButtonOrder(index + 1);
      }
      if(value.name === 'text between line 2'){
           setSecondTextBetweenLineOrder(index + 1);
      }
      if(value.name === 'text between line 3'){
           setThirdTextBetweenLineOrder(index + 1);
      }
      if(value.name === 'text between line 4'){
           setFourthTextBetweenLineOrder(index + 1);
      }
      if(value.name === 'Iframe 2'){
           setSecondIframeOrder(index + 1);
      }
      if(value.name === 'Iframe 3'){
           setThirdIframeOrder(index + 1);
      }
      if(value.name === 'Iframe 4'){
           setFourthIframeOrder(index + 1);
      }
      if(value.name === 'Payment Button'){
           setPaymentButtonOrder(index + 1);
      }
      
    })
  }, [blocks]);


 useEffect(() => { 
   if(info.video !== 0 && info.videolink !== ''){
       document.getElementById("v-intro").load();
   }

   //mapLink = "https://maps.google.com/maps?q=37.42216,-122.08427&hl=es&z=14&amp;output=embed&language=en";
 }, [info.videolink]);

//console.log(info.background_color);

const [vcard_background, setVcardBackground] = useState('');
useEffect(() => {
  if(info.vcard_gradient === 'no') {
      setVcardBackground(`rgba(${info.background_color.r},${info.background_color.g},${info.background_color.b},${info.background_color.a})`);
  }else{
    if(info.vcard_gradient_type === 'linear'){
        setVcardBackground(`linear-gradient(${info.vcard_gradient_angle}deg, rgba(${info.vcard_gradient_color_1.r},${info.vcard_gradient_color_1.g},${info.vcard_gradient_color_1.b},${info.vcard_gradient_color_1.a}) 25%, rgba(${info.vcard_gradient_color_2.r},${info.vcard_gradient_color_2.g},${info.vcard_gradient_color_2.b},${info.vcard_gradient_color_2.a}) 64%)`);      
    }else{
      setVcardBackground(`radial-gradient(circle, rgba(${info.vcard_gradient_color_1.r},${info.vcard_gradient_color_1.g},${info.vcard_gradient_color_1.b},${info.vcard_gradient_color_1.a}) 25%, rgba(${info.vcard_gradient_color_2.r},${info.vcard_gradient_color_2.g},${info.vcard_gradient_color_2.b},${info.vcard_gradient_color_2.a}) 64%)`);
    }
  }

}, [info.vcard_gradient,
    info.background_color, 
    info.vcard_gradient_color_1,
    info.vcard_gradient_color_2, 
    info.vcard_gradient_angle, 
    info.vcard_gradient_type
   ]
)

const [iframe_border, setIframeBorder] = useState(`${info.iframe_border_width}px ${info.iframe_border_type} rgba(${info.iframe_border_color.r}, ${info.iframe_border_color.g}, ${info.iframe_border_color.b}, ${info.iframe_border_color.a})`);
useEffect(() => {
  if(info.iframe_show_border === 'yes'){
      setIframeBorder(`${info.iframe_border_width}px ${info.iframe_border_type} rgba(${info.iframe_border_color.r}, ${info.iframe_border_color.g}, ${info.iframe_border_color.b}, ${info.iframe_border_color.a})`);
  }else{
    setIframeBorder('0');
  }
}, [info.iframe_show_border,
    info.iframe_border_width,
    info.iframe_border_color,
    info.iframe_border_type])

const [second_iframe_border, setSecondIframeBorder] = useState(`${info.second_iframe_border_width}px ${info.second_iframe_border_type} rgba(${info.second_iframe_border_color.r}, ${info.second_iframe_border_color.g}, ${info.second_iframe_border_color.b}, ${info.second_iframe_border_color.a})`);
useEffect(() => {
  if(info.second_iframe_show_border === 'yes'){
      setSecondIframeBorder(`${info.second_iframe_border_width}px ${info.second_iframe_border_type} rgba(${info.second_iframe_border_color.r}, ${info.second_iframe_border_color.g}, ${info.second_iframe_border_color.b}, ${info.second_iframe_border_color.a})`);
  }else{
    setSecondIframeBorder('0');
  }
}, [info.second_iframe_show_border,
    info.second_iframe_border_width,
    info.second_iframe_border_color,
    info.second_iframe_border_type])

const [third_iframe_border, setThirdIframeBorder] = useState(`${info.third_iframe_border_width}px ${info.third_iframe_border_type} rgba(${info.third_iframe_border_color.r}, ${info.third_iframe_border_color.g}, ${info.third_iframe_border_color.b}, ${info.third_iframe_border_color.a})`);
useEffect(() => {
  if(info.third_iframe_show_border === 'yes'){
      setThirdIframeBorder(`${info.third_iframe_border_width}px ${info.third_iframe_border_type} rgba(${info.third_iframe_border_color.r}, ${info.third_iframe_border_color.g}, ${info.third_iframe_border_color.b}, ${info.third_iframe_border_color.a})`);
  }else{
    setThirdIframeBorder('0');
  }
}, [info.third_iframe_show_border,
    info.third_iframe_border_width,
    info.third_iframe_border_color,
    info.third_iframe_border_type])

const [fourth_iframe_border, setFourthIframeBorder] = useState(`${info.fourth_iframe_border_width}px ${info.fourth_iframe_border_type} rgba(${info.fourth_iframe_border_color.r}, ${info.fourth_iframe_border_color.g}, ${info.fourth_iframe_border_color.b}, ${info.fourth_iframe_border_color.a})`);
useEffect(() => {
  if(info.fourth_iframe_show_border === 'yes'){
      setFourthIframeBorder(`${info.fourth_iframe_border_width}px ${info.fourth_iframe_border_type} rgba(${info.fourth_iframe_border_color.r}, ${info.fourth_iframe_border_color.g}, ${info.fourth_iframe_border_color.b}, ${info.fourth_iframe_border_color.a})`);
  }else{
    setFourthIframeBorder('0');
  }
}, [info.fourth_iframe_show_border,
    info.fourth_iframe_border_width,
    info.fourth_iframe_border_color,
    info.fourth_iframe_border_type])

  return (
    <>
    <Container>
     <div style={{minHeight: '100%', background: vcard_background}}>
     { info.company_logo_show === 0 ?
      <Intro>
         { info.header_img !== '' ?
           <img src={'https://nfcleads.au/nodeapi/uploads/' + info.header_img} />
           :
           '' 
         }
         { info.profile_pic !== '' ? 
         <div style={{
                textAlign: `${info.profile_pic_horizontal_pos}`,
                width: '100%',
                position: 'absolute',
                top: `${info.profile_pic_vertical_pos}%`,
                padding: '0 20px',
              }}>  
           <img style={{
                borderRadius: `${info.profile_pic_shape}`,
                width: '150px',
                height: '150px',
                border: `${info.profile_pic_border_width}px solid rgba(${info.profile_pic_border_color.r}, ${info.profile_pic_border_color.g}, ${info.profile_pic_border_color.b}, ${info.profile_pic_border_color.a})`,
           }} src={'https://nfcleads.au/nodeapi/uploads/' + info.profile_pic} />
         </div>
         : ''}
      </Intro>
      : ''
      }

      { info.company_logo_show === 1 ?
        <>
        { info.company_logo !== '' ?
        <div style={{
           width: '100%',
           textAlign: `${info.company_logo_horizontal_pos}`,
           paddingTop: `${info.company_logo_vertical_pos}px`,
           paddingLeft: '20px',
           paddingRight: '20px',
         }}>  
           <img style={{
                borderRadius: `${info.company_logo_shape}`,
                width: '100px',
                border: `${info.company_logo_border_width}px solid rgba(${info.company_logo_border_color.r}, ${info.company_logo_border_color.g}, ${info.company_logo_border_color.b}, ${info.company_logo_border_color.a})`,
           }} src={'https://nfcleads.au/nodeapi/uploads/' + info.company_logo} />
         </div>
         : ''
         }
         </>
        :
       ''
      }
      
      { info.firstname !== '' && info.lastname !== '' ?
        <h3 style={{
          color: `rgba(${info.name_color.r}, ${info.name_color.g}, ${info.name_color.b}, ${info.name_color.a})`,
          textAlign: `${info.name_text_align}`, 
          padding: '0 20px',
          paddingTop: `${info.name_margin_top}px`,
          fontWeight: `${info.font_weight_name}`, 
          fontSize: `${info.font_size_name}px`, 
          fontStyle: `${info.font_style_name}`,
          textDecoration: `${info.font_underline_name}`,
          fontFamily: `${info.font_family}`}}>{info.firstname} {info.lastname}</h3>
        : ''    
      }
      { info.jobtitle !== '' ?
        <p style={{
          color: `rgba(${info.jobtitle_color.r}, ${info.jobtitle_color.g}, ${info.jobtitle_color.b}, ${info.jobtitle_color.a})`,
          textAlign: `${info.jobtitle_text_align}`, 
          marginTop: '5px', 
          fontWeight: `${info.font_weight_jobtitle}`,
          fontSize: `${info.font_size_jobtitle}px`, 
          fontStyle: `${info.font_style_jobtitle}`,
          textDecoration: `${info.font_underline_jobtitle}`,
          fontFamily: `${info.font_family}`}}>{info.jobtitle}</p>
        : ''
      }
      { info.company !== '' ?
        <p style={{
          color: `rgba(${info.company_color.r}, ${info.company_color.g}, ${info.company_color.b}, ${info.company_color.a})`,
          textAlign: `${info.company_text_align}`, 
          marginTop: '5px', 
          fontSize: `${info.font_size_company}px`,
          fontWeight: `${info.font_weight_company}`,  
          fontStyle: `${info.font_style_company}`,
          textDecoration: `${info.font_underline_company}`,
          fontFamily: `${info.font_family}`}}>{info.company}</p>
        : ''
      }
      { info.address !== '' ?
        <p style={{
            color: `rgba(${info.address_color.r}, ${info.address_color.g}, ${info.address_color.b}, ${info.address_color.a})`,
            fontWeight: `${info.font_weight_address}`,
            fontStyle: `${info.font_style_address}`,
            textDecoration: `${info.font_underline_address}`,
            textAlign: `${info.address_text_align}`,
            marginTop: '5px',
            fontSize: `${info.font_size_address}px`,
            fontFamily: `${info.font_family}`}}>{info.address}</p>
        : ''
      }
      { info.description !== '' ?
        <p style={{
             color: `rgba(${info.description_color.r}, ${info.description_color.g}, ${info.description_color.b}, ${info.description_color.a})`,
             textAlign: `${info.description_text_align}`, 
             padding: `${info.description_padding_top}px 20px ${info.description_padding_bottom}px`, 
             fontSize: `${info.font_size_description}px`, 
             fontWeight: `${info.font_weight_description}`,
             fontStyle: `${info.font_style_description}`,
             lineHeight: `${info.description_line_height}px`, 
             textDecoration: `${info.font_underline_description}`,
             fontFamily: `${info.font_family}`}} dangerouslySetInnerHTML={{__html: info.description}}></p>
        : ''
      }

      { info.firstname !== '' && info.lastname !== '' ?
         <SaveContact style={{padding: `${info.save_contact_button_padding_top}px 20px ${info.save_contact_button_padding_bottom}px 20px`}}>
          <a onClick={() => alert('Please download the vcard and host the index file on your server, then try it.')} style={{
              background: `rgba(${info.save_contact_button_background.r}, ${info.save_contact_button_background.g}, ${info.save_contact_button_background.b}, ${info.save_contact_button_background.a})`,
              padding: '16px',
              borderColor: `rgba(${info.save_contact_button_border_color.r}, ${info.save_contact_button_border_color.g}, ${info.save_contact_button_border_color.b}, ${info.save_contact_button_border_color.a})`,
              border: `${info.save_contact_button_border_width}px solid`,
              borderRadius: `${info.save_contact_button_border_radious}px`,
              color: `rgba(${info.save_contact_button_font_color.r}, ${info.save_contact_button_font_color.g}, ${info.save_contact_button_font_color.b}, ${info.save_contact_button_font_color.a})`,
              textAlign: 'center',
              fontSize: `${info.save_contact_button_font_size}px`,
              fontWeight: `${info.save_contact_button_font_weight}`,
              cursor: 'pointer',
              width: '100%',
              boxShadow: '1px 1px 10px 1px #d2d0d0'
          }}><SaveAltIcon/> Save Contact</a>
        </SaveContact>
         : 
         ''
       }

      <ContactDetails>
       <div style={{padding: `${info.contact_details_padding_top}px 0 ${info.contact_details_padding_bottom}px 0`, display: 'flex', flexWrap: 'wrap'}}>
        { info.number_mobile !== '' ?
          <Contact>
           <a href={'tel:' + info.number} target="_blank"><MobileScreenShareIcon style={{background: '#607d8b'}}/></a>
           <p style={{fontFamily: `${info.font_family}`}}>Mobile</p>
          </Contact>
          : ''
        }
        { info.number_sms !== '' ?
          <Contact>
           <a href={'SMS:' + info.number} target="_blank"><SmsIcon style={{background: '#0fbaaa'}}/></a>
           <p style={{fontFamily: `${info.font_family}`}}>SMS</p>
          </Contact>
          : ''
        }
        { info.number_whatsapp !== '' ?
          <Contact>
           <a href={'https://wa.me/' + info.number} target="_blank"><WhatsAppIcon style={{background: '#14ca4e'}}/></a>
           <p style={{fontFamily: `${info.font_family}`}}>WhatsApp</p>
          </Contact>
          : ''
        }
        { info.email !== '' ?
          <Contact>
           <a href={ 'mailto:' + info.email} target="_blank"><EmailIcon style={{background: '#3f51b5'}}/></a>
             <p style={{fontFamily: `${info.font_family}`}}>Email</p>
          </Contact>
          : ''
        }
        { info.website !== '' ?
          <Contact>
           <a href={info.website} target="_blank"><LanguageIcon style={{background: '#9c27b0'}}/></a>
           <p style={{fontFamily: `${info.font_family}`}}>Website</p>
          </Contact>
          : ''
        }
        { info.location !== '' ?
          <Contact>
           <a href={info.location} target="_blank"><LocationOnIcon style={{background: '#f44336'}}/></a>
           <p style={{fontFamily: `${info.font_family}`}}>Location</p>
          </Contact>
          : ''
        }
        { info.facebook !== '' ?
          <Contact>
           <a href={info.facebook} target="_blank"><FacebookIcon style={{background: '#3c93d9'}}/></a>
           <p style={{fontFamily: `${info.font_family}`}}>Facebook</p>
          </Contact>
          : ''
        }
        { info.instagram !== '' ?
          <Contact>
           <a href={info.instagram} target="_blank"><InstagramIcon style={{background: '#e91e63'}}/></a>
           <p style={{fontFamily: `${info.font_family}`}}>Instagram</p>
          </Contact>
          : ''
        }
        { info.linkedin !== '' ?
          <Contact>
           <a href={info.linkedin} target="_blank"><LinkedInIcon style={{background: '#158abf'}}/></a>
           <p style={{fontFamily: `${info.font_family}`}}>LinkedIn</p>
          </Contact>
          : ''
        }
                { info.skype !== '' ?
          <Contact>
           <a href={info.skype} target="_blank"><img src={skype} style={{width: '45px'}}/></a>
           <p style={{fontFamily: `${info.font_family}`}}>Skype</p>
          </Contact>
          : ''
        }
        { info.messenger !== '' ?
          <Contact>
           <a href={info.messenger} target="_blank"><img src={messenger} style={{width: '45px'}}/></a>
           <p style={{fontFamily: `${info.font_family}`}}>Messenger</p>
          </Contact>
          : ''
        }
        { info.pinterest !== '' ?
          <Contact>
           <a href={info.pinterest} target="_blank"><img src={pinterest} style={{width: '45px'}}/></a>
           <p style={{fontFamily: `${info.font_family}`}}>Pinterest</p>
          </Contact>
          : ''
        }
        { info.twitter !== '' ?
          <Contact>
           <a href={info.twitter} target="_blank"><img src={twitter} style={{width: '45px'}}/></a>
           <p style={{fontFamily: `${info.font_family}`}}>Twitter</p>
          </Contact>
          : ''
        }
        { info.telegram !== '' ?
          <Contact>
           <a href={info.telegram} target="_blank"><img src={telegram} style={{width: '45px'}}/></a>
           <p style={{fontFamily: `${info.font_family}`}}>Telegram</p>
          </Contact>
          : ''
        }
        { info.snapchat !== '' ?
          <Contact>
           <a href={info.snapchat} target="_blank"><img src={snapchat} style={{width: '45px'}}/></a>
           <p style={{fontFamily: `${info.font_family}`}}>Snapchat</p>
          </Contact>
          : ''
        }
        { info.viber !== '' ?
          <Contact>
           <a href={info.viber} target="_blank"><img src={viber} style={{width: '45px'}}/></a>
           <p style={{fontFamily: `${info.font_family}`}}>Viber</p>
          </Contact>
          : ''
        }
        { info.tumblr !== '' ?
          <Contact>
           <a href={info.tumblr} target="_blank"><img src={tumblr} style={{width: '45px'}}/></a>
           <p style={{fontFamily: `${info.font_family}`}}>Tumblr</p>
          </Contact>
          : ''
        }
        { info.wechat !== '' ?
          <Contact>
           <a href={info.wechat} target="_blank"><img src={wechat} style={{width: '45px'}}/></a>
           <p style={{fontFamily: `${info.font_family}`}}>Wechat</p>
          </Contact>
          : ''
        }
        { info.youtube !== '' ?
          <Contact>
           <a href={info.youtube} target="_blank"><img src={youtube} style={{width: '45px'}}/></a>
           <p style={{fontFamily: `${info.font_family}`}}>YouTube</p>
          </Contact>
          : ''
        }
        { info.reddit !== '' ?
          <Contact>
           <a href={info.reddit} target="_blank"><img src={reddit} style={{width: '45px'}}/></a>
           <p style={{fontFamily: `${info.font_family}`}}>Reddit</p>
          </Contact>
          : ''
        }
        { info.tiktok !== '' ?
          <Contact>
           <a href={info.tiktok} target="_blank"><img src={tiktok} style={{width: '45px'}}/></a>
           <p style={{fontFamily: `${info.font_family}`}}>Tiktok</p>
          </Contact>
          : ''
        }
       </div> 
      </ContactDetails>

     <div style={{display: 'flex', flexWrap: 'wrap'}}>
      { info.audio === 1 ?
        <AudioIntro style={{padding: `${info.audio_padding_top}px 20px ${info.audio_padding_bottom}px 20px`, order: `${audio_order}`}}>
         { info.audio_title === 1 ?
           <h3 style={{
                  fontFamily: `${info.font_family}`,
                  fontSize: `${info.font_size_audio_title}px`,
                  fontWeight: `${info.font_weight_audio_title}`,
                  fontStyle: `${info.font_style_audio_title}`,
                  padding: `${info.audio_title_padding_top}px 30px ${info.audio_title_padding_bottom}px 30px`,
                  textDecoration: `${info.font_underline_audio_title}`,
                  color: `rgba(${info.audio_title_color.r}, ${info.audio_title_color.g}, ${info.audio_title_color.b}, ${info.audio_title_color.a})`,
                  textAlign: `${info.audio_title_text_align}`
               }}>{info.audio_title_label}</h3>
           : ''
         }
         <audio id="v-intro" width="100%" height="auto" controls>
           <source src={info.audiolink} type="video/mp4"/>
         </audio>
        </AudioIntro>
        : ''
      }

      { info.video === 1 ?
        <VideoIntro style={{padding: `${info.video_padding_top}px 20px ${info.video_padding_bottom}px 20px`, order: `${video_order}`}}>
         { info.video_title === 1 ?
           <h3 style={{
                  fontFamily: `${info.font_family}`,
                  fontSize: `${info.font_size_video_title}px`,
                  fontWeight: `${info.font_weight_video_title}`,
                  fontStyle: `${info.font_style_video_title}`,
                  padding: `${info.video_title_padding_top}px 30px ${info.video_title_padding_bottom}px 30px`,
                  textDecoration: `${info.font_underline_video_title}`,
                  color: `rgba(${info.video_title_color.r}, ${info.video_title_color.g}, ${info.video_title_color.b}, ${info.video_title_color.a})`,
                  textAlign: `${info.video_title_text_align}`
               }}>{info.video_title_label}</h3>
           : ''
         }
         <video id="v-intro" width="100%" height="auto" controls>
           <source src={info.videolink} type="video/mp4"/>
         </video>
        </VideoIntro>
        : ''
      }

      { info.showTestimony === 1 ?
        <Testimonial style={{padding: `${info.testimonial_padding_top}px 0 ${info.testimonial_padding_bottom}px 0`, order: `${testimonial_order}`}}>
          { info.testimonial_title === 1 ?
           <h3 style={{
                  fontFamily: `${info.font_family}`,
                  fontSize: `${info.font_size_testimonial_title}px`,
                  fontWeight: `${info.font_weight_testimonial_title}`,
                  fontStyle: `${info.font_style_testimonial_title}`,
                  padding: `${info.testimonial_title_padding_top}px 30px ${info.testimonial_title_padding_bottom}px 30px`,
                  textDecoration: `${info.font_underline_testimonial_title}`,
                  color: `rgba(${info.testimonial_title_color.r}, ${info.testimonial_title_color.g}, ${info.testimonial_title_color.b}, ${info.testimonial_title_color.a})`,
                  textAlign: `${info.testimonial_title_text_align}`
               }}>{info.testimonial_title_label}</h3>
           : ''
          }
          <Carousel responsive={responsive}>
           { testimonial.map(img => 
            <div style={{width: '92%'}}>
              <img src={'https://nfcleads.au/nodeapi/uploads/' + img} />
            </div>
           ) }
           </Carousel>
         </Testimonial>
         : ''
      }

      { info.custom_text_show === 1 ?
        <CustomText style={{padding: `${info.custom_text_padding_top}px 20px ${info.custom_text_padding_bottom}px 20px`, order: `${custom_text_order}`}}>
          { info.custom_text_title === 1 ? 
             <h3 style={{
                   color: `rgba(${info.custom_text_title_color.r}, ${info.custom_text_title_color.g}, ${info.custom_text_title_color.b}, ${info.custom_text_title_color.a})`,
                   fontSize: `${info.font_size_custom_text_title}px`,
                   fontWeight: `${info.font_weight_custom_text_title}`,
                   fontStyle: `${info.font_style_custom_text_title}`,
                   textDecoration: `${info.font_underline_custom_text_title}`,
                   padding: `${info.custom_text_title_padding_top}px 0 ${info.custom_text_title_padding_bottom}px 0`,
                   textAlign: `${info.custom_text_title_text_align}`,
             }}>{info.custom_text_title_label}</h3>
            : ''}
          <p style={{
               background: `rgba(${info.custom_text_background_color.r}, ${info.custom_text_background_color.g}, ${info.custom_text_background_color.b}, ${info.custom_text_background_color.a})`,
               fontSize: `${info.custom_text_font_size}px`,
               fontWeight: `${info.custom_text_font_weight}`,
               fontStyle: `${info.custom_text_font_style}`,
               color: `rgba(${info.custom_text_font_color.r}, ${info.custom_text_font_color.g}, ${info.custom_text_font_color.b}, ${info.custom_text_font_color.a})`,
               textAlign: `${info.custom_text_font_text_align}`,
               lineHeight: '24px',
               padding: '25px',
          }} dangerouslySetInnerHTML={{__html: info.custom_text}}></p>
        </CustomText>
        : ''
      }

      { info.second_custom_text_show === 1 ?
        <CustomText style={{padding: `${info.second_custom_text_padding_top}px 20px ${info.second_custom_text_padding_bottom}px 20px`, order: `${second_custom_text_order}`}}>
          { info.second_custom_text_title === 1 ? 
             <h3 style={{
                   color: `rgba(${info.second_custom_text_title_color.r}, ${info.second_custom_text_title_color.g}, ${info.second_custom_text_title_color.b}, ${info.second_custom_text_title_color.a})`,
                   fontSize: `${info.second_font_size_custom_text_title}px`,
                   fontWeight: `${info.second_font_weight_custom_text_title}`,
                   fontStyle: `${info.second_font_style_custom_text_title}`,
                   textDecoration: `${info.second_font_underline_custom_text_title}`,
                   padding: `${info.second_custom_text_title_padding_top}px 0 ${info.second_custom_text_title_padding_bottom}px 0`,
                   textAlign: `${info.second_custom_text_title_text_align}`,
             }}>{info.second_custom_text_title_label}</h3>
            : ''}
          <p style={{
               background: `rgba(${info.second_custom_text_background_color.r}, ${info.second_custom_text_background_color.g}, ${info.second_custom_text_background_color.b}, ${info.second_custom_text_background_color.a})`,
               fontSize: `${info.second_custom_text_font_size}px`,
               fontWeight: `${info.second_custom_text_font_weight}`,
               fontStyle: `${info.second_custom_text_font_style}`,
               color: `rgba(${info.second_custom_text_font_color.r}, ${info.second_custom_text_font_color.g}, ${info.second_custom_text_font_color.b}, ${info.second_custom_text_font_color.a})`,
               textAlign: `${info.second_custom_text_font_text_align}`,
               lineHeight: '24px',
               padding: '25px',
          }} dangerouslySetInnerHTML={{__html: info.second_custom_text}}></p>
        </CustomText>
        : ''
      }

      { info.custom_image_show === 1 && info.custom_image !== ''?
      <CustomImage style={{padding: `${info.custom_image_padding_top}px 20px ${info.custom_image_padding_bottom}px 20px`, order: `${custom_image_order}`}}>
        { info.custom_image_title === 1 ?
           <h3 style={{
              fontFamily: `${info.font_family}`,
              color: `rgba(${info.custom_image_title_color.r}, ${info.custom_image_title_color.g}, ${info.custom_image_title_color.b}, ${info.custom_image_title_color.a})`,
              fontSize: `${info.font_size_custom_image_title}px`,
              fontWeight: `${info.font_weight_custom_image_title}`,
              fontStyle: `${info.font_style_custom_image_title}`,
              textDecoration: `${info.font_underline_custom_image_title}`,
              padding: `${info.custom_image_title_padding_top}px 0 ${info.custom_image_title_padding_bottom}px 0`,
              textAlign: `${info.custom_image_title_text_align}`,
              }}>{info.custom_image_title_label}</h3>
           : ''
        }
        <img src={'https://nfcleads.au/nodeapi/uploads/' + info.custom_image} style={{
          width: '100%',
          border: `${info.custom_image_border_width}px solid rgba(${info.custom_image_border_color.r}, ${info.custom_image_border_color.g}, ${info.custom_image_border_color.b}, ${info.custom_image_border_color.a})`
        }}/>
      </CustomImage>
       : ''
      }

      { info.second_custom_image_show === 1 && info.second_custom_image !== ''?
      <CustomImage style={{padding: `${info.second_custom_image_padding_top}px 20px ${info.second_custom_image_padding_bottom}px 20px`, order: `${second_custom_image_order}`}}>
        { info.second_custom_image_title === 1 ?
           <h3 style={{
              fontFamily: `${info.font_family}`,
              color: `rgba(${info.second_custom_image_title_color.r}, ${info.second_custom_image_title_color.g}, ${info.second_custom_image_title_color.b}, ${info.second_custom_image_title_color.a})`,
              fontSize: `${info.second_font_size_custom_image_title}px`,
              fontWeight: `${info.second_font_weight_custom_image_title}`,
              fontStyle: `${info.second_font_style_custom_image_title}`,
              textDecoration: `${info.second_font_underline_custom_image_title}`,
              padding: `${info.second_custom_image_title_padding_top}px 0 ${info.second_custom_image_title_padding_bottom}px 0`,
              textAlign: `${info.second_custom_image_title_text_align}`,
              }}>{info.second_custom_image_title_label}</h3>
           : ''
        }
        <img src={'https://nfcleads.au/nodeapi/uploads/' + info.second_custom_image} style={{
          width: '100%',
          border: `${info.second_custom_image_border_width}px solid rgba(${info.second_custom_image_border_color.r}, ${info.second_custom_image_border_color.g}, ${info.second_custom_image_border_color.b}, ${info.second_custom_image_border_color.a})`
        }}/>
      </CustomImage>
       : ''
      }

      { info.image_grid_show === 1 ?
        <ImageGrid style={{padding: `${info.image_grid_padding_top}px 15px ${info.image_grid_padding_bottom}px 15px`, order: `${custom_image_order}`}}>
          { info.image_grid_title === 1 ?
           <h3 style={{
                  fontFamily: `${info.font_family}`,
                  fontSize: `${info.font_size_image_grid_title}px`,
                  fontWeight: `${info.font_weight_image_grid_title}`,
                  fontStyle: `${info.font_style_image_grid_title}`,
                  padding: `${info.image_grid_title_padding_top}px 30px ${info.image_grid_title_padding_bottom}px 30px`,
                  textDecoration: `${info.font_underline_image_grid_title}`,
                  color: `rgba(${info.image_grid_title_color.r}, ${info.image_grid_title_color.g}, ${info.image_grid_title_color.b}, ${info.image_grid_title_color.a})`,
                  textAlign: `${info.image_grid_title_text_align}`
               }}>{info.image_grid_title_label}</h3>
           : ''
         }
          <SRLWrapper>
            <div id="gallery-main">
             { image_grid.map(img => 
               <a 
                 href={'https://nfcleads.au/nodeapi/uploads/' + img} 
                 style={{
                    display: 'block', 
                    float: 'left',
                    width: `${gallery_image_style.width}px`, 
                    height: `${gallery_image_style.width}px`, 
                    margin: '5px'
                 }}>
                 <img style={{width: '100%'}} src={'https://nfcleads.au/nodeapi/uploads/' + img} />
               </a>
             )}
            </div>
          </SRLWrapper>
          <div style={{clear: 'both'}}></div>
         </ImageGrid>
         : ''
      }

      { info.map === 1 ?
      <Map style={{padding: `${info.map_padding_top}px 0 ${info.map_padding_bottom}px 0`, order: `${map_order}`}}>
        { info.map_title === 1 ?
           <h3 style={{
                  fontFamily: `${info.font_family}`,
                  fontSize: `${info.font_size_map_title}px`,
                  fontWeight: `${info.font_weight_map_title}`,
                  fontStyle: `${info.font_style_map_title}`,
                  padding: `${info.map_title_padding_top}px 30px ${info.map_title_padding_bottom}px 30px`,
                  textDecoration: `${info.font_underline_map_title}`,
                  color: `rgba(${info.map_title_color.r}, ${info.map_title_color.g}, ${info.map_title_color.b}, ${info.map_title_color.a})`,
                  textAlign: `${info.video_title_text_align}`
               }}>{info.map_title_label}</h3>
           : ''
         }
        <iframe 
         width="100%" 
         height="350" 
         frameborder="0" 
         scrolling="yes"
         marginheight="0" 
         marginwidth="0" 
         src={'https://maps.google.com/maps?q=' + info.latitude + ',' + info.longitude + '&hl=es&z=14&output=embed&hl=en' }>
       </iframe>
      </Map>
       : ''
      }


      { info.add_text_between_line === 1 ?
        <TextBetweenLine style={{padding: `${info.text_between_line_space_top}px 20px ${info.text_between_line_space_bottom}px 20px`, order: `${text_between_line_order}`}}>
          <div class="text_between_line_container">
            <p class="text_between_line_title" style={{
                fontSize: `${info.text_between_line_word_font_size}px`,
                fontStyle: `${info.text_between_line_word_format}`,
                fontWeight: `${info.text_between_line_word_font_weight}`,
                color: `rgba(${info.text_between_line_word_color.r}, ${info.text_between_line_word_color.g}, ${info.text_between_line_word_color.b}, ${info.text_between_line_word_color.a})`,
            }}>
               <span style={{
                    height: `${info.text_between_line_size}px`,
                    backgroundColor: `rgba(${info.text_between_line_color.r}, ${info.text_between_line_color.g}, ${info.text_between_line_color.b}, ${info.text_between_line_color.a})`,
                 }}>
               </span>
                 {info.text_between_line_word}
               <span style={{
                   height: `${info.text_between_line_size}px`,
                   backgroundColor: `rgba(${info.text_between_line_color.r}, ${info.text_between_line_color.g}, ${info.text_between_line_color.b}, ${info.text_between_line_color.a})`,
                 }}>
               </span>
            </p>
          </div>
        </TextBetweenLine>
        :
       ''
      }

      { info.second_add_text_between_line === 1 ?
        <TextBetweenLine style={{padding: `${info.second_text_between_line_space_top}px 20px ${info.second_text_between_line_space_bottom}px 20px`, order: `${second_text_between_line_order}`}}>
          <div class="text_between_line_container">
            <p class="text_between_line_title" style={{
                fontSize: `${info.second_text_between_line_word_font_size}px`,
                fontStyle: `${info.second_text_between_line_word_format}`,
                fontWeight: `${info.second_text_between_line_word_font_weight}`,
                color: `rgba(${info.second_text_between_line_word_color.r}, ${info.second_text_between_line_word_color.g}, ${info.second_text_between_line_word_color.b}, ${info.second_text_between_line_word_color.a})`,
            }}>
               <span style={{
                    height: `${info.second_text_between_line_size}px`,
                    backgroundColor: `rgba(${info.second_text_between_line_color.r}, ${info.second_text_between_line_color.g}, ${info.second_text_between_line_color.b}, ${info.second_text_between_line_color.a})`,
                 }}>
               </span>
                 {info.second_text_between_line_word}
               <span style={{
                   height: `${info.second_text_between_line_size}px`,
                   backgroundColor: `rgba(${info.second_text_between_line_color.r}, ${info.second_text_between_line_color.g}, ${info.second_text_between_line_color.b}, ${info.second_text_between_line_color.a})`,
                 }}>
               </span>
            </p>
          </div>
        </TextBetweenLine>
        :
       ''
      }

      { info.third_add_text_between_line === 1 ?
        <TextBetweenLine style={{padding: `${info.third_text_between_line_space_top}px 20px ${info.third_text_between_line_space_bottom}px 20px`, order: `${third_text_between_line_order}`}}>
          <div class="text_between_line_container">
            <p class="text_between_line_title" style={{
                fontSize: `${info.third_text_between_line_word_font_size}px`,
                fontStyle: `${info.third_text_between_line_word_format}`,
                fontWeight: `${info.third_text_between_line_word_font_weight}`,
                color: `rgba(${info.third_text_between_line_word_color.r}, ${info.third_text_between_line_word_color.g}, ${info.third_text_between_line_word_color.b}, ${info.third_text_between_line_word_color.a})`,
            }}>
               <span style={{
                    height: `${info.third_text_between_line_size}px`,
                    backgroundColor: `rgba(${info.third_text_between_line_color.r}, ${info.third_text_between_line_color.g}, ${info.third_text_between_line_color.b}, ${info.third_text_between_line_color.a})`,
                 }}>
               </span>
                 {info.third_text_between_line_word}
               <span style={{
                   height: `${info.third_text_between_line_size}px`,
                   backgroundColor: `rgba(${info.third_text_between_line_color.r}, ${info.third_text_between_line_color.g}, ${info.third_text_between_line_color.b}, ${info.third_text_between_line_color.a})`,
                 }}>
               </span>
            </p>
          </div>
        </TextBetweenLine>
        :
       ''
      }

      { info.fourth_add_text_between_line === 1 ?
        <TextBetweenLine style={{padding: `${info.fourth_text_between_line_space_top}px 20px ${info.fourth_text_between_line_space_bottom}px 20px`, order: `${fourth_text_between_line_order}`}}>
          <div class="text_between_line_container">
            <p class="text_between_line_title" style={{
                fontSize: `${info.fourth_text_between_line_word_font_size}px`,
                fontStyle: `${info.fourth_text_between_line_word_format}`,
                fontWeight: `${info.fourth_text_between_line_word_font_weight}`,
                color: `rgba(${info.fourth_text_between_line_word_color.r}, ${info.fourth_text_between_line_word_color.g}, ${info.fourth_text_between_line_word_color.b}, ${info.fourth_text_between_line_word_color.a})`,
            }}>
               <span style={{
                    height: `${info.fourth_text_between_line_size}px`,
                    backgroundColor: `rgba(${info.fourth_text_between_line_color.r}, ${info.fourth_text_between_line_color.g}, ${info.fourth_text_between_line_color.b}, ${info.fourth_text_between_line_color.a})`,
                 }}>
               </span>
                 {info.fourth_text_between_line_word}
               <span style={{
                   height: `${info.fourth_text_between_line_size}px`,
                   backgroundColor: `rgba(${info.fourth_text_between_line_color.r}, ${info.fourth_text_between_line_color.g}, ${info.fourth_text_between_line_color.b}, ${info.fourth_text_between_line_color.a})`,
                 }}>
               </span>
            </p>
          </div>
        </TextBetweenLine>
        :
       ''
      }

      { info.add_custom_button === 1 ?
        <CustomButton style={{textAlign: `${info.custom_button_position}`, margin: `${info.custom_button_margin_top}px 20px ${info.custom_button_margin_bottom}px 20px`, order: `${custom_button_order}`}}>
          { info.custom_button_animation === '1' ?
            <button onClick={() => window.open(info.custom_button_link)} className="custom-button button-1" style={{
              width: `${info.custom_button_width}px`,
              height: `${info.custom_button_height}px`,
              color: `rgba(${info.custom_button_font_color.r}, ${info.custom_button_font_color.g}, ${info.custom_button_font_color.b}, ${info.custom_button_font_color.a})`,
              border: `${info.custom_button_border_width}px solid rgba(${info.custom_button_border_color.r}, ${info.custom_button_border_color.g}, ${info.custom_button_border_color.b}, ${info.custom_button_border_color.a})`,
              borderRadius: `${info.custom_button_border_radious}px`,
              fontSize: `${info.custom_button_font_size}px`,
              fontWeight: `${info.custom_button_font_weight}`,
            }}><span style={{
                  backgroundColor: `rgba(${info.custom_button_background.r}, ${info.custom_button_background.g}, ${info.custom_button_background.b}, ${info.custom_button_background.a})`,
            }}></span>{info.custom_button_text}</button>
            : '' 
          }
          { info.custom_button_animation === '2' ?
            <button onClick={() => window.open(info.custom_button_link)} className="custom-button button-2" style={{
              width: `${info.custom_button_width}px`,
              height: `${info.custom_button_height}px`,
              color: `rgba(${info.custom_button_font_color.r}, ${info.custom_button_font_color.g}, ${info.custom_button_font_color.b}, ${info.custom_button_font_color.a})`,
              border: `${info.custom_button_border_width}px solid rgba(${info.custom_button_border_color.r}, ${info.custom_button_border_color.g}, ${info.custom_button_border_color.b}, ${info.custom_button_border_color.a})`,
              borderRadius: `${info.custom_button_border_radious}px`,
              fontSize: `${info.custom_button_font_size}px`,
              backgroundImage: `linear-gradient(to right, transparent 50%, rgba(${info.custom_button_background.r}, ${info.custom_button_background.g}, ${info.custom_button_background.b}, ${info.custom_button_background.a}) 50%)`,
              backgroundSize: '200%',
              backgroundPosition: '0%',
              fontWeight: `${info.custom_button_font_weight}`,
            }}>{info.custom_button_text}</button>
            : '' 
          }
          { info.custom_button_animation === '3' ?
            <button onClick={() => window.open(info.custom_button_link)} className="custom-button button-3" style={{
              width: `${info.custom_button_width}px`,
              height: `${info.custom_button_height}px`,
              color: `rgba(${info.custom_button_font_color.r}, ${info.custom_button_font_color.g}, ${info.custom_button_font_color.b}, ${info.custom_button_font_color.a})`,
              border: `${info.custom_button_border_width}px solid rgba(${info.custom_button_border_color.r}, ${info.custom_button_border_color.g}, ${info.custom_button_border_color.b}, ${info.custom_button_border_color.a})`,
              borderRadius: `${info.custom_button_border_radious}px`,
              fontSize: `${info.custom_button_font_size}px`,
              backgroundImage: `linear-gradient(to top, rgba(${info.custom_button_background.r}, ${info.custom_button_background.g}, ${info.custom_button_background.b}, ${info.custom_button_background.a}), 50%, transparent 50%`,
              backgroundSize: '100% 200%',
              backgroundPosition: '0% 0%',
              fontWeight: `${info.custom_button_font_weight}`,
            }}>{info.custom_button_text}</button>
            : '' 
          }
          { info.custom_button_animation === '4' ?
            <button onClick={() => window.open(info.custom_button_link)} className="custom-button button-4" style={{
              width: `${info.custom_button_width}px`,
              height: `${info.custom_button_height}px`,
              color: '#fff',
              fontSize: `${info.custom_button_font_size}px`,
              borderRadius: `${info.custom_button_border_radious}px`,
              backgroundColor: `rgba(${info.custom_button_background.r}, ${info.custom_button_background.g}, ${info.custom_button_background.b}, ${info.custom_button_background.a})`,
              transition: '0.1s',
              textShadow: '0px 3px rgba(0,0,0,0.17)',
              boxShadow: 'inset 0 -8px 0 0 rgba(0,0,0,0.17)',
              fontWeight: `${info.custom_button_font_weight}`,
            }}>{info.custom_button_text}</button>
            : '' 
          }
          { info.custom_button_animation === '5' ?
            <button onClick={() => window.open(info.custom_button_link)} className="custom-button button-5" style={{
              width: `${info.custom_button_width}px`,
              height: `${info.custom_button_height}px`,
              color: `rgba(${info.custom_button_font_color.r}, ${info.custom_button_font_color.g}, ${info.custom_button_font_color.b}, ${info.custom_button_font_color.a})`,
              fontSize: `${info.custom_button_font_size}px`,
              fontWeight: `${info.custom_button_font_weight}`,
            }}>
              <span className="btn-left" style={{
                borderTop: `${info.custom_button_border_width}px solid rgba(${info.custom_button_border_color.r}, ${info.custom_button_border_color.g}, ${info.custom_button_border_color.b}, ${info.custom_button_border_color.a})`,
                borderLeft: `${info.custom_button_border_width}px solid rgba(${info.custom_button_border_color.r}, ${info.custom_button_border_color.g}, ${info.custom_button_border_color.b}, ${info.custom_button_border_color.a})`,
            }}></span>
                  {info.custom_button_text}
              <span className="btn-right" style={{
                borderBottom: `${info.custom_button_border_width}px solid rgba(${info.custom_button_border_color.r}, ${info.custom_button_border_color.g}, ${info.custom_button_border_color.b}, ${info.custom_button_border_color.a})`,
                borderRight: `${info.custom_button_border_width}px solid rgba(${info.custom_button_border_color.r}, ${info.custom_button_border_color.g}, ${info.custom_button_border_color.b}, ${info.custom_button_border_color.a})`,
            }}></span>
            </button>
            : '' 
          }
        </CustomButton>
        :
       ''
      }

      { info.second_add_custom_button === 1 ?
        <CustomButton style={{textAlign: `${info.second_custom_button_position}`, margin: `${info.second_custom_button_margin_top}px 20px ${info.second_custom_button_margin_bottom}px 20px`, order: `${second_custom_button_order}`}}>
          { info.second_custom_button_animation === '1' ?
            <button onClick={() => window.open(info.second_custom_button_link)} className="custom-button button-1" style={{
              width: `${info.second_custom_button_width}px`,
              height: `${info.second_custom_button_height}px`,
              color: `rgba(${info.second_custom_button_font_color.r}, ${info.second_custom_button_font_color.g}, ${info.second_custom_button_font_color.b}, ${info.second_custom_button_font_color.a})`,
              border: `${info.second_custom_button_border_width}px solid rgba(${info.second_custom_button_border_color.r}, ${info.second_custom_button_border_color.g}, ${info.second_custom_button_border_color.b}, ${info.second_custom_button_border_color.a})`,
              borderRadius: `${info.second_custom_button_border_radious}px`,
              fontSize: `${info.second_custom_button_font_size}px`,
              fontWeight: `${info.second_custom_button_font_weight}`,
            }}><span style={{
                  backgroundColor: `rgba(${info.second_custom_button_background.r}, ${info.second_custom_button_background.g}, ${info.second_custom_button_background.b}, ${info.second_custom_button_background.a})`,
            }}></span>{info.second_custom_button_text}</button>
            : '' 
          }
          { info.second_custom_button_animation === '2' ?
            <button onClick={() => window.open(info.second_custom_button_link)} className="custom-button button-2" style={{
              width: `${info.second_custom_button_width}px`,
              height: `${info.second_custom_button_height}px`,
              color: `rgba(${info.second_custom_button_font_color.r}, ${info.second_custom_button_font_color.g}, ${info.second_custom_button_font_color.b}, ${info.second_custom_button_font_color.a})`,
              border: `${info.second_custom_button_border_width}px solid rgba(${info.second_custom_button_border_color.r}, ${info.second_custom_button_border_color.g}, ${info.second_custom_button_border_color.b}, ${info.second_custom_button_border_color.a})`,
              borderRadius: `${info.second_custom_button_border_radious}px`,
              fontSize: `${info.second_custom_button_font_size}px`,
              backgroundImage: `linear-gradient(to right, transparent 50%, rgba(${info.second_custom_button_background.r}, ${info.second_custom_button_background.g}, ${info.second_custom_button_background.b}, ${info.second_custom_button_background.a}) 50%)`,
              backgroundSize: '200%',
              backgroundPosition: '0%',
              fontWeight: `${info.second_custom_button_font_weight}`,
            }}>{info.second_custom_button_text}</button>
            : '' 
          }
          { info.second_custom_button_animation === '3' ?
            <button onClick={() => window.open(info.second_custom_button_link)} className="custom-button button-3" style={{
              width: `${info.second_custom_button_width}px`,
              height: `${info.second_custom_button_height}px`,
              color: `rgba(${info.second_custom_button_font_color.r}, ${info.second_custom_button_font_color.g}, ${info.second_custom_button_font_color.b}, ${info.second_custom_button_font_color.a})`,
              border: `${info.second_custom_button_border_width}px solid rgba(${info.second_custom_button_border_color.r}, ${info.second_custom_button_border_color.g}, ${info.second_custom_button_border_color.b}, ${info.second_custom_button_border_color.a})`,
              borderRadius: `${info.second_custom_button_border_radious}px`,
              fontSize: `${info.second_custom_button_font_size}px`,
              backgroundImage: `linear-gradient(to top, rgba(${info.second_custom_button_background.r}, ${info.second_custom_button_background.g}, ${info.second_custom_button_background.b}, ${info.second_custom_button_background.a}), 50%, transparent 50%`,
              backgroundSize: '100% 200%',
              backgroundPosition: '0% 0%',
              fontWeight: `${info.second_custom_button_font_weight}`,
            }}>{info.second_custom_button_text}</button>
            : '' 
          }
          { info.second_custom_button_animation === '4' ?
            <button onClick={() => window.open(info.second_custom_button_link)} className="custom-button button-4" style={{
              width: `${info.second_custom_button_width}px`,
              height: `${info.second_custom_button_height}px`,
              color: '#fff',
              fontSize: `${info.second_custom_button_font_size}px`,
              borderRadius: `${info.second_custom_button_border_radious}px`,
              backgroundColor: `rgba(${info.second_custom_button_background.r}, ${info.second_custom_button_background.g}, ${info.second_custom_button_background.b}, ${info.second_custom_button_background.a})`,
              transition: '0.1s',
              textShadow: '0px 3px rgba(0,0,0,0.17)',
              boxShadow: 'inset 0 -8px 0 0 rgba(0,0,0,0.17)',
              fontWeight: `${info.second_custom_button_font_weight}`,
            }}>{info.second_custom_button_text}</button>
            : '' 
          }
          { info.second_custom_button_animation === '5' ?
            <button onClick={() => window.open(info.second_custom_button_link)} className="custom-button button-5" style={{
              width: `${info.second_custom_button_width}px`,
              height: `${info.second_custom_button_height}px`,
              color: `rgba(${info.second_custom_button_font_color.r}, ${info.second_custom_button_font_color.g}, ${info.second_custom_button_font_color.b}, ${info.second_custom_button_font_color.a})`,
              fontSize: `${info.second_custom_button_font_size}px`,
              fontWeight: `${info.second_custom_button_font_weight}`,
            }}>
              <span className="btn-left" style={{
                borderTop: `${info.second_custom_button_border_width}px solid rgba(${info.second_custom_button_border_color.r}, ${info.second_custom_button_border_color.g}, ${info.second_custom_button_border_color.b}, ${info.second_custom_button_border_color.a})`,
                borderLeft: `${info.second_custom_button_border_width}px solid rgba(${info.second_custom_button_border_color.r}, ${info.second_custom_button_border_color.g}, ${info.second_custom_button_border_color.b}, ${info.second_custom_button_border_color.a})`,
            }}></span>
                  {info.second_custom_button_text}
              <span className="btn-right" style={{
                borderBottom: `${info.second_custom_button_border_width}px solid rgba(${info.second_custom_button_border_color.r}, ${info.second_custom_button_border_color.g}, ${info.second_custom_button_border_color.b}, ${info.second_custom_button_border_color.a})`,
                borderRight: `${info.second_custom_button_border_width}px solid rgba(${info.second_custom_button_border_color.r}, ${info.second_custom_button_border_color.g}, ${info.second_custom_button_border_color.b}, ${info.second_custom_button_border_color.a})`,
            }}></span>
            </button>
            : '' 
          }
        </CustomButton>
        :
       ''
      }

      { info.add_table === 1 ?
        <CardTable style={{padding: `${info.table_padding_top}px 20px ${info.table_padding_bottom}px 20px`, order: `${custom_text_order}`}}>
          { info.table_title === 1 ? 
             <h3 style={{
                   color: `rgba(${info.table_title_color.r}, ${info.table_title_color.g}, ${info.table_title_color.b}, ${info.table_title_color.a})`,
                   fontSize: `${info.font_size_table_title}px`,
                   fontWeight: `${info.font_weight_table_title}`,
                   fontStyle: `${info.font_style_table_title}`,
                   textDecoration: `${info.font_underline_table_title}`,
                   padding: `${info.table_title_padding_top}px 0 ${info.table_title_padding_bottom}px 0`,
                   textAlign: `${info.table_title_text_align}`,
             }}>{info.table_title_label}</h3>
             : ''
            }

            {info.table.length > 0 && (
             <table style={{
                 width: '100%', 
                 border: `${info.table_border_width}px solid rgba(${info.table_border_color.r}, ${info.table_border_color.g}, ${info.table_border_color.b}, ${info.table_border_color.a})`, 
                 borderCollapse: 'collapse',
                 background: `rgba(${info.table_cell_background.r}, ${info.table_cell_background.g}, ${info.table_cell_background.b}, ${info.table_cell_background.a})`,
               }}>
               <tbody>
                 {info.table.map((rowData, rowIndex) => (
                  <tr key={rowIndex}>
                   {rowData.map((cellData, columnIndex) => (
                     <td style={{
                        color: `rgba(${info.table_font_color.r}, ${info.table_font_color.g}, ${info.table_font_color.b}, ${info.table_font_color.a})`,
                        fontSize: `${info.table_font_size}px`,
                        fontWeight: `${info.table_font_weight}`,
                        fontStyle: `${info.table_font_style}`,
                        padding: `${info.table_cell_padding}px`, 
                        textAlign: `${info.table_cell_alignment}`,
                        border: `${info.table_border_width}px solid rgba(${info.table_border_color.r}, ${info.table_border_color.g}, ${info.table_border_color.b}, ${info.table_border_color.a})`
                     }} key={columnIndex} dangerouslySetInnerHTML={{__html: cellData}}></td>
                   ))}
                  </tr>
                ))}
               </tbody>
              </table>
            )}
        </CardTable>
        : ''
      }

      { info.two_image_show === 1 ?
       <TwoImage style={{padding: `${info.two_image_padding_top}px 20px ${info.two_image_padding_bottom}px 20px`, order: `${appointment_order}`}}>
         { info.two_image_title === 1 ?
           <h3 style={{
                  fontFamily: `${info.font_family}`,
                  fontSize: `${info.font_size_two_image_title}px`,
                  fontWeight: `${info.font_weight_two_image_title}`,
                  fontStyle: `${info.font_style_two_image_title}`,
                  padding: `${info.two_image_title_padding_top}px 0px ${info.two_image_title_padding_bottom}px 0px`,
                  textDecoration: `${info.font_underline_two_image_title}`,
                  color: `rgba(${info.two_image_title_color.r}, ${info.two_image_title_color.g}, ${info.two_image_title_color.b}, ${info.two_image_title_color.a})`,
                  textAlign: `${info.appointment_title_text_align}`
               }}>{info.two_image_title_label}</h3>
           : ''
         }
        <Row>
          <div className="two-img-col">
            { info.two_image_first !== '' ?
             <>
             <a href={info.two_image_first_link}>
              <img style={{
                 borderRadius: `${info.two_image_first_shape}`,
                 border: `${info.two_image_first_border_width}px solid`,
                 borderColor: `rgba(${info.two_image_first_border_color.r}, ${info.two_image_first_border_color.g}, ${info.two_image_first_border_color.b}, ${info.two_image_first_border_color.a})`,
              }} src={'https://nfcleads.au/nodeapi/uploads/' + info.two_image_first} />
             </a>  
             { info.two_image_first_link_show === 1 ?
              <p className="two_img_link_show"><a href={info.two_image_first_link}>{info.two_image_first_link}</a></p>
              :
              ''
            }
            </>
            : ''
            }
          </div>
          <div className="two-img-col">
           { info.two_image_last !== '' ?
            <>
            <a href={info.two_image_last_link}>
             <img style={{
                 borderRadius: `${info.two_image_last_shape}`,
                 border: `${info.two_image_last_border_width}px solid`,
                 borderColor: `rgba(${info.two_image_last_border_color.r}, ${info.two_image_last_border_color.g}, ${info.two_image_last_border_color.b}, ${info.two_image_last_border_color.a})`,
              }} src={'https://nfcleads.au/nodeapi/uploads/' + info.two_image_last} />
            </a> 
            { info.two_image_last_link_show === 1 ?
            <p className="two_img_link_show"><a href={info.two_image_last_link}>{info.two_image_last_link}</a></p>
            :
            ''
            }
            </>
            : ''
           }
          </div>
        </Row>
       </TwoImage>
      : ''
      }

      { info.add_payment_button === 1 ?
        <PaymentButton style={{ margin: `${info.payment_button_margin_top}px 20px ${info.payment_button_margin_bottom}px 20px`, order: `${payment_button_order}`, 'overflowX':  'hidden'}}>
          { info.payment_button_title === 1 ? 
             <h3 style={{
                   color: `rgba(${info.payment_button_title_color.r}, ${info.payment_button_title_color.g}, ${info.payment_button_title_color.b}, ${info.payment_button_title_color.a})`,
                   fontSize: `${info.font_size_payment_button_title}px`,
                   fontWeight: `${info.font_weight_payment_button_title}`,
                   fontStyle: `${info.font_style_payment_button_title}`,
                   textDecoration: `${info.font_underline_payment_button_title}`,
                   padding: `${info.payment_button_title_padding_top}px 0 ${info.payment_button_title_padding_bottom}px 0`,
                   textAlign: `${info.payment_button_title_text_align}`,
             }}>{info.payment_button_title_label}</h3>
             : ''
          }
          <div className="products">
            { info.products &&
              info.products?.map((product,index) => (
                <div key={index} className="product">
                 { product.product_image !== '' ?
                   <img src={'https://nfcleads.au/nodeapi/uploads/' + product.product_image}/>
                  : 
                   <img src={productImage}/>
                 }
                  <div className="details">
                    <h3>{product.title !== '' ? product.title : 'Product Name'}</h3>
                    <span className="price">${product.price}</span>
                  </div>
                  <button onClick={() => handleAddToCart(product)} style={{
                     width: '100%',
                     height: `${info.payment_button_height}px`,
                     color: `rgba(${info.payment_button_font_color.r}, ${info.payment_button_font_color.g}, ${info.payment_button_font_color.b}, ${info.payment_button_font_color.a})`,
                     border: `${info.payment_button_border_width}px solid rgba(${info.payment_button_border_color.r}, ${info.payment_button_border_color.g}, ${info.payment_button_border_color.b}, ${info.payment_button_border_color.a})`,
                     borderRadius: `${info.payment_button_border_radious}px`,
                     fontSize: `${info.payment_button_font_size}px`,
                     fontWeight: `${info.payment_button_font_weight}`,
                     textAlign: `${info.payment_button_text_align}`,
                     backgroundColor: `rgba(${info.payment_button_background.r}, ${info.payment_button_background.g}, ${info.payment_button_background.b}, ${info.payment_button_background.a})`
                  }}>
                    {info.payment_button_text}
                  </button>
                </div>
              ))}
          </div>
          <Cart/>
        </PaymentButton>
        :
       ''
      }

      { info.add_accordion === 1 ?
        <CardAccordion>
          { info.accordion_title === 1 ?
           <h3 style={{
                  fontFamily: `${info.font_family}`,
                  fontSize: `${info.font_size_accordion_title}px`,
                  fontWeight: `${info.font_weight_accordion_title}`,
                  fontStyle: `${info.font_style_accordion_title}`,
                  padding: `${info.accordion_title_padding_top}px 0px ${info.accordion_title_padding_bottom}px 0px`,
                  textDecoration: `${info.font_underline_accordion_title}`,
                  color: `rgba(${info.accordion_title_color.r}, ${info.accordion_title_color.g}, ${info.accordion_title_color.b}, ${info.accordion_title_color.a})`,
                  textAlign: `${info.accordion_title_text_align}`
               }}>{info.accordion_title_label}</h3>
           : ''
          }
          { accordions.map(accordion => 
             <Accordion1 title={accordion.name} content={accordion.content} lineHeight={info.accordion_content_line_height}/>
          )}
        </CardAccordion>
        :
        ''
      }

      { info.add_block_image_text === 1 ?
      <BlockImageText style={{
         margin: `${info.block_image_text_padding_top}px 20px ${info.block_image_text_padding_bottom}px 20px`, 
         order: `${appointment_order}`,
         background: `rgba(${info.block_image_text_background.r}, ${info.block_image_text_background.g}, ${info.block_image_text_background.b}, ${info.block_image_text_background.a})`,
         width: `unset`,
        }}>
        {info.block_image_text_img !== '' ?
         <div className="image-block">
          <img style={{
                width: '100%',
                margin: `${info.block_image_text_img_padding_top}px ${info.block_image_text_img_padding_right}px ${info.block_image_text_img_padding_bottom}px ${info.block_image_text_img_padding_left}px`,
                border: `${info.block_image_text_img_border_width}px solid`,
                borderColor: `rgba(${info.block_image_text_img_border_color.r}, ${info.block_image_text_img_border_color.g}, ${info.block_image_text_img_border_color.b}, ${info.block_image_text_img_border_color.a})`
              }} src={'https://nfcleads.au/nodeapi/uploads/' + info.block_image_text_img} />
         </div>
        :
        ''
        }
        <div className="text-block">
          { info.block_image_text_title === 1 ?
           <h3 style={{
                  fontFamily: `${info.font_family}`,
                  fontSize: `${info.font_size_block_image_text_title}px`,
                  fontWeight: `${info.font_weight_block_image_text_title}`,
                  fontStyle: `${info.font_style_block_image_text_title}`,
                  padding: `${info.block_image_text_title_padding_top}px ${info.block_image_text_title_padding_right}px ${info.block_image_text_title_padding_bottom}px ${info.block_image_text_title_padding_left}px`,
                  textDecoration: `${info.font_underline_block_image_text_title}`,
                  color: `rgba(${info.block_image_text_title_color.r}, ${info.block_image_text_title_color.g}, ${info.block_image_text_title_color.b}, ${info.block_image_text_title_color.a})`,
                  textAlign: `${info.block_image_text_title_text_align}`
               }}>{info.block_image_text_title_label}</h3>
           : ''
         }
         <p style={{
              color: `rgba(${info.block_image_text_color.r}, ${info.block_image_text_color.g}, ${info.block_image_text_color.b}, ${info.block_image_text_color.a})`, 
              fontWeight: `${info.block_image_text_font_weight}`, 
              fontSize: `${info.block_image_text_font_size}px`,
              textDecoration: `${info.block_image_text_underline}`,
              fontStyle: `${info.block_image_text_style}`,
              textAlign: `${info.block_image_text_alignment}`,
              lineHeight: `${info.block_image_text_line_height}px`,
              padding: `${info.block_image_text_paragraph_padding_top}px ${info.block_image_text_paragraph_padding_right}px ${info.block_image_text_paragraph_padding_bottom}px ${info.block_image_text_paragraph_padding_left}px`,
         }} dangerouslySetInnerHTML={{__html: info.block_image_text_paragraph}}></p>
        </div>
      </BlockImageText>
        :''
      }

      { info.add_iframe === 1 ?
        <CardIframe style={{padding: `${info.iframe_padding_top}px 20px ${info.iframe_padding_bottom}px 20px`, order: `${iframe_order}`}}>
          { info.iframe_title === 1 ? 
             <h3 style={{
                   color: `rgba(${info.iframe_title_color.r}, ${info.iframe_title_color.g}, ${info.iframe_title_color.b}, ${info.iframe_title_color.a})`,
                   fontSize: `${info.font_size_iframe_title}px`,
                   fontWeight: `${info.font_weight_iframe_title}`,
                   fontStyle: `${info.font_style_iframe_title}`,
                   textDecoration: `${info.font_underline_iframe_title}`,
                   padding: `${info.iframe_title_padding_top}px 0 ${info.iframe_title_padding_bottom}px 0`,
                   textAlign: `${info.iframe_title_text_align}`,
             }}>{info.iframe_title_label}</h3>
             : ''
            }

            {info.iframe_url !== '' ?
              <div id="iframe_view">
               <iframe 
                  style={{
                    border: `${iframe_border}`,
                    margin: '0 auto',
                    display: 'block',
                  }} 
                  src={info.iframe_url} 
                  scrolling={info.iframe_scrollbar} 
                  width={info.iframe_width} 
                  height={info.iframe_height} 
                  allowfullscreen></iframe> 
              </div>
              : ''
            }
        </CardIframe>
        : ''
      }

      { info.second_add_iframe === 1 ?
        <CardIframe style={{padding: `${info.second_iframe_padding_top}px 20px ${info.second_iframe_padding_bottom}px 20px`, order: `${second_iframe_order}`}}>
          { info.second_iframe_title === 1 ? 
             <h3 style={{
                   color: `rgba(${info.second_iframe_title_color.r}, ${info.second_iframe_title_color.g}, ${info.second_iframe_title_color.b}, ${info.second_iframe_title_color.a})`,
                   fontSize: `${info.second_font_size_iframe_title}px`,
                   fontWeight: `${info.second_font_weight_iframe_title}`,
                   fontStyle: `${info.second_font_style_iframe_title}`,
                   textDecoration: `${info.second_font_underline_iframe_title}`,
                   padding: `${info.second_iframe_title_padding_top}px 0 ${info.second_iframe_title_padding_bottom}px 0`,
                   textAlign: `${info.second_iframe_title_text_align}`,
             }}>{info.second_iframe_title_label}</h3>
             : ''
            }

            {info.second_iframe_url !== '' ?
              <div id="iframe_view">
               <iframe 
                  style={{
                    border: `${second_iframe_border}`,
                    margin: '0 auto',
                    display: 'block',
                  }} 
                  src={info.second_iframe_url} 
                  scrolling={info.second_iframe_scrollbar} 
                  width={info.second_iframe_width} 
                  height={info.second_iframe_height} 
                  allowfullscreen></iframe> 
              </div>
              : ''
            }
        </CardIframe>
        : ''
      }

      { info.third_add_iframe === 1 ?
        <CardIframe style={{padding: `${info.third_iframe_padding_top}px 20px ${info.third_iframe_padding_bottom}px 20px`, order: `${third_iframe_order}`}}>
          { info.third_iframe_title === 1 ? 
             <h3 style={{
                   color: `rgba(${info.third_iframe_title_color.r}, ${info.third_iframe_title_color.g}, ${info.third_iframe_title_color.b}, ${info.third_iframe_title_color.a})`,
                   fontSize: `${info.third_font_size_iframe_title}px`,
                   fontWeight: `${info.third_font_weight_iframe_title}`,
                   fontStyle: `${info.third_font_style_iframe_title}`,
                   textDecoration: `${info.third_font_underline_iframe_title}`,
                   padding: `${info.third_iframe_title_padding_top}px 0 ${info.third_iframe_title_padding_bottom}px 0`,
                   textAlign: `${info.third_iframe_title_text_align}`,
             }}>{info.third_iframe_title_label}</h3>
             : ''
            }

            {info.third_iframe_url !== '' ?
              <div id="iframe_view">
               <iframe 
                  style={{
                    border: `${third_iframe_border}`,
                    margin: '0 auto',
                    display: 'block',
                  }} 
                  src={info.third_iframe_url} 
                  scrolling={info.third_iframe_scrollbar} 
                  width={info.third_iframe_width} 
                  height={info.third_iframe_height} 
                  allowfullscreen></iframe> 
              </div>
              : ''
            }
        </CardIframe>
        : ''
      }

      { info.fourth_add_iframe === 1 ?
        <CardIframe style={{padding: `${info.fourth_iframe_padding_top}px 20px ${info.fourth_iframe_padding_bottom}px 20px`, order: `${fourth_iframe_order}`}}>
          { info.fourth_iframe_title === 1 ? 
             <h3 style={{
                   color: `rgba(${info.fourth_iframe_title_color.r}, ${info.fourth_iframe_title_color.g}, ${info.fourth_iframe_title_color.b}, ${info.fourth_iframe_title_color.a})`,
                   fontSize: `${info.fourth_font_size_iframe_title}px`,
                   fontWeight: `${info.fourth_font_weight_iframe_title}`,
                   fontStyle: `${info.fourth_font_style_iframe_title}`,
                   textDecoration: `${info.fourth_font_underline_iframe_title}`,
                   padding: `${info.fourth_iframe_title_padding_top}px 0 ${info.fourth_iframe_title_padding_bottom}px 0`,
                   textAlign: `${info.fourth_iframe_title_text_align}`,
             }}>{info.fourth_iframe_title_label}</h3>
             : ''
            }

            {info.fourth_iframe_url !== '' ?
              <div id="iframe_view">
               <iframe 
                  style={{
                    border: `${fourth_iframe_border}`,
                    margin: '0 auto',
                    display: 'block',
                  }} 
                  src={info.fourth_iframe_url} 
                  scrolling={info.fourth_iframe_scrollbar} 
                  width={info.fourth_iframe_width} 
                  height={info.fourth_iframe_height} 
                  allowfullscreen></iframe> 
              </div>
              : ''
            }
        </CardIframe>
        : ''
      }

      { info.appointment === 1 ?
      <Appointment style={{margin: `${info.appointment_padding_top}px 30px ${info.appointment_padding_bottom}px 30px`, order: `${appointment_order}`}}>
        { info.appointment_title === 1 ?
           <h3 style={{
                  fontFamily: `${info.font_family}`,
                  fontSize: `${info.font_size_appointment_title}px`,
                  fontWeight: `${info.font_weight_appointment_title}`,
                  fontStyle: `${info.font_style_appointment_title}`,
                  padding: `${info.appointment_title_padding_top}px 0px ${info.appointment_title_padding_bottom}px 0px`,
                  textDecoration: `${info.font_underline_appointment_title}`,
                  color: `rgba(${info.appointment_title_color.r}, ${info.appointment_title_color.g}, ${info.appointment_title_color.b}, ${info.appointment_title_color.a})`,
                  textAlign: `${info.appointment_title_text_align}`
               }}>{info.appointment_title_label}</h3>
           : ''
         }
        <form>
           <label>Select Date <span className="required">*</span></label>
           <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} /> 
           <label>Select Time <span className="required">*</span></label>
           <input type="text" placeholder="Appointment Time"/>
           <label>Name <span className="required">*</span></label>
           <input type="text" placeholder="Full Name"/>
           <label>Email <span className="required">*</span></label>
           <input type="text" placeholder="Email Address"/>
           <label>Telephone <span className="required">*</span></label>
           <input type="tel" placeholder="Landline/Mobile Number"/>
           <label>Appointment Subject <span className="required">*</span></label>
           <input type="text" placeholder="Subject"/>
           <label>Appointment Location <span className="required">*</span></label>
           <input type="text" placeholder="Number Street City"/><br></br><br></br>
           <button type="submit">Request</button>
         </form>
      </Appointment>
        :''
      }

      { info.contactForm === 1 ?
        <ContactForm style={{margin: `${info.contact_form_padding_top}px 30px ${info.contact_form_padding_bottom}px 30px`, order: `${enquiry_order}`}}>
          { info.contactForm_title === 1 ?
           <h3 style={{
                  fontFamily: `${info.font_family}`,
                  fontSize: `${info.font_size_contactForm_title}px`,
                  fontWeight: `${info.font_weight_contactForm_title}`,
                  fontStyle: `${info.font_style_contactForm_title}`,
                  padding: `${info.contactForm_title_padding_top}px 0px ${info.contactForm_title_padding_bottom}px 0px`,
                  textDecoration: `${info.font_underline_contactForm_title}`,
                  color: `rgba(${info.contactForm_title_color.r}, ${info.contactForm_title_color.g}, ${info.contactForm_title_color.b}, ${info.contactForm_title_color.a})`,
                  textAlign: `${info.contactForm_title_text_align}`
               }}>{info.contactForm_title_label}</h3>
           : ''
          }
          <form>
            <label>Name <span className="required">*</span></label>
            <input type="text" placeholder="Full Name"/>
            <label>Email <span className="required">*</span></label>
            <input type="text" placeholder="Email Address"/>
            <label>Telephone <span className="required">*</span></label>
            <input type="text" placeholder="Landline/Mobile Number"/>
            <label>Message <span className="required">*</span></label>
            <textarea placeholder="Type message here"></textarea>
            <button type="submit">Send</button>
          </form>
        </ContactForm>
        : ''
      }
      </div>
     </div>
    </Container>
    
    </>
  );
};

export default PreviewTemplate;

const CustomButton = styled.div`
   width:100%;
`;

const PaymentButton = styled.div`
   width:100%;
`;

const TextBetweenLine = styled.div`
   width: 100%;
`;

const SaveContact = styled.div`
   width: 100%;
   a {
     width: 400px;
     display: block;
     svg {
          vertical-align: text-bottom;
         font-size: 20px;
     }
   }
`;

const CardTable = styled.div`
   width: 100%;
`;

const CardIframe = styled.div`
   width: 100%;
   div#iframe_view iframe {
     max-width: 100%;
   }
`;

const BlockImageText = styled.div`
    width: 100%;
    display: flex;
    background: #fff;
    
    .text-block {
      width: 67%;
      padding: 0px;
    }
    .image-block {
      width: 33%;
    }
`;

const CardAccordion = styled.div`
   width: 100%;
       padding: 0 20px;
`;

const TwoImage = styled.div`
   width:100%;
   .two-img-col {
      width: 100%;
      img { width:100%;}
   }
`;

const Row = styled.div`
   width:100%;
   display:flex;
   gap: 20px;
`;

const VideoIntro = styled.div`
   width: 100%;
   text-align: center;

   video {
        width: 100%;
    height:auto;
   }
`;

const AudioIntro = styled.div`
   width: 100%;
   text-align: center;
   audio { width: 100%;}
`;

const Testimonial = styled.div`
   width: 100%;
`;

const ImageGrid = styled.div`
   width:100%;
   
   .gallery-main {
      text-align: center;
   }
`;

const CustomText = styled.div`
  width: 100%;
`;

const CustomImage = styled.div`
   width: 100%;
   text-align: center;
`;

const Appointment = styled.div`

    .required {
    color: red;
    font-size: 14px;
    text-align: center;
    margin-bottom: 13px;
}

form {
    padding: 20px;
    background: #fff;
    border-radius: 10px;
        box-shadow: 1px 1px 11px 1px #d7d4d4;
    label {
      font-size: 14px;
      font-weight: 500;
      margin-top: 8px;
    }
  }

  input {
    padding: 10px;
    outline: none;
    border-radius: 3px;
    border: 1px solid rgb(223 222 222);
    margin: 0.3rem 0;
    font-size: 14px;
    margin-bottom: 10px;
    width: 100%;

    &:focus {
      border: 1px solid #ddd;
    }
  }
  button {
      border: 0;
      padding: 13px 0;
      background: #284270;
      color: #fff;
      cursor: pointer;
      width: 100%;
    }
`;


const Map = styled.div`
   width:100%;
   text-align: center;

   h3 {
        font-weight: 600;
        margin-bottom: 18px;
      }
`;

const ContactDetails = styled.div`
   width: 100%;
   padding: 0 20px;
`;

const Contact = styled.div`
    width: 33%;
    text-align: center;
    display: inline-block;
    margin-bottom: 25px;

    p {
      font-size: 15px;
      font-weight: 500;
      margin-top: 5px;
    }
    svg {
    padding: 10px;
    font-size: 21px;
    width: 45px;
    height: 45px;
    color: #fff;
    border-radius: 50%;
    }
`;

const ContactForm = styled.div`
   width: 100%;

.required {
    color: red;
    font-size: 14px;
    text-align: center;
    margin-bottom: 13px;
}

    h3 {
      margin-bottom:10px;
      text-align: center;
      font-weight: 600;
    }

   form {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 1px 1px 11px 1px #d7d4d4;

    label {
      font-size: 14px;
      font-weight: 500;
      margin-top: 8px;
    }

  input {
    padding: 10px;
    outline: none;
    border-radius: 3px;
    border: 1px solid rgb(223 222 222);
    margin: 0.3rem 0;
    font-size: 14px;
    margin-bottom: 10px;
        width: 100%;

    &:focus {
      border: 1px solid #ddd;
    }
  }

  textarea {
    min-height:100px;
    width: 100%;
    padding: 10px;
    margin: 0.5rem 0;
    border: 1px solid rgb(223 222 222);
    border-radius: 3px;
    margin-bottom: 20px;
    &:focus {
      border: 1px solid #ddd;
    }
    &:focus-visible {
      outline: none;
    }
  }
  button {
    background: #ff5722;
    border: none;
    padding: 10px 0;
    color: #fff;
    border-radius: 3px;
  }
   }
  }
`;

const Container = styled.div`
    background: #fff;
    height: 100%;
    width: 100%;
`;

const Intro = styled.div`
    width: 100%;
    height: auto;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
`;

const ProfileImg = styled.div`
   width:100%;
   text-align: center;
   margin-bottom: 15px;

   img { 
      border-radius: 50%;
      width: 10rem;
      height: 10rem;
      margin-top: -5rem;
      border: 2px solid #fff;
   }
`;

const Field = styled.div`
    background: #666;
    font-size: 16px;
    padding: 30px;
    margin-bottom: 15px;

    h1 {
      color: orange;
    }
`;