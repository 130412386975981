import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { useNavigate, useSearchParams } from "react-router-dom";
import CopyrightIcon from '@mui/icons-material/Copyright';
import AdminNav from "./AdminNav";
import CardListItem from "./CardListItem";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import './editor.css';

const ListCard = () => {

  const { cards } = useSelector((state) => state.cards);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  console.log(cards);

  let success_message = false;
  let card_create = searchParams.get('card_create');
  if(card_create === 'true'){
      success_message = true;
  }

  return (
    <>
     <StyledDashboard>
       <AdminNav/>
       <Content>
         <div style={{width: '100%'}}>
           <h3 style={{fontSize: '22px'}}>Dashboard</h3>
           {success_message === true ? <p className="success_message">The vcard is created successfully.</p> : ''}
           <ListHeader>
             <h4>List of vcards</h4>
             <span><hr/></span>
             <CreateCard onClick={() => navigate('/create-vcard')}><AddCircleIcon/> Create vcard</CreateCard>
           </ListHeader>
           <ListTable>
            <table>
              <tr>
                <th style={{width: '40%'}}>Vcard</th>
                <th style={{width: '40%'}}>Created on</th>
                <th>Actions</th>
              </tr>
              { cards && cards.map(card => <CardListItem value={card}/>) }
            </table>
           </ListTable>
         </div>

         <Footer>
          <p><a href="/"><CopyrightIcon/> 2023. nfcleads.au</a></p>
         </Footer>
      </Content>
     </StyledDashboard>
    </>
  );

};

export default ListCard;

const ListHeader = styled.div`
    margin: 20px 0px;
    display: flex;
    align-items: center !important;

    span {
      flex: 1 1 auto !important;
      hr {
          border-color: #eeeeee !important;
          margin-top: 1rem;
          margin-bottom: 1rem;
          border: 0;
          height: 0;
          border-top: 1px solid rgba(10,10,11,.1);
      }
    }
    h4 {
      font-weight: 600;
      margin-right: 15px;
    }
    button { 
      margin-left: 15px; 
    }
`;

const CreateCard = styled.button`
   background: #ffc107;
    border: none;
    color: #fff;
    border-radius: 4px;
    font-weight: 500;
    width: 145px;
    height: 40px;
    cursor: pointer;

    svg {
      vertical-align: sub;
      font-size: 20px;
    }
`;

const ListTable = styled.div`
   width:100%;
   background: #fff;
   height: 420px;
    overflow-y: scroll;

   table {
     width: 100%;
     border: 1px solid #eee;
     border-collapse: collapse;
     
     th {
       font-weight: 600;
       font-size: 14px;
       text-align: left;
       background: #fff;
       padding: 14px;
       border: 0;
     }
     td {
       padding: 14px;
       border-top: 1px solid #eee;
       font-size: 15px;
       color: #4b4a4a;
     }
   }
`;

const Content = styled.div`
  margin-left: 230px;
  padding: 2.5rem 4rem 1rem;
  width: 100%;
  background: #fcfcfd;
`;

const StyledDashboard = styled.div`
  display: flex;
  height: 100%;
`;

const Footer = styled.div`
    width: 100%;
    text-align: center;
    padding: 15px 0;
    margin-top: 55px;

    a {
        font-size: 13px;
        text-decoration: none;

        svg { font-size: 13px; }
    }
`;

const Actions = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  button {
    border: none;
    outline: none;
    padding: 5px 10px;
    color: white;
    border-radius: 3px;
    cursor: pointer;
  }
`;

const View = styled.button`
  background-color: #5ebf63;
`;

const Edit = styled.button`
  background-color: #009688;
`;

const Delete = styled.button`
  background-color: #f44336;
`;