import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { url, setHeaders } from "./api";
import { toast } from "react-toastify";

const initialState = {
  categories: [],
  status: null,
  createStatus: null,
  editStatus: null,
  deleteStatus: null
};

export const categoriesFetch = createAsyncThunk(
  "categories/categoriesFetch",
  async () => {
    try {
      const response = await axios.get(`${url}/categories`);

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const categoriesCreate = createAsyncThunk(
  "categories/categoriesCreate",
  async (values) => {
    try {
      const response = await axios.post(
        `${url}/categories`,
        values,
        setHeaders()
      );

      return response.data;
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data);
    }
  }
);

export const categoriesEdit = createAsyncThunk(
  "categories/categoriesEdit",
  async (values) => {
    try {
      const response = await axios.put(
        `${url}/categories/${values.p_id}`,
        values,
        setHeaders()
      );

      return response.data;
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data);
    }
  }
);

export const categoriesDelete = createAsyncThunk(
  "categories/pcategoriesDelete",
  async (id) => {
    try {
      const response = await axios.delete(
        `${url}/categories/${id}`,
        setHeaders()
      );

      return response.data;
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data);
    }
  }
);

const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {},
  extraReducers: {
    [categoriesFetch.pending]: (state, action) => {
      state.status = "pending";
    },
    [categoriesFetch.fulfilled]: (state, action) => {
      state.categories = action.payload;
      state.status = "success";
    },
    [categoriesFetch.rejected]: (state, action) => {
      state.status = "rejected";
    },
    [categoriesCreate.pending]: (state, action) => {
      state.createStatus = "pending";
    },
    [categoriesCreate.fulfilled]: (state, action) => {
      state.categories.push(action.payload);
      state.createStatus = "success";
      toast.success("Category Created!");
    },
    [categoriesCreate.rejected]: (state, action) => {
      state.createStatus = "rejected";
    },
    [categoriesEdit.pending]: (state, action) => {
      state.editStatus = "pending";
    },
    [categoriesEdit.fulfilled]: (state, action) => {
      const updatedCategories = state.categories.map((category) => category._id === action.payload._id ? action.payload : category);
      state.categories = updatedCategories;
      state.editStatus = "success";
      toast.success("Category Updated!");
    },
    [categoriesEdit.rejected]: (state, action) => {
      state.editStatus = "rejected";
    },
    [categoriesDelete.pending]: (state, action) => {
      state.deleteStatus = "pending";
    },
    [categoriesDelete.fulfilled]: (state, action) => {
      const newList = state.categories.filter((category) => category._id !== action.payload._id);
      state.categories = newList;
      state.deleteStatus = "success";
      toast.error("Category Deleted!");
    },
    [categoriesDelete.rejected]: (state, action) => {
      state.deleteStatus = "rejected";
    },
  },
});

export default categoriesSlice.reducer;