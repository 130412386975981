import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { cardsDelete } from "../../slices/cardsSlice";
import styled from "styled-components";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";

const CardListItem = (props) => {

	const [delete_modal, setDeleteModal] = useState(false);
	const dispatch = useDispatch();
  const navigate = useNavigate();
	const { deleteStatus } = useSelector((state) => state.cards);
  const card_id = props.value.id;
  const created_on = props.value.created_on;
  const d_arr = created_on.split('T');
  const vcard_created_on = d_arr[0];
   
	const DeleteCard = () => {
      dispatch(cardsDelete(card_id));
	}

	return(
      <>
       <tr>
         <td>{props.value.firstname} {props.value.lastname}</td>
         <td>{vcard_created_on}</td>
         <td>
           <Action>
             <span title="Delete Vcard"><DeleteIcon onClick={() => setDeleteModal(true)} style={{color: '#f44336'}} /></span>
             <span title="Edit Vcard"><a href={`/edit-vcard/${card_id}`}><EditIcon style={{color: '#1cbdc7'}}/></a></span>  
         </Action>
         </td>
       </tr>

       { delete_modal === true ?
       	 <Modal>
       	   <div className="modal-inner">
             <p>Do you really want to delete the card?</p>
             <button onClick={DeleteCard} style={{background: 'red'}}>Delete</button>
             <button onClick={() => setDeleteModal(false)} style={{background: '#5ec5f4'}}>No</button>
             <span><CloseIcon onClick={() => setDeleteModal(false)} style={{color: 'red'}} /></span>
             { deleteStatus === 'pending' ?
               <p><br></br>Deleting v-card...</p>
               : ''
             }
       	   </div>
         </Modal> 
         : ''
       }
      </>
	) 
}

export default CardListItem;

const Card = styled.div`
   width:100%;
   padding: 20px;
    background: #fff;
    box-shadow: 0px 2px 11px 0px #dadada;
    border-radius: 10px;
    margin-bottom: 30px;
    position: relative;
`;

const Action = styled.div`

   span {
   	 cursor:pointer;
   	 margin-right: 10px;
     
     svg {
       font-size: 25px;
     }
   }
`;

const Modal = styled.div`
  position:fixed;
  background:rgba(0,0,0,0.8);
  top:0;
  left:0;
  width:100%;
  height:100vh;
  z-index:999;
  display: flex;
  align-items: center;

  .modal-inner {
  	width: 500px;
    background: #fff;
    margin: 0 auto;
    padding: 40px 20px;
    border-radius: 8px;
    text-align: center;
    position: relative;

    button {
      width: 85px;
    height: 34px;
    color: #fff;
    border: 0;
    margin-top: 30px;
    font-weight: 500;
    cursor: pointer;
    margin-right: 15px;
    }
    span {
      position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    }
  }
`;