import styled from "styled-components";

export const StyledForm = styled.form`
  max-width: 380px;
    width: 100%;
    margin: 10rem auto;
    background: #fff;
    padding: 25px;

  h2 {
    margin-bottom: 1rem;
    text-align: center;
    margin-bottom: 25px;
  }

  button,
  input {
    height: 43px;
    width: 100%;
    padding: 7px;
    outline: none;
    border-radius: 5px;
    border: 1px solid rgb(220,220,220);
    margin-bottom: 1.2rem;
    font-size: 14px;

    &:focus {
      border: 1px solid rgb(0, 208, 255);
    }
  }

  button {
    cursor: pointer;
    margin-bottom: 0;
    background: #32a98d;
    border: 0;
    color: #fff;
    font-size: 15px;
    text-transform: uppercase;

    &:focus {
      border: none;
    }
  }

  p {
    font-size: 14px;
    color: red;
  }
`;
